import React from 'react';
import { Switch, Route, Redirect,withRouter } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';

import appRoutes from "../routes/routes";
import loginRoutes from "../routes/loginRoutes";

import HeaderAppBar from '../components/HeaderAppBar'
import LoginSignup from "../views/user/LoginSignup"
//import NotFound404 from "../views/StatusPages/NotFound404"
import { toast, ToastContainer } from 'react-toastify';
import AppProvider from '../contexts/AppProvider';


import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";

import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../contexts/AppContext';

import {StylesProvider} from "@material-ui/styles";

const UserServices = require('../services/userServices');


const userRoles = require('../const/userRoles')


class AppComponent extends React.Component {

  state = {
    mobileOpen: false,
    userLoggedIn : false,
  };

  pathName = "/";

  constructor(props){
    super(props);

    this.pathName = window.location.pathname;
    this.getCurrentUser();
  }

  async getCurrentUser(){
      var userResponse = await UserServices.newTokenUser();
      if(userResponse.success){
        this.handleLogin(userResponse);
      }else{
        let message = "Login to Continue";
        if(userResponse.response && userResponse.data){  
          message = (userResponse.response.data);
        }else if(userResponse.message){
          console.log(userResponse.response);
          if(userResponse.message == "Network Error"){
            message = "Failed to connect";
          }else{
            message = (userResponse.message);
          }
        }
        toast.error(message);
      }
  }
  
  getBrandText = (path) => {
    var routes= (this.props.currentUser) ? appRoutes : loginRoutes;
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  handleLogin( userData ){
    
    // set token
    if('token' in userData){
      localStorage.setItem('accessToken', userData.token);
    }

    this.setState({ userLoggedIn : true, userData : userData.data })

    this.props.onLogin(userData.data);

    if (this.pathName === "/" && userData.data ){
      this.pathName = (userData.data.userRole == userRoles.ROLE_ADMIN) ? "/admin/dashboard" : "/admin/profile";
    }

    this.props.history.push(this.pathName);

  }

  async handleLogout(){
    await UserServices.logout()
    this.props.onLogout();
    this.setState({ userLoggedIn : false, userData : null }) 
    this.props.history.push('/admin/login')
  }

  getDefaultRoute () {
    if (this.props.currentUser) {
      let routeName = (this.props.currentUser.userRole === userRoles.ROLE_ADMIN) ? "/admin/dashboard" : "/admin/profile";
      return <Redirect to={routeName} />
    }

    return <Route path="*" render={(props) =>  <LoginSignup  {...this.props} {...props} />}/>;
  }
  
  render() {

    const switchRoutes = (
      <Switch>
        {this.props.currentUser ? appRoutes.map((prop, key) => {
          if(!prop.minAuthLevel || (this.props.currentUser.userRole >= prop.minAuthLevel)){
            return <Route exact path={prop.layout + prop.path} key={key} render={(props) => <prop.component currentUser={this.props.currentUser} onLogin={this.handleLogin.bind(this)} {...props}  /> } />;
          }
        }) : loginRoutes.map((prop, key) => {
          if(!prop.minAuthLevel || (this.props.currentUser.userRole >= prop.minAuthLevel)){
            return <Route exact path={prop.layout + prop.path} key={key} render={(props) => <prop.component currentUser={this.props.currentUser} onLogin={this.handleLogin.bind(this)} {...props}  /> } />;
          }
        })}

        {this.getDefaultRoute()}

      </Switch>
    );

    return (
        <StylesProvider injectFirst >
          <div className="flex">
              <CssBaseline />
              <div className="w-full">
                {this.props.currentUser ?
                  <div>
                    <Sidebar
                      color="black"
                      {...this.props}
                      userData={this.state.userData}
                      routes={this.props.currentUser ? appRoutes : loginRoutes}
                      onLogout={this.handleLogout.bind(this)}
                    /> 
                    <div id="main-panel" className="main-panel" ref="mainPanel">
                
                    <AdminNavbar
                      {...this.props}
                      brandText={this.getBrandText(this.props.location.pathname)}
                    />
                      {switchRoutes}
                    </div>
                  </div>
                  
                  : 
                  
                  <div className="w-full" ref="mainPanel">
                    {switchRoutes}
                  </div>
                
                }
              </div>
          </div>
          </StylesProvider>
    );
  }
}

AppComponent = withRouter(AppComponent)

class RealexAppWithContext extends React.Component {
  static contextType = AppContext;
  render() {
      return <>
        <AppComponent currentUser={this.context.currentUser} onLogin={this.context.onLogin} onLogout={this.context.onLogout}/>
        <ToastContainer />
      </>
  }
}

class RealexApp extends React.Component {
  render() {
      return (
          <AppProvider>
              <RealexAppWithContext/>
          </AppProvider>
      );
  }
}


export default (RealexApp);
