import React,{ Component } from 'react';

import AppContext from './AppContext';

class AppProvider extends Component {
    state = {
        currentUser : null
    };

    render() {
        return (
            <AppContext.Provider
                value={{
                    currentUser: this.state.currentUser,
                    onLogin: userData => {
                        this.setState({
                            currentUser : userData
                        });
                    },
                    onLogout: () => {
                        this.setState({
                            currentUser : null
                        });
                    }
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;