import React from "react";
import {Prompt} from "react-router";
import {toast} from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { 
    FormControl,
    Form,
    Row,Col,
    Button,
    Modal
} from "react-bootstrap";

import ScheduleModal from './scheduleModal.jsx';
import EditProofModal from "./editProofModal.jsx";
import WarningModal from "./warningModal.jsx";

import { CircularProgress, IconButton } from "@material-ui/core";
import PreviewCard from '../../../components/PreviewCard/PreviewCard.jsx';

// icons being used
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const qs = require('query-string');
const ReactQuill = require('react-quill');

const articleService=require("../../../services/articleService");
const draftInterval = require("../../../config/config.js").draftInterval;

const customRemote=require("../../../config/remote.js");
const customUrl = customRemote.customURL;

const config = require("../../../config/config.js");
const TITLE_LIMIT = config.titleLength;
const DESC_LIMIT = config.descriptionLength;

 
// 3 cases 
// - new article creation was in draft when edit was clicked
// - some other article was being edited and is in draft
// - the article draft was updated by someone else in the middle
// - creating article when user was previously creating an article
// - creating article when user was previously editing an article

class CustomArticle extends React.Component{

    constructor(props){
        super(props);
        this.state={
            id:"",
            Title:"",
            titleWordCount:0,
            Summary:"",
            Proofs:[""],
            selectedImage: null,
            ClaimArray:[
                {
                    Claim:"",
                    ProofArray:[],
                }
            ],
            claimRefs:[ React.createRef() ],
            Initial:{
                Title:"",
                Summary:"",
                ClaimArray:[{
                        Claim:"",
                        ProofArray:[]
                    }]
            },

            showDraftConflict:false,
            draftConflictMessage:"",
            draftConflictMode :null, 
            draftLoading:false,

            isLoading:false,
            loadingMessage:"Publishing Article ...",

            // texts for draft modal
            continueText:"Continue",
            cancelText : "Cancel",

            hasUpdated:false,
            buttonDisabled:false, // publish / cancel button status
            linkAddDisabled:false,
            facebookShare:false,
            instagramShare:false,
            twitterShare:false,
            publishDate:null,
            expiryDate:null,
            openScheduleModal:false,
            draftIntervalID:null,
            updated_time:null,

            factDeleteWarning: false,
            factToDelete: null,

            showProofModal:false,
            editProofData:{
                title:"",
            }
        }
        this.draftArticle = null;
        this.targetEditArticle = null;
    }

    async componentDidMount(){
        await this.handleArticleLoad();
    }

    componentWillUnmount(){
        clearInterval(this.state.draftIntervalID);
        window.onbeforeunload=null;
    }

    // handles Loading Article
    handleArticleLoad = async () =>{

        // if id found, open form in edit mode
        if(this.props.match.params.id){
            let id = this.props.match.params.id;
            await this.loadEditArticle(id);
        }
    
        // new article creation
        else {
            this.checkDraft();
        }
    }

    // article creation
    checkDraft = () =>{
        
        let params = qs.parse(this.props.location.search);

        if(params.mode=="draft"){
            this.loadFromDraft();
            this.startDraftTicker();
            return;
        }

        let draftArticle = this.checkLocalDraft();
        this.draftArticle = draftArticle;

        let conflictMessage , draftConflictMode;
        let continueText;
        
        // if an draft article has been found, show modal
        if(draftArticle){
            let cancelText = "Create New";
            // if there is an id in draft , editing
            if(draftArticle.id){
                conflictMessage = "You were editing an article, would you like to continue editing ? ";
                draftConflictMode = 1;
                continueText = "Continue Editing";
            } 
            // if no id was found, article creation is in draft
            else{
                conflictMessage = "You were creating an article, would you like to continue writing ?";
                draftConflictMode = 0;
                continueText = "Continue Writing";
            }
            this.setState({
                draftConflictMessage:conflictMessage,
                showDraftConflict:true,
                draftConflictMode,
                continueText,
                cancelText,
            })
        }

        // if no article in draft, start ticker for draft
        else{
            this.startDraftTicker();
        }
    }

    // load article from local storage / draft
    loadFromDraft = () =>{
        let draftArticle = localStorage.getItem("xotkariDraft");
        draftArticle = JSON.parse(draftArticle);
        if(draftArticle){
            this.setState({
                id:draftArticle.id,
                Title:draftArticle.Title,
                Summary:draftArticle.Summary,
                selectedImage:draftArticle.selectedImage,
                // Proofs:draftArticle.Proofs,
                ClaimArray:draftArticle.ClaimArray,
                updated_time:draftArticle.updated_time,
            })
        }
    }

    // initialize the draft interval and handler
    startDraftTicker = () =>{
        let intervalId = setInterval(this.draftToLocal,draftInterval);
        this.setState({draftIntervalID:intervalId});
    }

    // handles saving the article to localstorage
    draftToLocal = () =>{
        let articleData ={
            id:this.state.id,
            Title:this.state.Title,
            Summary:this.state.Summary,
            ClaimArray:this.state.ClaimArray,
            updated_time : this.state.updated_time,
            selectedImage: this.state.selectedImage,
        }
        let article = JSON.stringify(articleData);
        localStorage.setItem("xotkariDraft",article);
    }

    handleLoadingOverlay = (show,message) =>{
        this.setState({
            isLoading: show,
            loadingMessage : message
        })
    }
    
    // checks if there is anything in the draft
    // if empty - returns true else false
    checkLocalDraft = () =>{

        // load and parse article from ls
        let draftArticle = localStorage.getItem("xotkariDraft");
        if(!draftArticle){
            return false;
        }
        let draftJSON = JSON.parse(draftArticle);
        
        // check if there is any content in the draft
        let isEmpty = false;
        let draftClaims= draftJSON.ClaimArray;

        if( draftJSON.Title === "" && draftJSON.Summary === "" &&  
            (( draftClaims.length == 0 || (draftClaims[0].Claim === "" || draftClaims[0].Claim === "<p><br></p>") && draftClaims[0].ProofArray.length===0))){
            isEmpty = true;
        }

        // is no content, return
        if(isEmpty) return false;

        return draftJSON;
    }

    // Load Target Article for editing
    loadEditArticle=async (id)=>{

        this.handleLoadingOverlay(true,"Loading Article ...");

        // fetch article using id
        let article=await articleService.getArticle(id);
        
        console.log("LOADED ARTICLE");
        console.log(article);

        this.targetEditArticle = article; 

        if(!article.success){
            toast.error(article.message);
            return;
        }

        // check if draft is empty or contains content
        let draftArticle =  this.checkLocalDraft();
       
        this.draftArticle = draftArticle;

        let articleTitle = null;
        if(draftArticle.Title){
            articleTitle = draftArticle.Title;
        }

        let conflictMessage = null;
        
        this.handleLoadingOverlay(false,"");

        //opened from draft;
        let draftMode = false
        let params = qs.parse(this.props.location.search);

        if(params.mode=="draft"){
            draftMode = true;
        }

        let continueText = "";
        let cancelText = "Discard";
        // - new article creation was in draft when edit was clicked
        if(draftArticle && draftArticle.id =="" && !draftMode){
            continueText = "Continue Writing";
            conflictMessage =" You were creating an article, would you like to continue writing ?.";
            this.setState({
                showDraftConflict:true,
                draftConflictMode:0,
                cancelText,
                continueText,
                draftConflictMessage:conflictMessage,
            })    
            return;        
        }

        // - some other article was being edited and is in draft
        if(draftArticle && draftArticle.id != article.data._id && !draftMode){
            continueText = "Continue Editing";
            conflictMessage = "You were editing an article, would you like to continue editing ? ";
            this.setState({
                showDraftConflict:true,
                draftConflictMode:1,
                cancelText,
                draftConflictMessage:conflictMessage,
                continueText,
            })
            return;
        }

        // - the article draft was updated by someone else in the middle
        if(!draftMode && draftArticle && draftArticle.id === article.data._id && draftArticle.updated_time != article.data.updated_time){
            if(articleTitle){
                conflictMessage = "The article";
            }else{
                conflictMessage = draftArticle.Title;
            }
            continueText = "Continue Editing";
            conflictMessage +=" was recently updated and does not match the version in the draft. Discarding will replace the draft with the current version.";
            this.setState({
                showDraftConflict:true,
                draftConflictMode:2,
                draftConflictMessage : conflictMessage,
                continueText,
                cancelText,
            });
            return;
        }


        if(draftArticle && draftArticle.id === article.data._id){
            await this.loadFromDraft();
        }else{
            await this.loadArticle(article);
        }
        this.startDraftTicker();
        
    }


    loadArticle = (article) =>{
        
        console.log("ARTICLE LOADED FOR EDITNG");
        let initial={
            Title:article.data.title,
            Summary:article.data.description,
            ClaimArray:article.data.claims,
        }

        let claimRefs = [];
        article.data.claims.forEach((claim)=>{
            claimRefs.push(React.createRef());
        })
        this.setState({
            id:article.data._id,
            Title:article.data.title,
            Summary:article.data.description,
            ClaimArray:article.data.claims,
            publishDate:article.data.publish_date ? new Date(article.data.publish_date).getTime() : null,
            expiryDate: article.data.expiry_date ? new Date(article.data.expiry_date).getTime() : null,
            hasUpdated:false,
            twitterShare:article.data.twitterId ? true : false,
            facebookShare:article.data.facebookId ? true : false,
            Initial:initial,
            claimRefs,
            updated_time : article.data.updated_time,
            selectedImage: article.data.image[0].url,
        })
   
    }

    //checks if the component has updated or not
    // for confirmation dialogue
    componentDidUpdate = async (prevProps, prevState) => {

        if(prevProps!= this.props || prevProps.match.params.id != this.props.match.params.id || prevProps.location.search != this.props.location.search){
            await this.handleArticleLoad();
            this.setState({
                draftConflictMode:null,
                showDraftConflict:false,
                draftLoading:false
            })
        }

        //new form content
        var Current={
            Title:this.state.Title,
            Summary:this.state.Summary,
            ClaimArray:this.state.ClaimArray
        }

        //converting form json to string
        let initString=JSON.stringify(this.state.Initial);
        
        let currentString=JSON.stringify(Current);
        
        let updateStatus=true;
        //check for changes
        if(currentString===initString){
            updateStatus=false;
        }
        
        if (updateStatus==true) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
        
        if(updateStatus!=this.state.hasUpdated){
            this.setState({
                hasUpdated:updateStatus,
            })
        }
    }


    handleLink=(e)=>{
        var newLink=e.target.value;
        var claimID=e.target.getAttribute("claimid");
        var newProofs=this.state.Proofs;
        newProofs[claimID]=newLink;
        this.setState({
            Proofs:newProofs
        })
    }

    handleChange=(e)=>{
        let id = e.target.id;
        let value = e.target.value;
        
        this.setState({
            [id]:value
        })
    }
    

    // if protocol not found in given proof Link , prepend http://
    setHttp=(link)=> {
        if (link && link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    } 
    
    // handle Adding Claims and Proofs
    handleAddCP=async (e)=>{
        let claims=this.state.ClaimArray;
        let claimID=e.target.getAttribute("claimid");
        
        //adding proof for a claim
        if(claimID){
            let proofURL=this.state.Proofs[claimID];
            proofURL = this.setHttp(proofURL);
            
            if(!proofURL){
                toast.info("Empty link cannot be added");
                return;
            }

            // add proof to claim
            this.setState({
                linkAddDisabled:true
            })
            let newproof=await this.loadData(proofURL);
        
            this.setState({
                linkAddDisabled:false,
            })

            if(newproof==false){
                toast.error("Invalid link");
                return;
            }

            claims[claimID].ProofArray.push(newproof);
            var newProofs=this.state.Proofs;
            newProofs[claimID]="";
             this.setState({
                ClaimArray:claims,
                Proofs:newProofs
            })
        }else{
            // create a new reference for the claim
            let newRef = React.createRef();
            let claimRefs = this.state.claimRefs;
            claimRefs.push(newRef);

            var NewClaim= {Claim:"",ProofArray:[]};
            claims.push(NewClaim);
            
            this.setState({
                ClaimArray:claims,
                claimRefs,
            })
        }
    }
    
    // handle claim change
    handleClaim=(e,claimid)=>{

        let text = e;

        // let wordArray = text.split(" ");
        
        // do nothing and return if word count exceeds the word limit
        // if( wordArray.length > DESC_LIMIT ) return;

        var ClaimID=claimid;
        var claims=this.state.ClaimArray;
        claims[ClaimID].Claim=text;
        this.setState({
            ClaimArray:claims
        })
    }

    handleDescription = (e) =>{
        
        let value = e.target.value;

        let textArray = value.split(" ");

        // if description > description word count limit - return
        // if(textArray.length > DESC_LIMIT) return;
        
        let claims = this.state.ClaimArray;
        claims[0].Claim = value;
        this.setState({
            Summary:value,
            ClaimArray:claims
        })

    }

    // load url data
    loadData=async (link)=>{
        let result=await articleService.loadData({'url':link});
        if(result.success || (result.data && result.data.success==true)){
            let responseData = result.data.data;
            let ogData = responseData.ogData;
            let htmlData = responseData.html;
            let data = {};
            if(htmlData){
                data.html = htmlData;
            }
            if(ogData){
                data.title = ogData.Title;
                data.image = ogData.Image.url;
                data.link = ogData.Url;
                if(result.data.articlePublishedTime || result.data.ArticlePublishedTime){
                    let publishDate = (result.data.articlePublishedTime) ? new Date(result.data.articlePublishedTime) : new Date(result.data.ArticlePublishedTime);
                    publishDate = publishDate.getTime();               
                    data.publishedTime = publishDate;
                }
            }   
            return data;
        }else{
            return false;
        }
    }

    // Handle Remove Claim and Proof
    handleRemoveCP=(e,ClaimID=null,ProofID=null)=>{
        let showWarning = this.state.factDeleteWarning;
        let claims=this.state.ClaimArray;
        let claimRefs = this.state.claimRefs;
        let targetClaim = null;

        if(ProofID!=null){
            claims[ClaimID].ProofArray=claims[ClaimID].ProofArray.filter((item,index)=>{
                return(ProofID!=index)
                 })
        }
        else{
            let claimHasContent = this.claimContentCheck(ClaimID);
            // if contains content, show warning 
            if(claimHasContent){
                showWarning = true;
                targetClaim  = claims.filter((item,index)=>{return index===ClaimID});
                targetClaim = targetClaim[0];
                targetClaim.index = ClaimID;
            }
            // else remove the fact   
            else{
                // remove the fact
                claimRefs.splice(ClaimID,1);
                    claims=claims.filter((item,index)=>{
                        return(ClaimID!=index)
                })
            }
        
        }
        this.setState({
            claimRefs,
            factDeleteWarning: showWarning,
            ClaimArray:claims,
            factToDelete: targetClaim
        })
    }

    claimContentCheck(claimIndex){
        let hasContent = false;
        let target = this.state.ClaimArray.filter((item,index)=>{return claimIndex== index});
        target = target[0];
        if(target.Claim!="" && target.Claim!=null && target.Claim!="<p><br></p>"){
            hasContent = true;
        }
        if(!hasContent && target.ProofArray && target.ProofArray.length > 0){
            hasContent = true
        }
        return hasContent;
    }

    validateForm=()=>{
        console.log("VALIDATE FORM");
        var formValid=true;
        
        console.log(this.state.Title);
        console.log(this.state.Summary);
        let title = this.state.Title.trim();
        let summary = this.state.Summary.trim();

        if( title == "" ){
            toast.info("Title is empty");
            formValid=false;
            return;
        }
        if( summary == "" ){
            toast.info("Summary is empty");
            formValid=false;
            return;
        }

        // title length limit validation
        let valueArray= title.split(" ");
        if(valueArray.length > TITLE_LIMIT) {
            toast.info("Title too long");
            return;
        };

        // summary / description limit validation
        valueArray = summary.split(" ");
        if(valueArray.length > DESC_LIMIT){
            toast.info("Description too long");
            return;
        }

        var claims=this.state.ClaimArray;
        for(var i=0;i<=claims.length-1;i++){
            let claimText =claims[i].Claim;
            console.log("CLAIM " + i);
            console.log(claimText);
            claimText = claimText.trim(); 
            if( claimText == "" ){
                toast.info("One of the Fact is empty");
                formValid=false;
                break;
            }
            var proofs=claims[i].ProofArray;
            for(var j=0;j<=proofs.length-1;j++){
                if(proofs[j]==""){
                    var ClaimNo=i+1;
                    toast.info("Claim "+ClaimNo+" has a empty proof");
                    formValid=false;
                    break;                
                }
            }
        }
       return formValid;
    }
    
    //handle article submission
    handleSubmit=async (e)=>{
        try{

            this.setState({
                isLoading:true,
            })
            e.preventDefault();
            let formValid=this.validateForm();
            
            if(formValid){

                this.setState({
                    buttonDisabled:true,
                })

                let article={
                    Title:this.state.Title,
                    Summary:this.state.Summary,
                    ClaimArray:this.state.ClaimArray,
                    Preview:false, // saves the article without preview
                    PublishDate:this.state.publishDate ? this.state.publishDate : null,
                    image: this.state.selectedImage
                };
            
                let result;
                if(this.state.id){
                    // save changes
                    result=await articleService.editArticle(this.state.id,article); 
                } else {
                    result=await articleService.postArticle(article);
                }

                // for Publish article
                
                if(result.success==true && this.props.currentUser.userRole>1){
                    localStorage.removeItem("xotkariDraft");
                    // if user is editor / admin : show the scheduling Option
                    let publishDate = this.state.publishDate ? this.state.publishDate : new Date().getTime();
                    if(this.state.id!=""){
                        // if edit mode 
                        this.setState({
                            publishDate:publishDate,
                            buttonDisabled:false,
                            openScheduleModal:true,
                        })
                    }else{
                        // create a new article
                        this.setState({
                            publishDate:publishDate,
                            buttonDisabled:false,
                            openScheduleModal:true,
                            id:result.data.article._id,
                        })
                    }
                    
                }else if(result.success===true && this.props.currentUser.userRole<=1){
                    localStorage.removeItem("xotkariDraft");
                    //if user is not editior / admin : Does not show scheduling options
                    let message; 
                    if(this.state.id){
                        message = "Your Article has been edited";
                        this.props.history.push("/admin/profile");
                    }else{
                        message = "Congrats, Your Article has been published";
                        window.open(customUrl+"/article?id="+result.data.article._id+"&type=custom","_blank");
                    }
                    toast.success(message);
                    this.setState({
                        buttonDisabled:false,
                    })
                    this.resetState();
                
                }else{
                    toast.error(result.message);
                }

            }
            this.setState({
                isLoading:false,
            })
        }catch(err){
            this.setState({isLoading:false,buttonDisabled:false});
            console.log(err);
        }
    }

    //empty the entire form
    resetState=()=>{
        this.setState({
            buttonDisabled:false,
            Title:"",
            Summary:"",
            hasUpdated:false,
            id:"",
            ClaimArray:[
                {
                    Claim:"",
                    ProofArray:[]
                    
                }
            ],
            Initial:{
                Title:"",
                Summary:"",
                ClaimArray:[
                    {
                        Claim:"",
                        ProofArray:[]
                        
                    }
                ]
            }
        })
    }

    getHtml=(proof)=>{
        return {__html:proof.html}
    }

    onDoneSchedule =(e)=>{
        this.setState({
            openScheduleModal: false,
        })
        localStorage.removeItem("xotkariDraft");
        this.props.history.push("/admin/article/custom");
        window.open(customUrl+"/article?id="+this.state.id+"&type=custom","_blank");
        this.resetState();
    }

    cancelSchedule = (e)=>{ 
        this.setState({
            openScheduleModal:false,
        })
        localStorage.removeItem("xotkariDraft");
        if(this.props.match.params.id){
            toast.success("Article has been edited");
            this.props.history.push("/admin/profile");
        }else{
            this.props.history.push("/admin/article/custom");
            toast.success("Congrats, Article has been published");
            this.resetState();
            window.open(customUrl+"/article?id="+this.state.id+"&type=custom","_blank");
        }
    }

    // handles fact up / down option
    moveFact = (e,moveUp,index) =>{
        let claimArray = this.state.ClaimArray;
        var selectedRef,nextIndex;
        if(moveUp){
            nextIndex = index -1;
        }else{
            nextIndex = index + 1;
        }

        // invalid up from top of list and down from bottom of list
        if(nextIndex < 1 || nextIndex > claimArray.length-1){
            return;
        }
        
        let selectedClaim = claimArray.splice(index,1)[0];   
        
        claimArray.splice(nextIndex,0,selectedClaim);
        selectedRef = nextIndex;
        this.setState({
            ClaimArray : claimArray,
        },()=>{
            console.log(this.state.claimRefs);
            this.state.claimRefs[nextIndex].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            }) 
        });
    }
 
    handleConflictClose = ( ) => {
        this.setState({
            showDraftConflict : false
        })
    }

    continueDraft = () =>{  
        this.setState({
            draftLoading:true,
        })
        switch(this.state.draftConflictMode){
            case 0:
                this.props.history.push("/admin/article/custom?mode=draft");
                break;
            case 1:
                this.props.history.push("/admin/article/custom/" + this.draftArticle.id + "?mode=draft");
                break;
            case 2:
                this.setState({
                    draftConflictMode:null,
                    showDraftConflict:false,
                    draftLoading:false,
                })
                this.loadFromDraft();
                this.startDraftTicker();
                break;
            case 3:
                break;
        }
    }

    // clear draft and load article
    clearDraft = async ()=>{
        localStorage.removeItem("xotkariDraft");
        this.setState({
            showDraftConflict:false,
            draftConflictMode:null,
        })
        if(this.targetEditArticle){
            await this.loadArticle(this.targetEditArticle);
        }
        this.startDraftTicker();
    }

    addFactBottom = (e,index) =>{
        
        let claims = this.state.ClaimArray;
        let claimRefs = this.state.claimRefs;
 
        let newRef = React.createRef();
        let newClaim= {Claim:"",ProofArray:[]};

        // add new element into the array
        claims.splice(index+1,0,newClaim);
        claimRefs.splice(index+1,0,newRef);

        this.setState({
            ClaimArray:claims,
            claimRefs,
        },()=>{
            this.state.claimRefs[index+1].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        })

    }
        
    // on confirm editing a proof
    onProofEdit = (data) =>{
        let newClaims = this.state.ClaimArray;
        let {claimId,proofId,title} = data;
        newClaims[claimId].ProofArray[proofId].title = title;

        this.setState({
            ClaimArray:newClaims,
            showProofModal:false
        })
    }

    // cancel proof editing
    onProofCancel = () => {
        this.setState({
            showProofModal:false
        })
    }

    // handles Editing proof data - shows the proof edit modal
    handleEditProof = (e,claimId,proofId) =>{
        let proofData = this.state.ClaimArray[claimId].ProofArray[proofId];
        proofData.claimId = claimId;
        proofData.proofId = proofId;
        this.setState({
            editProofData:proofData,
            showProofModal:true,
        })
    }

    selectArticleImage = (imageUrl) =>{
        this.setState({
            selectedImage:imageUrl
        })
    }

    // toggles the fact removal warning modal
    toggleFactWarning = (e,show)=>{
        console.log("CHANGE FACT WARNING TO : " + show);
        this.setState({
            factDeleteWarning : show,
        })
    }

    // removes a fact from the article
    onRemoveFact = (e)=>{
        let claims=this.state.ClaimArray;
        let claimRefs = this.state.claimRefs;
        let ClaimID = this.state.factToDelete.index;

        // remove the fact
        claimRefs.splice(ClaimID,1);
            claims=claims.filter((item,index)=>{
                return(ClaimID!=index)
        })

        this.setState({
            claimRefs,
            ClaimArray:claims,
            factToDelete:null,
            factDeleteWarning:false
        })
    }

    render(){
        return <div>
        
        <WarningModal show= {this.state.factDeleteWarning}
            onRemove = {(e)=>this.onRemoveFact(e)}
            data = {this.state.factToDelete}
            onCancel={(e)=>this.toggleFactWarning(e,false)} 
        />
        
        <EditProofModal show={this.state.showProofModal} 
            selectedImage = {this.state.selectedImage}
            onImageSelect = {this.selectArticleImage.bind(this)}
            data={this.state.editProofData}
            onDone = {this.onProofEdit.bind(this)}  
            onCancel = {this.onProofCancel.bind(this)}
        />

        
        <Modal show={this.state.showDraftConflict} >
            <Modal.Body>
                <p>{this.state.draftConflictMessage}</p>
            </Modal.Body>
            <Modal.Footer>
                {this.state.draftLoading 
                ? 
                    <CircularProgress /> 
                : 
                    <div>
                        <button className='btn primaryButton' onClick={this.continueDraft}>{this.state.continueText}</button>
                        <button className="btn primaryButton" onClick={this.clearDraft}>{this.state.cancelText}</button>
                    </div>
                }
            </Modal.Footer>    
        </Modal>
         
            <ScheduleModal 
                showModal={this.state.openScheduleModal} 
                id={this.state.id}
                twitterShare={this.state.twitterShare}
                instagramShare= {this.state.instagramShare}
                facebookShare = {this.state.facebookShare}
                publishDate = {this.state.publishDate}
                expiryDate = {this.state.expiryDate}
                onCancel = {this.cancelSchedule.bind(this)}
                onDone = {this.onDoneSchedule.bind(this)}
            /> 
            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text={this.state.loadingMessage}
                styles={{
                    wrapper: {
                      zIndex: '100000'
                    }
                  }}
                >
            <Form onSubmit={this.handleSubmit}>
            
                {/* Confirmation Alert on changes */}
                <Prompt
                    when={this.state.hasUpdated==true}
                    message= "You have unsaved changes, are you sure you want to leave?"
                />
                
                <Row>
                    <Col md={12}>
                    <Row>
                        <Col md={12}>
                            { (this.state.Title && this.state.Title.split(" ").length > TITLE_LIMIT) ? 
                                <div className="wordCount color-red">
                                    { this.state.Title.split(" ").length + " / " + TITLE_LIMIT } words 
                                </div>
                            : 
                                <div className="wordCount">
                                    {TITLE_LIMIT - (this.state.Title ? (this.state.Title.split(" ").length) : 0 )} words left
                                </div>
                            }
                            <FormControl type="text" id="Title"
                                placeholder="Enter Title" value={this.state.Title}
                                onChange={this.handleChange} />
                        </Col>
                    </Row><br></br>
                    {/* <Row>
                        <Col md={12}>
                            <FormControl componentClass="textarea" id="Summary"
                                style={{height:'80px'}}
                                placeholder="Summary" value={this.state.Summary}
                                onChange={this.handleChange} />
                        <br></br>
                        </Col>
                    </Row> */}

                    {this.state.ClaimArray.map((claim,index)=>{
                            return(
                                <div key={index} ref={this.state.claimRefs[index]}>
                                    <Row style={{marginBottom:12}}>
                                        <Col md={1}>
                                            {index===0 ? "Description" :  "Fact" }
                                        </Col>
                                        {index ===0 ? <span></span> :
                                            <Col md={11} style={{textAlign:"right"}}>
                                                {this.state.ClaimArray.length >1 ? 
                                                    <span>
                                                        <Button className="ml-5" bsSize="xs" onClick={(e)=>{this.moveFact(e,true,index)}} >
                                                            <ExpandLessIcon />
                                                        </Button>
                                                        <Button className="ml-5" bsSize="xs" onClick={(e)=>{this.moveFact(e,false,index)}}>
                                                            <ExpandMoreIcon />
                                                        </Button>
                                                        <Button className="ml-5" bsSize="xs" onClick={(e)=>{this.addFactBottom(e,index)}}>
                                                            <AddIcon color="primary"></AddIcon>
                                                        </Button>
                                                    </span>
                                                    :<span></span> 
                                                }

                                                <Button className="ml-5" bsSize="xs" onClick={(e)=>this.handleRemoveCP(e,index)} >
                                                    <ClearIcon color="secondary" fontSize="inherit" />
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    {index!==0 ? 
                                        <Row>
                                            <Col md={12}>
                                                {/* <div className="wordCount">{DESC_LIMIT - (claim.Claim!="" ? (claim.Claim.split(" ").length) : 0 )} words left</div> */}
                                                <div style={{backgroundColor:"white",border:"1px solid lightgrey"}} >
                                                    <ReactQuill className="quill" theme="snow"
                                                        value={claim.Claim}
                                                        onChange={(e)=>{this.handleClaim(e,index)}} 
                                                        placeholder="Enter Fact" 
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        : <Row>
                                            <Col md={12}>
                                            { (claim.Claim!="" && claim.Claim.split(" ").length > DESC_LIMIT) ? 
                                                <div className="wordCount color-red">
                                                    {claim.Claim.split(" ").length + " / " + DESC_LIMIT } words 
                                                </div>
                                            : 
                                                <div className="wordCount">
                                                    { DESC_LIMIT - (claim.Claim!="" ? (claim.Claim.split(" ").length) : 0 )} words left
                                                </div>
                                            }
                                            {/* <div className="wordCount">{DESC_LIMIT - (claim.Claim!="" ? (claim.Claim.split(" ").length) : 0 )} words left</div> */}
                                            <FormControl componentClass="textarea" id="Summary"
                                                style={{height:'80px'}}
                                                placeholder="Summary" value={claim.Claim}
                                                onChange={(e)=>{this.handleDescription(e)}} />
                                            </Col>
                                        </Row>
                                    }
                                    <br></br>

                                    {claim.ProofArray.map((proof,ProofId)=>{
                                        return(
                                            <div key={ProofId}>
                                                <div style={{textAlign:'right'}}>
                                                    <IconButton size="medium" onClick={(e)=>this.handleEditProof(e,index,ProofId)}>
                                                        <CreateIcon fontSize="inherit"/>
                                                    </IconButton>
                                                    <IconButton size="medium" onClick={(e)=>this.handleRemoveCP(e,index,ProofId)} >
                                                        <ClearIcon style={{color:'red'}} fontSize="inherit" />
                                                    </IconButton>
                                                </div>
                                                <Row>
                                                    <Col md={11}>
                                                        {proof.html? <div>
                                                                <div dangerouslySetInnerHTML={this.getHtml(proof)} ></div>
                                                            </div>
                                                            : 
                                                            <PreviewCard 
                                                                selected = {this.state.selectedImage === proof.image}
                                                                onImageSelect = {this.selectArticleImage.bind(this)}
                                                                title={proof.title}
                                                                image={proof.image}
                                                                url={proof.link} 
                                                                publishTime = {proof.publishedTime}
                                                            />
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                        
                                    })}
                                    <Row>
                                        <Col md={2}>
                                            <div style={{margin:"10px 0px"}}>Insert link</div>
                                        </Col>
                                        <Col md={8}>
                                            <FormControl placeholder="Paste Link Here" 
                                                value={this.state.Proofs[index]} 
                                                claimid={index} 
                                                onChange={this.handleLink} />
                                            <br></br>
                                        </Col>
                                        <Col md={2}>
                                            <Button claimid={index} bsStyle="info" 
                                                onClick={this.handleAddCP}
                                                className="button"
                                                disabled={this.state.linkAddDisabled}>Add</Button>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </div>  
                            )
                    })}
      
                    <Row >
                        <Col md={12}>
                            <div style={{textAlign:"right"}}>
                                <Button onClick={this.handleAddCP} 
                                    className="button addFactbtn">
                                        Add Fact
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Button type="submit" 
                                className="button submit-btn" 
                                disabled={this.state.buttonDisabled}>
                                {this.state.id ? <span>Save Changes</span> : <span>Submit Article</span>}
                            </Button>
                        </Col>
                        
                    </Row>
                
                </Col>
                </Row>

             </Form>
             </LoadingOverlay>

        </div>
    }
}
export default CustomArticle;
