module.exports = [
    "राजनीति",
    "अर्थ",
    "ठाउँ / क्षेत्र / देश",
    "अपराध",
    "समाज / परिवार",
    "घटना",
    "विचारसमूह", 
    "नकारात्मक",
    "व्यक्तित्व",
    "व्यवसाय",
    "व्यक्ति / समुदाय",
    "पेसा / ओहोदा",
    "सेवा / सुबिधा ",
    "उपभोग्य वस्तु",
    "कानून / प्रशासन",
    "चाडपर्व / दिवस",
    "खेलकुद",  
    "संस्था / निकाय",
    "कुराकानी",  
    "मनोरञ्जन",  
    "प्रवास",    
    "जीवनशैली",
    "घर-गृहस्थी",
    "यात्रा",
    "साहित्य",  
    "प्रविधि", 
    "शिक्षा",
    "स्वास्थ्य",  
    "भिडियो", 
    "चुनाब", 
    "मौसम",
    "कार्यक्रम / प्रतियोगिता",
    "ज्योतिष शास्त्र",
    "धर्म - संस्कृति",
    "समय / अवधि",
    "तथ्यांक",
    "अन्तरसम्बन्धबोधक",
    "विविध",
    "परिस्थिति",
    "प्रकृति / वातावरण"
    ]
