import React from 'react';

import {toast} from "react-toastify";
import MaterialTable from 'material-table';
import { Grid, Row, Col} from "react-bootstrap";

import ProviderModal from "./components/modal.jsx";
import ProviderService from "../../services/providerService.js";

class ProviderMgmt extends React.Component{
    
    constructor(props){
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            providers: [],
            totalCount : 0,
            pageSize:100,    
        
            //modal settings
            showProviderModal : false,
            selectedProvider:null,

        }
        
        this.actions = [
            {
                icon: 'add',
                isFreeAction:true,  
                tooltip: 'Create new provider',
                onClick: (event, rowData) => {
                    this.openCreateModal();
                },
            },
            {
                icon:"edit",
                tooltip: 'Edit Provider',
                onClick : (event,rowData)=>{
                    this.openEditModal(rowData);
                }
            }
        ]
    }

    // open create modal
    openCreateModal = ()=>{
        this.setState({
            selectedProvider:null,
            showProviderModal:true,
        })
    }

    // opens edit modal
    openEditModal=(rowData)=>{
        this.setState({
            selectedProvider:rowData,
            showProviderModal:true,
        })
    }

    // handles toggling the provider modal
    toggleProviderModal = (value) =>{
        this.setState({
            showProviderModal:value
        })
    }

    // handles closing provider modal
    handleModalClose = () =>{
        this.toggleProviderModal(false);
    }

    // fetch providers
    fetchProviders = async (query) => {
        return new Promise(async (resolve, reject) => {
            let response = await ProviderService.fetchProviders(query.page,query.pageSize,query.search);
            if(response.success){
                this.setState({
                    providers:response.data.providers,
                    totalCount: response.data.totalCount,
                    pageSize:query.pageSize
                })
                resolve({
                    data: this.state.providers,
                    page: query.page,
                    totalCount: this.state.totalCount,
                });
            }else{
                toast.error(response.message);            
            }
        })
    }

    // reloads the table data
    handleRefresh =(e)=>{
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    render(){
        return <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <MaterialTable
                            title = "Provider Management" 
                            tableRef = {this.tableRef}
                            actions = {this.actions}
                            data = {this.state.providers}
                            options={{
                                debounceInterval : 400,
                                search : true,
                                pageSize : this.state.pageSize,
                                pageSizeOptions : [5,10,15],
                                headerStyle: {
                                    backgroundColor: '#01579b',
                                    color: '#FFF',
                                    fontSize: "15px"
                                },
                                rowStyle: rowData=> ({
                                    color:(parseInt(rowData.active) == 0) ? "red" : "black",
                                })
                            }}
                            columns = {[
                                {title:"Name",field:"name"},
                                {title:"Language",field:"language"},
                                {title:"Landing URL", field:"landing_url"},
                                {title:"RSS URL", field:"rssFeedUrl"}
                            ]}
                            data = {this.fetchProviders}
                        />
                    </Col>
                </Row>
                <ProviderModal 
                    show = {this.state.showProviderModal} 
                    data = {this.state.selectedProvider}
                    onClose = {this.handleModalClose}
                    onRefresh = {this.handleRefresh}
                />
            </Grid>
        </div>
    }

}


export default ProviderMgmt;