// IN USE
import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { Grid, ButtonToolbar, DropdownButton, MenuItem, Button } from "react-bootstrap";

import { getValue } from "../../utils/object";
import tagService from "../../services/tagsService";
import ConfirmModal from './ConfirmModal/confirm.modal';
import TagDetailModal from "./TagDetailModal/tagDetailModal";

const getAgoString = require("../../utils/timeAgo");

class TagsManagement extends Component{

    constructor(props){
        super(props);

        this.confirmModalTypes = {
            BULK : "BULK",
            SINGLE: "SINGLE"
        }

        this.state = {
            tags: [],
            show: false,
            pageSize: 100,
            isLoading: false,
            selectedFilter: 0,
            selectedLanguage: 0,
            preserveState: false,
            selectedTags: [],
            ignoreTags: [],
            filterQuery: { "language": "NP" },
            bulkUpdateConfirm: {
                isValid: false,
                show: false,
                modalData: {
                    title: "Confirmation Required",
                    body: "Are you sure you want to update these tags ?",
                },
                actions: [],
            },
            tagDetailModal: {
                show: false,
                similarTags: [],
                duplicateTags: [],
                selectedTags: [],
                similarSearchText: "",
                type: this.confirmModalTypes.SINGLE,
            }

        }

        this.modalRef = React.createRef();
        this.tableRef = React.createRef();

        this.filterOptions = [
            {  
                id:0,
                name:"All",
                action: () => this.changeFilter(0,{})
            },
            {
                id:1,
                name:"Waiting",
                action : () => this.changeFilter(1,{"isProcessed":false},"status")
            },
            {
                id:2,
                name:"Valid",
                action : () => this.changeFilter(2,{"isValid":true,"isProcessed":true},"status")
            },
            {
                id:3,
                name:"Invalid",
                action : () => this.changeFilter(3,{"isValid":false,"isProcessed":true},"status")
            },
        ]

        this.languageOptions = [
            {
                id:0,
                name:"Nepali",
                action : () =>this.changeFilter(0,{"language":"NP"},"language")
            },
            {
                id:1,
                name:"English",
                action : () =>this.changeFilter(1,{"language":"ENG"},"language")
            }
        ]

    }

    changeFilter = (selectedId,filterCondition,type) => {
        let newState = {
            filterQuery: { ...this.state.filterQuery, ...filterCondition },
        }

        if (!type) {
            newState = { filterQuery : {} };
        }

        if( type === "status" ){
            newState.selectedFilter = selectedId;
        }else if( type === "language" ){
            newState.selectedLanguage = selectedId;
        }
        this.setState(newState);
        this.refreshTable({ preserveState: false });
    }

    refreshTable = ({ preserveState = false }) => {
        if (preserveState != this.state.preserveState){
            let newState = {
                preserveState,
            }
            if (!preserveState){
                newState.selectedTags = [];
                newState.ignoreTags = [];
            }
            this.setState(
                newState, 
                () => {
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                }
            )
            return;
        }

        this.tableRef.current && this.tableRef.current.onQueryChange()
    };

    fetchTags = async (query) => {
        return new Promise( async (resolve,reject) => {
            let tagResponse = await tagService.fetchTags(query.pageSize, this.state.filterQuery, query.search);
            let newTags = []
            if(tagResponse.success && tagResponse.data.tags){
                newTags = tagResponse.data.tags;
                newTags = newTags.map((item) => {
                    return this.formatTag(item);
                }).filter((item) => item != undefined);
                this.setState({
                    tags: newTags,
                    pageSize: query.pageSize
                })
            } else if(tagResponse.success && tagResponse.data.tags.length == 0 ) toast.error("No tags found");
            resolve({
                data : newTags,
                page : query.page,
                totalCount : query.pageSize
            })
        })
    }

    formatTag = (item) => {
        if(!item.isProcessed) item.status = "Waiting";
        else if( item.isValid) item.status = "Valid";
        else item.status = "Invalid";

        item.lastSeen = getAgoString( item.lastSeenTimestamp * 1000 );
        item.firstSeen = getAgoString( item.firstSeenTimestamp * 1000 );

        const { ignoreTags, selectedTags, preserveState } = this.state;

        if (ignoreTags && ignoreTags.length>0 && ignoreTags.includes(item.id)){
            return undefined;
        }

        if (preserveState && selectedTags && selectedTags.length>0 && selectedTags.includes(item.id)){
            item.tableData = {
                checked: true,
            };
        };

        return item;
    }

    openTagStats = ( tagData ) => {
        this.setState({
            tagStat:{ show: true, tag: tagData }
        })
    }

    hideTagStats = () => {
        this.setState({
            tagStat:{ show:false, tag:null }
        })
    }

    // handle validate or invalidate tag action
    handleUpdate = async (updateBody) =>{
        try {
            this.setState({ isLoading: true })

            const selectedTags = getValue(this.state,'tagDetailModal.selectedTags');
            let selectedTag = selectedTags[selectedTags.length-1];
    
            if( selectedTag.isValid === updateBody.isValid ){
                toast.info(selectedTag.name + " is already marked as " + selectedTag.status);
                this.setState({
                    isLoading:false,
                    tagDetailModal:{show:false,selectedTag:null,title:""}
                })
                return;
            }
    
            let response = await tagService.updateTag(selectedTag.id,updateBody);
            if(response.success){
                this.refreshTable({ preserveState: true });
                this.hideTagDetailModal(false);
                toast.success(response.data.message);
            }else{
                toast.error(response.message);
            }
            this.setState({isLoading:false});
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    // dismiss tagDetail modal
    hideTagDetailModal = async () => {

        let prevSelectedTags = getValue(this.state, 'tagDetailModal.selectedTags');
        if (prevSelectedTags && prevSelectedTags.length > 0 ) {
            prevSelectedTags.pop();
        }

        let details;
        // other tags in queue are left
        if (prevSelectedTags.length > 0) {
            const tagsLength = prevSelectedTags.length - 1;
            const currentTag = this.state.tagDetailModal.selectedTags[tagsLength];
            const tagResponse = await tagService.getTagDetails({ tagId: currentTag.id });

            const { success, data } = tagResponse;
            if ( !success && !data){
                toast.error("Unable to load tag data");
                return;
            }

            details = getValue(tagResponse, "data.details");
            if (!details) {
                toast.error("Unable to load tag data");
                return;
            }
        }

        this.setState({ 
            tagDetailModal: {
                ...this.state.tagDetailModal,
                show: false,
                selectedTag: null,
                selectedTags: prevSelectedTags
            } 
        });

        if (prevSelectedTags.length>0) {
            const { tagData, similars, duplicates } = details;
            setTimeout(() => {
                this.setState({
                    tagDetailModal: {
                        ...this.state.tagDetailModal,
                        show: true,
                        selectedTag : tagData, 
                        duplicateTags: duplicates,
                        similarTags: similars.tags,
                    }
                })
            },400);
        }
        return;
    }
    
    // show tag detail for a tag
    showTagDetailModal = async (tag = null) =>{
        const tagResponse = await tagService.getTagDetails({ tagId: tag.id });
        
        if ( !tagResponse.success && !tagResponse.data){
            toast.error("Unable to load tag data");
            return;
        }

        const details = getValue(tagResponse, "data.details");
        if (!details) {
            toast.error("Unable to load tag data");
            return;
        }

        const { tagData, similars, duplicates } = details;
        const prevSelectedTags = getValue(this.state, 'tagDetailModal.selectedTags');
        const newSelectedTags = [...prevSelectedTags, tagData];

        if(prevSelectedTags.length > 0){
            this.setState({
                tagDetailModal: {
                    ...this.state.tagDetailModal,
                    show: false,
                }
            });
            setTimeout(()=>{
                this.setState({
                    tagDetailModal: { 
                        show: true, 
                        selectedTag : tagData, 
                        duplicateTags: duplicates,
                        similarTags: similars.tags,
                        selectedTags: newSelectedTags
                    }
                })
            },400);
            return;
        }

        this.setState({
            tagDetailModal: { 
                show: true, 
                selectedTag : tagData,
                duplicateTags: duplicates,
                similarTags: similars.tags,
                selectedTags: newSelectedTags
            }
        })
    }

    showBulkConfirmModal = ( show, tags = [], markValid = true) => {
        if(show){
            let actions = [
                {
                    label:"Cancel",
                    onClick:()=>this.setState({
                        bulkUpdateConfirm:{
                            show:false,
                            actions:[]
                        }
                    }),
                },
            ];
            if(markValid){
                actions.push({
                    label: "Mark as Valid",
                    className: "primaryButton",
                    onClick: (markOthers) => this.handleValidate( tags, true, markOthers),
                })
            }else{
                actions.push({
                    label: "Mark as Invalid",
                    className: "dangerButton",
                    onClick: (markOthers) => this.handleValidate( tags, false, markOthers),
                })
            }
            
            this.setState({
                bulkUpdateConfirm:{
                    isValid: markValid,
                    show: true,
                    modalData: {
                        title: "Confirmation Required",
                        body: `You are going to ${markValid ? "validate" : "invalidate"} ${tags.length} tags. Are you sure to continue ?`,
                    },
                    actions
                }
            })
        }else{
            this.setState({
                bulkUpdateConfirm:{
                    show:false,
                    modalData:{},
                    actions:[]
                }
            })
        }
    }

    // handle bulk validate/invalidate logic
    handleValidate = async ( tags, isValid, markOthers = false ) => {

        if( this.state.isLoading === true ) {
            console.debug("Action disabled : Process is already loading");
            return;
        };

        this.setState({isLoading:true});

        try{

            let updatedTags = [];
            if (markOthers) {
                const selectedIds = tags.map((tag) => tag.id);
                updatedTags = [...this.state.tags];
                updatedTags.forEach((tag) => {
                    tag.isProcessed = true;
                    tag.isValid = selectedIds.includes(tag.id) ? isValid : !isValid;
                })    
            } else{
                updatedTags = [...tags];
                updatedTags.forEach((tag) => {
                    tag.isValid = isValid;
                    tag.isProcessed = true;
                })
            }
           
            let payload = { tags : updatedTags };
            let response = await tagService.updateTagsBulk(payload);

            if(response.success){
                toast.success("Tags have been updated");
                this.showBulkConfirmModal(false);
                this.refreshTable({ preserveState: false });
            }else{
                throw Error(response.message);
            }

        }catch(err){
            console.log("ERR")
            console.log(err);
            let message = err.message || "Something went wrong. Try again";
            toast.error(message);
        }

        this.setState({isLoading:false});
    }

    removeTagsFromList = (tags = []) => {
        let ignoreTags = [ ...this.state.ignoreTags ];

        tags.forEach((tag) => {
            const exists = ignoreTags.includes(tag);
            if(!exists) ignoreTags.push(tag);
        })
        
        this.setState({
            ignoreTags,
        }, () => {
            this.refreshTable({ preserveState: true });
        });
    }

    handleSelectionChange = ({ rows }) => {
        const selectedIds = rows.map((tag) => tag.id);
        this.setState({
            selectedTags: selectedIds
        })
    }

    // handles refreshing tagData, duplicate + similar tags
    handleRefreshTag = async ({ tagId, similarSearchText }) => {
        try {

            const tagResponse = await tagService.getTagDetails({ tagId, similarSearchText });

            if ( !tagResponse.success && !tagResponse.data){
                toast.error("Unable to load tag data");
                return;
            }
    
            const details = getValue(tagResponse, "data.details");
            if (!details) {
                toast.error("Unable to load tag data");
                return;
            }
    
            const { tagData, similars, duplicates, searchText } = details;
            this.setState({
                tagDetailModal: {
                    ...this.state.tagDetailModal,
                    selectedTag : tagData,
                    duplicateTags: duplicates,
                    similarTags: similars,
                    similarSearchText: searchText,
                }
            })

        } catch (err) {
            console.log(err);            
        }
    }

    render(){

        const selectedTags = getValue(this.state,"tagDetailModal.selectedTags");
        
        let selectedTag = {};
        if(selectedTags && selectedTags.length > 0){
            const tagsLength = selectedTags.length - 1;
            selectedTag = getValue(this.state, `tagDetailModal.selectedTags[${tagsLength}]`);;
        }

        const { show: showDetailModal, similarTags, duplicateTags, similarSearchText } = this.state.tagDetailModal;

        return <div className="content">
            <Grid fluid>
                <MaterialTable
                    title = "Tag Management"
                    data = {this.state.tags}
                    tableRef = {this.tableRef}
                    columns = {[
                        {
                            title:"Tag",
                            field :"name",
                            render: (rowData) =><span className="clickable" onClick={async ()=> await this.showTagDetailModal(rowData)}>{rowData.name}</span>
                        },
                        {
                            title:"Duplicate-Of",
                            field:"duplicateOf",
                            render: (rowData) => 
                                <span>
                                    {(rowData.duplicateOf && rowData.source && rowData.source.name) ? 
                                        <span className="clickable" onClick={() => this.showTagDetailModal(rowData.source)}>{rowData.source.name}</span> :  
                                        "-"
                                    }
                                </span>
                        },
                        {title:"Status", field :"status"},
                        {title:"First Seen", field :"firstSeen"},
                        {title:"Last Seen", field :"lastSeen"},
                    ]}
                    actions={[
                        {
                            icon: () => <ButtonToolbar>
                                <DropdownButton
                                    pullRight 
                                    className="primaryButton"
                                    title="Language" 
                                    id="dropdown-basic-Primary"
                                >
                                {this.languageOptions.map((filterItem,index)=>
                                    <MenuItem 
                                        active = {filterItem.id===this.state.selectedLanguage ? true : false}
                                        key={index} onClick={filterItem.action}
                                    >
                                        {filterItem.name}
                                    </MenuItem>
                                )}
                                </DropdownButton>
                            </ButtonToolbar>,
                            isFreeAction : true,          
                        },
                        {
                            icon: () => <ButtonToolbar>
                                <DropdownButton
                                    pullRight 
                                    className="primaryButton"
                                    title="Status" 
                                    id="dropdown-basic-Primary"
                                >
                                {this.filterOptions.map((filterItem,index)=>
                                    <MenuItem 
                                        active = {filterItem.id===this.state.selectedFilter ? true : false}
                                        key={index} onClick={filterItem.action}
                                    >
                                        {filterItem.name}
                                    </MenuItem>
                                )}
                                </DropdownButton>
                            </ButtonToolbar>,
                            isFreeAction:true,          
                        },
                        { 
                            icon:() => <Button className="primaryButton">Mark as Valid </Button>,
                            onClick: ( evt, tags ) => this.showBulkConfirmModal( true, tags, true ), 
                        },
                        { 
                            icon:() => <Button className="dangerButton">Mark as Invalid </Button>,
                            onClick: ( evt, data ) => this.showBulkConfirmModal( true, data, false ),
                        }
                    ]}
                    onSelectionChange = {(rows) => this.handleSelectionChange({ rows })}
                    options = {{
                        headerStyle: { position: 'sticky', top: 0 },
                        maxBodyHeight: 500,
                        search : true,
                        selection : true,
                        debounceInterval : 400,
                        pageSize : this.state.pageSize,
                        pageSizeOptions: [10,20,50,100,200,300,400,500],
                        headerStyle: {
                            color: '#FFF',
                            fontSize: "15px",
                            backgroundColor: '#01579b',
                        },
                        rowStyle:(rowData)=>{
                            return {
                                fontWeight:"bold",
                                color : rowData.status == "Invalid" ? "red" : rowData.status =="Valid" ? "green" : "black"
                            }
                        }
                    }}
                    data = {this.fetchTags}
                />;

            </Grid>

            {/* bulk Update Confirm Modal */}
            <ConfirmModal 
                isValid = {this.state.bulkUpdateConfirm.isValid}
                metaData = {this.state.bulkUpdateConfirm.modalData} 
                show = {this.state.bulkUpdateConfirm.show} 
                actions = {this.state.bulkUpdateConfirm.actions}
                onHideHandler = {() => this.showBulkConfirmModal(false)}
                isLoading = {this.state.isLoading}
            >
            </ConfirmModal>
 
            <TagDetailModal 
                tag = { selectedTag } 
                show = { showDetailModal } 
                similarTags = { similarTags }
                duplicateTags = { duplicateTags }
                searchText = { similarSearchText }
                handleHideModal = {() => this.hideTagDetailModal()}
                openTagData = {(tagData) => this.showTagDetailModal(tagData)} 
                removeTagsFromList = {(tagIds) => this.removeTagsFromList(tagIds)}
                handleRefreshTable = { (preserveState) => this.refreshTable(preserveState) }
                handleRefreshTag = {({ tagId, similarSearchText }) => this.handleRefreshTag({ tagId, similarSearchText})}
            />
        </div>
    }
}

export default TagsManagement;