const axios = require('axios')
const qs = require('query-string');
const remote = require('../config/remote.js');

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
	var token = localStorage.getItem('accessToken');
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	}, function (error) {    
			return Promise.reject(error);
});

var loggedInUser

module.exports = {
	currentUser : loggedInUser,
	login : function(loginData){
		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/auth', loginData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
					  let err;
					  if(error.response){
						  err = error.response;
					  }else if( error.response && error.response.data){
						  err = error.response.data
					  }else{
						  err = error;
					  }
				    resolve(err);
				  });
			});	
	},

	facebookLogin : (dataBlob) => {
		const options = {
				mode: 'cors',
				cache: 'default'
		};

		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/auth/facebook', dataBlob, options)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					resolve(error.response.data);
				});
		});


	},

	goolgleLogin : (dataBlob) => {
		const options = {
				mode: 'cors',
				cache: 'default'
		};

		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/auth/google', dataBlob, options)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if(error.message){
						resolve(error.message);
					}else{
						resolve(error.response.data);
					}
				});
		});
	},

	createUser : function (userData){
		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/users', userData)
			  .then(function (response) {
			    resolve(response.data);
			  })
			  .catch(function (error) {
			    resolve(error.response.data);
			  });
			})
	},

	verifyEmail: function(tokenData){
		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/auth/verifyEmail', tokenData)
			  .then(function (response) {
			    resolve(response.data);
			  })
			  .catch(function (error) {
			    resolve(error.response.data);
			  });
			})
	},

	sendInvite: function(userInfo){
		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/users/invite', userInfo)
			  .then(function (response) {
			    resolve(response.data);
			  })
			  .catch(function (error) {
			    resolve(error.response.data);
			  });
			})
	},

	updateUser : function (id, userData){
		return new Promise(function(resolve, reject) {
			axiosInstance.put(remote.url+'/users/'+id, userData)
			  .then(function (response) {
			    resolve(response.data);
			  })
			  .catch(function (error) {
			    resolve(error.response.data);
			  });
		  });
	},

	getCurrentUser : function (){
		return new Promise(function(resolve, reject) {
			axiosInstance.get(remote.url+'/auth')
				  .then(function (response) {
						if(response.data.success){
							loggedInUser = response.data.data
						}
				    resolve(response.data);
				  })
				  .catch(function (error) {
					if(error.message){
						resolve(error.message);
					}else{
						resolve(error.response.data);
				  	}
				  });
			});
	},	
	newTokenUser : function (){
		return new Promise(function(resolve, reject) {
			axiosInstance.get(remote.url+'/auth/newTokenUser')
				  .then(function (response) {
						if(response.data.success){
							loggedInUser = response.data.data
						}
				    resolve(response.data);
				  })
				  .catch(function (error) {
					if(error.message){
						resolve(error.message);
					}else if(error.response && error.response.data){
						resolve(error.response.data);
					}else{
						resolve(error);
					}
				  });
			});
	},


	getUsers : function (pagingParams){
		return new Promise(function(resolve, reject) {
			var pagingString= ""
			if(pagingParams){
				pagingString = '?'+qs.stringify(pagingParams)
			}
			axiosInstance.get(remote.url+'/users/'+pagingString)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

	getUser : function (id){
		return new Promise(function(resolve, reject) {
			axiosInstance.get(remote.url+'/users/'+id)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},


	deleteUser : function (id){
		return new Promise(function(resolve, reject) {
			axiosInstance.delete(remote.url+'/users/'+id)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},



	logout : function(loginData){
		return new Promise(function(resolve, reject) {
			localStorage.removeItem('accessToken');
			localStorage.removeItem("xotkariDraft");
			
			//logout from facebook
			if(window.FB){
				window.FB.logout()
			}

			//remove google auth data if exists
			try{
				const auth2 = window.gapi.auth2.getAuthInstance()
			if (auth2 != null) {
				auth2.signOut().then(
					auth2.disconnect().then(()=>{
							resolve({success: true});
						})
				)
			}
			}catch{

			}
			
			resolve({success: true});
		});
		
	},


}