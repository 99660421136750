import React from "react";
import  {Grid,Row,Col} from "react-bootstrap";
import {Card}  from "../../components/Card/Card";
import CustomArticle from './components/CustomArticleForm.jsx';

class CustomArticlePage extends React.Component{
  render(){
      return <div className="content">
        <Grid fluid>
          <Row>
            <Col md={1}></Col>
            <Col md={8}>
              <Card content={<CustomArticle  {...this.props}/>}/>  
            </Col>
            <Col md={3}></Col>
        </Row>
        </Grid>
      </div>
  }
}

export default CustomArticlePage;