import React, { Component } from "react";
import {Image} from "react-bootstrap";
import StarIcon from '@material-ui/icons/Star';

var URL= require('url-parse');
const timeAgo= require("../../utils/timeAgo.js");

export class PreviewCard extends Component {

  handleImageSelection = () => {
    this.props.onImageSelect(this.props.image);
  }
  
  render() {

    return (
      <div className={"card" + (this.props.plain ? " card-plain" : ""),"clearfix"} 
      style={{marginBottom:'10px'}}> 
        <div style={{width:'40%', float:'left'}} className={this.props.selected ? "previewImageWrapper selectedImage" : "previewImageWrapper"} onClick = {this.handleImageSelection}>
          <div className="previewImageOverlay">
            <StarIcon  style={{fontSize:20}}/> 
          </div>
          <Image referrerPolicy="no-referrer" src={this.props.image} className="previewImage" responsive/>
        </div>
        <a href={this.props.url} target='blank'>
            <div className="preview_card" style={{width:'60%',float:'right',paddingLeft:'12px'}}>
            <div style={{fontSize:'17px'}}><b>{this.props.title}</b></div>
            <div style={{fontSize:'13px'}} className="clearfix">
              <span className="float-left">  
                {this.props.url ? URL(this.props.url).hostname : ""}
              </span>
              <span className="float-right">
                {this.props.publishTime ? timeAgo(this.props.publishTime) : ""}
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default PreviewCard;
