
import LoginSignup from "../views/user/LoginSignup";
import VerifyEmail from "../views/user/VerifyEmail";

const userRoles = require('../const/userRoles')

const loginRoutes = [
  {
    path:"/login",
    name:"Login",
    component:LoginSignup,
    icon:"pe-7s-pen",
    layout:"/admin",
    showInSideBar:true,
  },
  // {
  //   path: "/emailVerification/:token",
  //   name: "Verify Email",
  //   component: VerifyEmail,
  //   showInSideBar:false,
  // },
];

export default loginRoutes;
