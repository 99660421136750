import React,{useState} from "react";

import {
    Modal,
    Button,
    Col,Row,
    FormGroup,
    FormControl,
    ControlLabel,
} from "react-bootstrap";
import { toast } from "react-toastify";

const ProviderService = require("../../../services/providerService.js");

class ProviderModal extends React.Component{

    constructor(props){
        super(props);
        this.state={
            _id:null,
            loading:false,
            name:"",
            landing_url:"",
            rssFeedUrl:"",
            status:1,
            language:"NP",
            languages:[],
        }
    }

    async componentDidMount(){
        let response = await ProviderService.fetchLanguages();
        if(response.success){
            this.setState({
                languages:response.data.languages
            })
        }else {
            toast.error(response.message);
        }

        if(this.props.providerData){
            let providerData = this.props.providerData;
            let {_id,name,landing_url,active,language,rssFeedUrl} = providerData;
            this.setState({
                _id,
                name,
                landing_url,
                rssFeedUrl,
                language,
                status:active
            })
        }
    }

    async componentDidUpdate(prevProps){
        if(prevProps==null || ( this.props!=prevProps && this.props.show && this.props.data)){
            let {name,landing_url,active,language,_id,rssFeedUrl} = this.props.data;
            this.setState({
                name,landing_url,rssFeedUrl,
                language,_id,
                status:active
            })
        }
    }

    // handle input field changes
    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // handles closing provider modal
    closeModal=(e)=>{
        
        if(this.state.loading) return;

        this.setState({
            _id:null,
            name:"",
            landing_url:"",
            rssFeedUrl:"",
            status:1,
            language:"NP",
        })
        this.props.onClose();
    }

    validateProvider = (providerData) =>{
        if(!providerData.name || providerData.name.trim()==""){
            toast.error("Name is required");
            return false;
        }

        if(!providerData.landing_url || providerData.landing_url.trim()==""){
            toast.error("Landing Url is required");
            return false;
        }
        
        if(!providerData.landing_url.startsWith("http://") && !providerData.landing_url.startsWith("https://")){
            toast.error("Landing url scheme is required. E.g http://example.com");
            return false;
        }

        if(providerData.rssFeedUrl && !providerData.rssFeedUrl.startsWith("http://") && !providerData.rssFeedUrl.startsWith("https://")){
            toast.error("Rss Feed url scheme is required. E.g http://example.com");
            return false;
        }
        return providerData;
    }

    handleUpdate = async(e) =>{

        // validate data
        let providerData = {
            name:this.state.name,
            landing_url:this.state.landing_url,
            rssFeedUrl:this.state.rssFeedUrl,
            language : this.state.language,
            active: parseInt(this.state.status)
        };

        let isValid = this.validateProvider(providerData);
        if(!isValid) return;

        this.setState({loading:true});

        let response = await ProviderService.updateProvider(this.state._id,providerData);

        this.setState({loading:false});
        if(response.success){
            toast.success(response.data.message);
            this.closeModal();
            this.props.onRefresh();
        }else{
            toast.error(response.message);
        }
    
    }

    // handles Adding Provider
    handleAdd = async (e) => {

        // validate data
        let providerData = {
            name:this.state.name,
            landing_url:this.state.landing_url,
            rssFeedUrl : this.state.rssFeedUrl,
            language : this.state.language,
            active: parseInt(this.state.status)
        };

        let isValid = this.validateProvider(providerData);
        if(!isValid) return;

        this.setState({loading:true});

        // send data to backend
        let response = await ProviderService.createProvider(providerData);
    
        this.setState({loading:false});

        // check success
        if(response.success){
            toast.success(response.data.message);
            this.closeModal();
            this.props.onRefresh();
        }else{
            toast.error(response.message);
        }
    

    }

    render(){
        return  <Modal style={{zIndex:1200}} show={this.props.show}>
            <Modal.Header>{this.props.title}</Modal.Header>    
            <Modal.Body>
                <FormGroup controlId="_id">
                    <ControlLabel> Provider ID</ControlLabel>
                    <FormControl id="_id" disabled name="_id" value={this.state._id} required/>
                </FormGroup>
                <FormGroup controlId="name">
                    <ControlLabel> Provider Name</ControlLabel>
                    <FormControl id="name" name="name" value={this.state.name} onChange={this.handleChange} required/>
                </FormGroup>
                <FormGroup controlId="landing_url">
                    <ControlLabel> Landing Url</ControlLabel>
                    <FormControl id="landing_url" name="landing_url" value={this.state.landing_url} onChange={this.handleChange} required />
                </FormGroup>
                <FormGroup controlId="rssFeedUrl">
                    <ControlLabel> Rss Feed</ControlLabel>
                    <FormControl id="rssFeedUrl" name="rssFeedUrl" value={this.state.rssFeedUrl} onChange={this.handleChange} />
                </FormGroup>
                <Row>
                    <Col md={6}>
                        <FormGroup controlId="status">
                            <ControlLabel>Status</ControlLabel>
                            <FormControl name="status" onChange={this.handleChange}
                                value={this.state.status } 
                                componentClass="select" placeholder="select">
                                <option value={1} >Active</option>
                                <option value={0}>Inactive</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup controlId="language">
                            <ControlLabel>Language</ControlLabel>
                            <FormControl name="language" onChange={this.handleChange}
                                value={this.state.language } 
                                componentClass="select" placeholder="select">
                                {this.state.languages.map((lang)=>{
                                    return <option value={lang}>{lang}</option>
                                })}
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            
                <div>
                {this.state._id ? 
                    <Button disabled ={this.state.loading} className="primaryButton" onClick={this.handleUpdate}> 
                        Edit
                    </Button>
                    :
                    <Button disabled = {this.state.loading} className="primaryButton" onClick={this.handleAdd}> 
                        Add
                    </Button>
                }
                    <Button disabled = {this.state.loading} onClick={this.closeModal}> 
                        Close
                    </Button>
                </div>
                
                
            </Modal.Footer>
        </Modal>
    }

}

export default ProviderModal;