import React from 'react';
import { withRouter } from 'react-router-dom'

const userService = require('../../services/userServices');

class VerifyEmail extends React.Component{
    state = {
        verified : false
    }
    componentDidMount(){
        const { match: { params } } = this.props;
        var verificationToken = params.token
        this.verify(verificationToken);
    }

    async verify(token){
        //logout if any user is previously logged in
        await userService.logout();
        var response = await userService.verifyEmail({ token });
        if(response.success){
            this.setState({verified: true});
        }
    }

    async openLogin(){
        this.props.history.push('/');
    }

    render(){
        return (
            <div class="home-main bg-white">
                <div class="container mx-auto w-full md:py-6">
                    <div class="flex m-auto mt-6 mx-auto w-full max-w-lg">
                        <div class="w-full font-sans text-center">
                            {
                                this.state.verified ?
                                <>
                                    <p class="p-4 text-xl text-bold leading-normal">Successfully verified your email. You can now login.</p>                        
                                    <button onClick={this.openLogin.bind(this)} class="mt-2 md:mt-0 py-4 px-8 text-sm font-black border border-purple-dark hover:border-purple-darkest text-black bg-white uppercase rounded font-mono">Login</button>
                                </> 
                                : 
                                <p class="p-4 text-xl text-bold text-white leading-normal">Verifying...</p>                        
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    

};


export default withRouter(VerifyEmail);