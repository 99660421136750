import React from "react";
import MaterialTable from 'material-table';

import articleService from "../../../services/articleService.js";

const customRemote = require('../../../config/remote.js');
const customUrl = customRemote.customURL; 

class MostViewedArticles extends React.Component{

    constructor(props){
        super(props);
        this.state={
            articles:[],
        }
    }

    formatDate = (date) => {
        return date.toDateString() + ", " + date.getHours() + ":" + date.getMinutes();
    }
    
    fetchArticles=(query)=>{
        return new Promise(async (resolve, reject) => {
            let Articles = await articleService.topCustomArticles({limit : 5, page : 0});
            let date, exp_date;
            Articles.data.forEach((article)=>{
                if(article.publish_date){
                    date = new Date(article.publish_date);
                    article["publish_date"] = this.formatDate(date);
                }
                if(article.expiry_date){
                    exp_date = new Date(article.expiry_date);
                    article["expiry_date"] = this.formatDate(exp_date);
                }
                article["state"]=(article.publish_date!=null && (article.expiry_date!=null && parseInt(new Date(article.expiry_date).getTime()) >= new Date().getTime())) ? "active" : "expired";
            });
            this.setState({
                articles:Articles.data,
            })
            resolve({
                data: this.state.articles,
                page: query.page,
                totalCount: Articles.totalCount,
            });
        })
    }

    render(){
        return <MaterialTable
        tableRef={this.tableRef}
        columns={[
            { title: 'Title', field: 'title',
                render:rowData => <a style={{color:rowData.state=="expired" ? "grey" : "#1DC7EA"}}  href={customUrl + "/article?id=" + rowData._id + "&type=custom"} target='_blank'>{rowData.title}</a> },
            { title: 'Writer', 
                render:rowData => <span>{rowData.writer.firstName +" " +  rowData.writer.lastName}</span> },
            { title: 'Publish Date', field: 'publish_date' },
            { title: 'Views', field: 'views' },
            { title: 'Status',field:'state'}
                
        ]}
        data={this.state.articles}
        title="Most Viewed (Last 30 days)"
        options={{pageSize:5, search: false, pageSizeOptions:[5,10],
        headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF',
            fontSize:"15px"
        },
        rowStyle: rowData=>({
                backgroundColor: '#EEE',
                fontSize:"20px",
                fontWeight:"600",
                color:(rowData.state == "expired") ? "grey" : "black",    
            })
        }}
        data={this.fetchArticles}
    />
    }

}

export default MostViewedArticles;