import React from "react";

import {
    Row,Col,
    Form, FormGroup, ControlLabel,
    FormControl, 
    Button, Modal,ModalBody
} from "react-bootstrap";
import {toast} from "react-toastify";   
import LoadingOverlay from "react-loading-overlay";

import ScheduleModal from "./scheduleModal.jsx";
import ArticleService from "../../../services/articleService.js";
import FileInput from "../../../components/FileInput/FileInput.jsx";
import config, { imageArticleRatio } from "../../../const/config.js";
import ImageCropper from "../../../components/ImageCropper/imageCropper.jsx";

class ImageArticleForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            cropMode:false,

            editMode:false,
        
            id:null,
            selectedFile:null,
            message:"",
            url:"https://xotkari.com",
            action:"share",
            target:"image",
            expandedView:false,
            showButton:false,
            buttonText:"Share",

            expiryDate:null,
            publishDate:null,
            facebookShare:false,
            twitterShare:false,
            instagramShare:false,
            showScheduleModal:false,

            isLoading : false,
            loadingMessage : "Publishing Article ..."
        }
    }

    componentDidMount(){
        const {match : {params}} = this.props;
        let articleId = params.id;
        if(articleId){
            this.initialize(articleId);
        }
    }

    initialize = async (id) =>{
        this.handleLoadingOverlay(true,"Loading Article ...");
        let response=await ArticleService.getArticle(id);
        if(!response.success){
            toast.error(response.message);
            return;
        }
        console.log(response);
        let article = response.data;
        this.setState({
            editMode:true,
            id:id,
            selectedFile:article.img_url,
            message:article.message,
            url:article.url,
            action:article.action,
            target:article.target,
            expandedView:article.expandedView,
            showButton:article.showButton,
            buttonText:article.buttonText,
            publishDate:article.publish_date ? new Date(article.publish_date).getTime() : null,
            expiryDate : article.expiry_date ? new Date(article.expiry_date) : null,
            facebookShare : article.facebookId ? true : false,
            twitterShare : article.twitterId ? true : false,
        });
        
        this.handleLoadingOverlay(false,"Loading Article ...");
    }
    
    handleLoadingOverlay = (show,message) =>{
        this.setState({
            isLoading: show,
            loadingMessage : message
        })
    }

    toggleCheck=(e)=>{
        this.setState({
            [e.target.name]:e.target.checked
        })
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    
    closeLoading = (e)=>{
        this.setState({
            isLoading:false,
        })
    }

    handleSubmit= async (e)=>{
        e.preventDefault();
        this.setState({
            isLoading : true,
            loadingMessage : "Publishing Article ..."
        })
        if(!this.state.selectedFile){
            toast.error("Image File not found");
            this.closeLoading(e);
            return;
        }
        if(this.state.cropMode){
            toast.error("Image Cropping not complete ");
            this.closeLoading(e);
            return;
        }
        if(this.state.message==""){
            toast.error("Message Field is empty");
            this.closeLoading(e);
            return;
        }

        let formData = {
            image:this.state.selectedFile,
            title:this.state.message,
            message:this.state.message,
            url:this.state.url,
            action:this.state.action,
            target:this.state.target,
            expandedView:this.state.expandedView,
            showButton:this.state.showButton,
            buttonText:this.state.buttonText,
        }
        
        let response = await ArticleService.postImageArticle(formData);
        console.log(response);
        if(response.success && this.props.currentUser.userRole <=1){
            toast.success("Congrats, Your Article Has Been Published");
        }else if( response.success && this.props.currentUser.userRole >= 1 ){
            
            let publishDate = this.state.publishDate ? this.state.publishDate : new Date().getTime();
            this.setState({
                publishDate,
                showScheduleModal:true,
                id: response.article._id,
            })
        }else{
            toast.error(response.message);
        }

        this.setState({
            isLoading:false
        })
    }

    handleEditArticle = async(e)=>{
        this.setState({
            isLoading:true,
            loadingMessage:"Updating Article ..."
        })

        if(!this.state.selectedFile){
            toast.error("Image File not found");
            return;
        }
        if(this.state.cropMode){
            toast.error("Image Cropping not complete ");
            return;
        }
        if(this.state.message==""){
            toast.error("Message Field is empty");
            return;
        }

        let formData = {
            title:this.state.message,
            message:this.state.message,
            url:this.state.url,
            action:this.state.action,
            target:this.state.target,
            expandedView:this.state.expandedView,
            showButton:this.state.showButton,
            buttonText:this.state.buttonText,
        }
        
        if(this.state.selectedFile.base64){
            formData.image = this.state.selectedFile;
        }

        let response = await ArticleService.editImageArticle(this.state.id,formData);
        if(response.success && this.props.currentUser.userRole <=1){
            toast.success("Congrats, Your Article Has Been Published");
        }else if( response.success && this.props.currentUser.userRole >= 1 ){
            this.setState({
                showScheduleModal:true,
                id: response.article._id,
            })
        }else{
            toast.error(response.message);
        }
        this.setState({
            isLoading:false
        })
    }

    handleImageSelection = (fileInfo)=>{
        let fileData = {
            name: fileInfo.name,
            aspectRatio: fileInfo.aspectRatio,
            size: fileInfo.size,
            base64 : fileInfo.base64,
            type: fileInfo.type
        }
        let cropMode = true;
        if(fileData.aspectRatio <=imageArticleRatio.max && fileData.aspectRatio >=imageArticleRatio.min){
            cropMode = false;
        }
        this.setState({
            selectedFile : fileData,
            cropMode,
        });
    }

    cancelSchedule = (e)=>{
        this.setState({
            showScheduleModal:false
        })
        let message = "Congrats, Your Article has been published";
        if(this.state.editMode){
            message = "Your Article has been edited";
        }
        toast.success(message);
        this.props.history.push("/admin/profile");
    }

    onDoneSchedule =(e)=>{
        this.props.history.push("/admin/profile");
    }

    cancelCrop = (e) => { 
        this.setState({
            showCropper:false,
        })
    }

    confirmCrop = (croppedImage) => {
        let selectedFile = this.state.selectedFile;
        selectedFile.base64 = croppedImage.base64;
        selectedFile.aspectRatio = croppedImage.aspectRatio;
        this.setState({
            cropMode:false,
            selectedFile
        })
    }

    discardImageSelection = () =>{
        this.setState({
            cropMode:false,
            selectedFile:null,
        })
    }

    render(){

        let imageSelection;

        if(this.state.selectedFile && this.state.editMode && !this.state.cropMode) {
            imageSelection = <>
                    <img src={this.state.selectedFile.base64 ? this.state.selectedFile.base64 : this.state.selectedFile} className="img-fluid"/>
                    <br></br>
                    <Button bsSize="sm" onClick={this.discardImageSelection} className="successBtn">Change Image</Button>
                </>
        } else if(this.state.selectedFile && this.state.cropMode){
            imageSelection = <>
                <ImageCropper 
                    onConfirm = {this.confirmCrop.bind(this)}
                    onCancel = {this.discardImageSelection.bind(this)}
                    show={this.state.showCropper} 
                    image={this.state.selectedFile ? this.state.selectedFile.base64 : ""}
                />
                <br></br>
            </>
        }else if(this.state.selectedFile){
            imageSelection=  <>
                    <img src={this.state.selectedFile.base64} className="img-fluid" />
                    <br></br>
                    <Button bsSize="sm" onClick={this.discardImageSelection} className="successBtn">Change Image</Button>
                </>
        }else{
            imageSelection =  <FileInput 
                files={this.state.selectedFile ? [this.state.selectedFile] : []}
                allowTypes={["image/png","image/jpg","image/jpeg"]}
                allowMultiple={false}
                onSelected = {this.handleImageSelection.bind(this)}
            />
        }
    
        return <>

           
            <ScheduleModal 
                showModal={this.state.showScheduleModal} 
                id={this.state.id}
                twitterShare={this.state.twitterShare}
                instagramShare= {this.state.instagramShare}
                facebookShare = {this.state.facebookShare}
                publishDate = {this.state.publishDate}
                expiryDate = {this.state.expiryDate}
                onCancel = {this.cancelSchedule.bind(this)}
                onDone = {this.onDoneSchedule.bind(this)}
            />

            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text={this.state.loadingMessage}
                styles={{
                    wrapper: {
                    zIndex: '100000'
                    }
                }}
            >

            <Form>
                <Row style={{marginBottom:"30px"}}>
                    <Col lg={6}>
                        {imageSelection}
                    {/* { (this.state.selectedFile && this.state.cropMode) ? 
                        <>
                            <ImageCropper 
                                onConfirm = {this.confirmCrop.bind(this)}
                                onCancel = {this.discardImageSelection.bind(this)}
                                show={this.state.showCropper} 
                                image={this.state.selectedFile ? this.state.selectedFile.base64 : ""}
                            />
                            <br></br>
                        </>
                    : (this.state.selectedFile) ?
                        <>
                            <img src={this.state.selectedFile.base64} className="img-fluid" />
                            <br></br>
                            <Button bsSize="sm" onClick={this.discardImageSelection} className="successBtn">Change Image</Button>
                        </>
                        : 
                        <FileInput 
                            files={this.state.selectedFile ? [this.state.selectedFile] : []}
                            allowTypes={["image/png","image/jpg","image/jpeg"]}
                            allowMultiple={false}
                            onSelected = {this.handleImageSelection.bind(this)}
                        />
                    } */}
                    </Col>
                        
                        <Col lg={6} style={{paddingLeft:"25px"}}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <ControlLabel>Message</ControlLabel>
                                        <FormControl type="text" 
                                            placeholder="Enter Message" 
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                            required
                                            />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <ControlLabel>URL</ControlLabel>
                                        <FormControl type="text" 
                                            placeholder="Enter URL" 
                                            name="url"
                                            value={this.state.url}
                                            onChange={this.handleChange}
                                            required
                                            />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup controlId="formControlsSelect">
                                        <ControlLabel>Action</ControlLabel>
                                        <FormControl componentClass="select" 
                                            onChange={this.handleChange} 
                                            name="action"
                                            value = {this.state.action}
                                            placeholder="select"
                                            >
                                            <option value="share">Share</option>
                                            <option value="link">Link</option>
                                            <option value="dial">Dial</option>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup controlId="formControlsSelect">
                                        <ControlLabel>Target</ControlLabel>
                                        <FormControl componentClass="select" 
                                            name="target"
                                            onChange={this.handleChange}
                                            placeholder="select"
                                            value = {this.state.target}
                                            >
                                            <option value="image">Image</option>
                                            <option value="link">Link</option>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                    {/* <input 
                                        id="expandedView"
                                        name="expandedView"
                                        type="checkbox"
                                        onChange={this.toggleCheck}
                                        checked={this.state.expandedView}
                                        />
                                    <label htmlFor="expandedView"  style={{paddingLeft:"10px",paddingRight:"10px"}}>Expanded View</label> */}
                                    
                                    <input 
                                        id="showButton"
                                        name="showButton"
                                        type="checkbox"
                                        onChange={this.toggleCheck}
                                        checked={this.state.showButton}
                                        />
                                    <label htmlFor="showButton"  style={{paddingLeft:"10px",paddingRight:"10px"}}>Show Button</label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.showButton ? 
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <ControlLabel>Button Text</ControlLabel>
                                            <FormControl type="text" 
                                                placeholder="Button Text" 
                                                name="buttonText"
                                                value={this.state.buttonText}
                                                onChange={this.handleChange}
                                                required
                                                />
                                        </FormGroup>
                                    </Col>
                                </Row>    
                                : 
                                <></>
                            }

                            {this.state.editMode ? 
                                <Button onClick={this.handleEditArticle} className="primaryBtn" >Save Changes</Button>
                            : 
                                <Button onClick={this.handleSubmit} className="primaryBtn" >Submit Article</Button>
                            }
                            
                        </Col>
                </Row>
            </Form>
            </LoadingOverlay>
        </>
    }
}

export default ImageArticleForm;