const axios = require('axios');
const remote = require('../config/remote.js');

// const utils = require('./utils');
// const qs = require('query-string');

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
	var token = localStorage.getItem('accessToken');
	if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	}, function (error) {    
			return Promise.reject(error);
}); 	


const API_BASE_URL = remote.url;
 
module.exports = {
	providerArticleAnalytics : function(source,frequency,filterData){
		return new Promise( function(resolve, reject) {
			var filterString = '/analytics/'+source+"/"+frequency;
			axiosInstance.post(API_BASE_URL+filterString,filterData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
}