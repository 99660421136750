import React from 'react';

import {Modal,Button,Row,Col} from "react-bootstrap";
import PreviewCard from '../../../components/PreviewCard/PreviewCard.jsx';

function WarningModal (props) { 
    return <Modal show = {props.show}>
        <Modal.Header>
            Remove Fact
        </Modal.Header>
        <Modal.Body  style={{maxHeight:"60vh",overflow:"auto"}}>
            This fact contains some content. You will lose the following content if you remove this fact. Are you sure you want to remove it ?
            <hr></hr>
            {props.data!=null &&  <div dangerouslySetInnerHTML={{__html:props.data.Claim}} />}
            <br></br>
            {props.data!=null  && props.data.ProofArray.map((proof)=>{
                return <Row>
                    <Col md={11}>
                        {proof.html? <div>
                                <div dangerouslySetInnerHTML={ {__html:proof.html}} ></div>
                            </div>
                            : 
                            <PreviewCard 
                                selected = {false}
                                onImageSelect = {()=>{}}
                                title={proof.title}
                                image={proof.image}
                                url={proof.link} 
                                publishTime = {proof.publishedTime}
                            />
                        }
                    </Col>
                </Row>
            })}
       
        </Modal.Body>
        <Modal.Footer>
            <Button className="dangerButton" onClick={props.onRemove}>
                Remove
            </Button>
            <Button className="primaryButton" onClick={props.onCancel} >
                Cancel
            </Button>
        </Modal.Footer>   
    </Modal>    
}

export default WarningModal;