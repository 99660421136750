import React,{Component} from "react";

import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from 'react-bootstrap';

import ReactCrop from 'react-image-crop';
import {toast} from 'react-toastify';

import 'react-image-crop/dist/ReactCrop.css';
import { imageArticleRatio } from "../../const/config";

class ImageCropper extends Component{

    constructor(props){
        super(props);
        this.state = {
            image:"",
            crop:{
                x:0,
                y:0,
                height:0,
                width:0
            },
            showModal:false,
            croppedImage : null,
        }
    }

    componentDidMount(){
        this.setState({
            showModal:this.props.show,
            image:this.props.image,
            croppedImage:this.props.image,
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.show && ( this.props.show!=prevProps.show || !prevProps.show)){
            this.setState({
                showModal:this.props.show,
                image:this.props.image,
                croppedImage:this.props.image,
            })
        }
    }

    handleClose = () =>{
        this.props.onCancel();
    }

    setCrop = (newCrop) =>{
        this.setState({
            crop:newCrop
        })
        
    }

    onImageLoaded = image => {
        this.imageRef = image;
        this.setState({ crop: { x:0,y:0,width: image.width, height: image.height } });
        return false; // Return false when setting crop state in here.
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            let croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImage });
        }
    }

    getCroppedImg(image, crop, fileName) {
        let canvas = document.createElement('canvas');
        let scaleX = image.naturalWidth / image.width;
        let scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        let ctx = canvas.getContext('2d');

        console.log("Natural Width : " + image.naturalWidth);
        console.log("Natural Height :" + image.naturalHeight);
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        // As Base64 string
        let base64Image = canvas.toDataURL('image/jpeg');

        return base64Image;
    }
    
    handleConfirm = () =>{
        let aspectRatio = this.state.crop.width / this.state.crop.height;
        if(aspectRatio > imageArticleRatio.max){
            toast.error("Selection is too wide");
            return;
        }else if(aspectRatio<imageArticleRatio.min){
            toast.error("Selection is too tall");
            return;
        }
        
        let croppedImage = {
            base64:this.state.croppedImage,
            aspectRatio,
        };
        this.setState({
            showModal:false,
            croppedImage:null,
            image:null,
        })

        this.props.onConfirm(croppedImage);
    }
    
    render(){

        return <>
            {this.state.image ?
                <>
                    <ReactCrop 
                        src={this.state.image} 
                        crop={this.state.crop} 
                        onImageLoaded = {this.onImageLoaded}
                        onChange={newCrop => this.setCrop(newCrop)} 
                        onComplete={this.onCropComplete}
                        keepSelection = {true}
                    />
                    <br></br>
                    <Button bsSize="sm" bsStyle="success" className="successBtn mr--15" onClick = {this.handleConfirm}>Crop</Button>
                    <Button bsSize="sm" bsStyle="info" className="primaryBtn" onClick = {this.handleClose}>Discard</Button>
                </>
            :
                <p>No Image Found</p>
            } 
        </>
    }

}

export default ImageCropper;