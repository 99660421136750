import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import MaterialTable from "material-table";
import { Grid, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "react-bootstrap";

import { getValue } from "../../utils/object";
import TagStatModal from "./StatModal/tag.stats";
import tagsService from "../../services/tagsService";
import ArticlesBlock from "./ArticlesBlock/articles.block";


const moment = require("moment");

function TagsReport ( props ) {
    
    const [ tags, setTags ] = useState([]);
    const tableRef = useRef();

    const confirmTypes = {
        DISMISS: "dismiss",
        INVALID: "invalid",
    }

    const [ isLoading, setLoading] = useState(false);
    const [ pageDetails, setPageDetails ] = useState({ page: 0, pageSize: 10 });
    const [ detailModal, setDetailModal]  = useState({ show: false, selectedTag: null, title: "" });
    const [ confirmModal, setConfirmModal ] = useState({ show: false, selectedTags: [], type: confirmTypes.DISMISS });

    const fetchTags =  async (query) =>{
        return new Promise(async ( resolve, reject) => {
            const { page, pageSize } = query;

            let tagResponse = await tagsService.fetchReportedTags(page, pageSize);
            const { success, data } = tagResponse; 

            let newTags = [];
            if(success && data){
                newTags = getValue(data, 'tags');
                for(let i = 0 ; i < newTags.length ; i++){
                    newTags[i].lastReport = moment(newTags[i].latestReported).format("YYYY-MM-DD hh:mm").toString();
                }
                setPageDetails({
                    ...pageDetails,
                    pageSize: pageSize
                })
                setTags(newTags);
            }else if (success && data.tags && data.tags.length==0 ) toast.info("No tags were reported");
            resolve({
                page: page,
                data : newTags,
                totalCount : pageSize
            })
        })
    }

    // handle validate or invalidate tag action
    const handleUpdate = async (updateBody) =>{
        setLoading(true);
        let selectedTag = detailModal.selectedTag;

        let response = await tagsService.updateTag(selectedTag.id, updateBody);
        if(response.success){
            refreshTable();
            setDetailModal({show:false,selectedTag:null,title:""});
            toast.success(response.data.message);
        }else{
            toast.error(response.message);
        }
        setLoading(false);
    }

    const handleReject = async ()=>{
        setLoading(true);
        try{
            let selectedTag = detailModal.selectedTag;
            let response = await tagsService.rejectReport(selectedTag.id);
            if(response.success){
                refreshTable();
                setDetailModal({ show: false, selectedTag: null, title: "" });
                toast.success(response.data.message);
            }else{
                toast.error(response.message);
            }    
        }catch(err){ 
            console.log("ERROR : HANDLE REJECT");
            console.log(err);
        }
        setLoading(false);
    }

    const refreshTable =()=>{
        tableRef.current && tableRef.current.onQueryChange();
    }
    
    const toggleModal = ( show, tag = null ) => {
        if (!show || !tag) {
            setDetailModal({ ...detailModal, ...{ show: false, selectedTag: null, title: "" }});
            setLoading(false);
        }else if (show && tag) {
            setDetailModal({ ...detailModal, ...{ show: true, selectedTag:tag, title: "Reported Tag : " + tag.name}});
        }
    }

    // shows confirmation modal
    const showConfirmModal = (tags, selectedConfirmType) => {
        setConfirmModal({
            show: true,
            selectedTags: tags,
            type: selectedConfirmType,
        })
    }

    const hideConfirmModal = () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
            selectedTags: []
        })
    }

    // confirmed dismiss reports
    const confirmDismissReports = async () => {
        try {
            
            if (!confirmModal.selectedTags) throw Error(`No selectedTags found`);

            const tags = confirmModal.selectedTags.map((tag) => tag.id);

            const response = await tagsService.bulkRejectReport(tags);

            toast.success(response.data.message);
            refreshTable();
            hideConfirmModal();
        } catch (err) {
            toast.error(`Rejecting reports failed. Try again`);           
        }
    }

    // confirmed invalidate tags
    const confirmInvalidTags = async () => {
        try {

            if (!confirmModal.selectedTags) throw Error(`No selectedTags found`);

            const tags = confirmModal.selectedTags.map((tag) => tag.id);
            const response = await tagsService.bulkValidate({ tags, isValid:false });
            
            toast.success(response.data.message);
            refreshTable();
            hideConfirmModal();
        } catch (err) {
            toast.error(`Failed to mark tags as invalid`);
        }
    }

    return <Grid fluid>
        <MaterialTable
            data = {tags}
            tableRef = {tableRef}
            title = "Tag Management"
            columns = {[
                { title: "Tag", render: (rowData) => <span className="clickable" onClick={() => toggleModal(true,rowData)}> {rowData.name}</span> },
                { title: "Report Count", field: "reportCount" },
                { title: "Last Reported", field: "lastReport" },
            ]}
            actions = {[
                { 
                    icon:() => <Button className="btn-sm dangerButton">Mark as Invalid </Button>,
                    onClick: ( evt, tags ) => showConfirmModal( tags, confirmTypes.INVALID ), 
                },
                { 
                    icon:() => <Button className="btn-sm primaryButton">Dismiss Reports</Button>,
                    onClick: ( evt, tags ) => showConfirmModal( tags, confirmTypes.DISMISS ), 
                },
            ]}
            options = {{
                selection: true,
                pageSize: pageDetails.pageSize,
                pageSizeOptions: [10,20,50,100],
                search: false, 
                headerStyle: {
                    color: '#FFF',
                    fontSize:"15px",
                    backgroundColor: '#01579b',
                },
                rowStyle: (rowData) => {
                    const rowStyle = {
                        fontWeight:"bold",
                    }
                    if (rowData.isValid != undefined){
                        rowStyle.color = rowData.isValid ? "green" : "red";
                    }
                    return rowStyle;
                }
            }}
            data = {fetchTags}
        />;

        {/* Report Detail Modal */}
        <Modal show={detailModal.show} bsSize="large" onHide={() =>toggleModal(false)}>
            <ModalHeader closeButton>Report Actions</ModalHeader>
            <ModalBody>
                <div class="tagOverflowScroll">
                    <TagStatModal tag={detailModal.selectedTag} /> 
                    {detailModal.selectedTag && <ArticlesBlock tagName={detailModal.selectedTag.name} /> }
                </div>
            </ModalBody>
                <ModalFooter>
                    <Button 
                        disabled= {isLoading}
                        className="secondaryButton btn btn-default" 
                        onClick={()=>toggleModal(false) }>
                            Cancel
                    </Button>
                    <Button 
                        disabled= {isLoading}
                        className="primaryButton btn btn-default" 
                        onClick={ async ()=> {
                            await handleReject();
                        } }>
                            Reject
                    </Button>
                    <Button 
                        disabled= {isLoading}
                        className={"dangerButton btn btn-default"} 
                        onClick={ async ()=> {
                            await handleUpdate({isValid:false,isProcessed:true});
                        }}>
                            Invalid
                    </Button>
                </ModalFooter>
        </Modal>

        {/* Confirmation Modal */}
        <Modal show={ confirmModal.show } >
            <ModalHeader>
                Confirmation Required
            </ModalHeader>
            <ModalBody>
                Are you sure you want to {confirmModal.type === confirmTypes.DISMISS ? `dismiss these ${confirmModal.selectedTags.length} reports` : `mark these ${confirmModal.selectedTags.length} tags as invalid`} ? 
            </ModalBody>
            <ModalFooter>
                <Button onClick={hideConfirmModal}> Cancel </Button>
                { confirmModal.type === confirmTypes.DISMISS ? <>
                    <Button className="primaryButton" onClick={confirmDismissReports}> Dismiss </Button>
                </> : <>
                    <Button className="primaryButton" onClick={confirmInvalidTags}> Mark as Invalid </Button>
                </>}
            </ModalFooter>
        </Modal>

    </Grid>
}


export default TagsReport;