import React,{Component} from 'react';

import {
  BarChart,
  Bar, 
  Cell, 
  XAxis, YAxis, 
  CartesianGrid, 
  Tooltip, Legend,
  ResponsiveContainer,
} from 'recharts';
import {Button} from "react-bootstrap";

import StackedSettings from "./StackedSettings";
import loading from '../../../assets/img/loading.svg';

const DashboardService = require('../../../services/dashboardService');

class StackedBarChart extends Component{
  
    constructor(props){
      super(props);
      this.state={
        data:[],
        legends:[],
        frequency:"Daily",
        source:"recent",
        start:new Date(Date.now()-24*60*60*1000*7),
        end:new Date(),
        settingOpen:false,
        isLoading:true,
      }
      this.fetchData=this.fetchData.bind(this);
      this.onClose=this.onClose.bind(this);
      this.changeSettings=this.changeSettings.bind(this);
      this.toggleSettings=this.toggleSettings.bind(this);
    }   

    async componentDidMount(){
        var result=await DashboardService.providerArticleAnalytics(this.state.source,this.state.frequency,{"startDate":this.state.start,"endDate":this.state.end});
        if(result){
          this.setState({isLoading:false});
        }
        if(result.success){
          var data = result.data.data;
          var legends=result.data.legends;
          this.setState({data,legends});
        }else{
          // console.log(result);
        }
    }


    async fetchData(){
      this.setState({isLoading:true});
      var result=await DashboardService.providerArticleAnalytics(this.state.source,this.state.frequency,
                          {"startDate":this.state.start,"endDate":this.state.end});
      if(result){
        this.setState({isLoading:false});
      }
      if(result.success){
        var data = result.data.data;
        var legends=result.data.legends;
        this.setState({data,legends});
      }else{
        // console.log(result);
      }
    }

    async changeSettings(settings){
      var frequency=settings.frequency;

      //capitalize first letter
      frequency=frequency.charAt(0).toUpperCase() + frequency.slice(1);
      await this.setState({
        frequency,
        start:settings.start,
        end:settings.end,
        source:settings.source
      })
      
      this.fetchData();
    }

    toggleSettings(){
      var isOpen=this.state.settingOpen;
      isOpen=!isOpen;
      this.setState({settingOpen:isOpen});
    }

    onClose(close){
      if(close){
        this.setState({settingOpen:false});
      }
    }

    render() {
        return ( 
          <div>
              
              <div className="clearfix">
                <span className="float-left chart-subTitle">{this.state.frequency} Performance ( {this.state.source} )</span>
                <span className="float-right">
                  <Button bsStyle="primary" onClick={this.toggleSettings}>Setting</Button>
                </span>
              </div>

              {this.state.isLoading ? <div><img src={loading} style={{height:"230px"}}></img></div> : 
              <div className="ct-chart" style={{position:"relative"}}>
                {this.state.settingOpen ? <StackedSettings start={this.state.start} end={this.state.end} onClose={this.onClose} changeSettings={this.changeSettings}/>
                : null }
              <ResponsiveContainer >
                <BarChart
                  data={this.state.data}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />

                {this.state.legends.map((item,index)=>{
                  return <Bar key={index} dataKey={item.name} stackId="a" fill={item.color} />
                })}
                  
                </BarChart>
              </ResponsiveContainer>
              </div>
              }
          </div>
        );
      }
  
}

export default StackedBarChart;