import UserMgmt from "../views/UserMgmt.jsx";
import TrendNews from "../views/TrendNews.jsx";
import Dashboard from "../views/Dashboard.jsx";
import UserProfile from "../views/UserProfile.jsx";
import ProviderMgmt from "../views/Providers/ProviderMgmt.jsx";
import TagsManagement from "../views/TagManagement/tag.management.jsx";
import TagManagement from "../views/TagManagement/TagManagement.jsx";
import ImageArticlePage from "../views/customArticle/ImageArticlePage.jsx";
import CustomArticlePage from "../views/customArticle/CustomArticlePage.jsx";
import ArticleStatistics from "../views/articleStatistics/ArticleStatistics.jsx";
import TagsReport from "../views/TagManagement/tag.report.jsx";

const userRoles = require('../const/userRoles')

const appRoutes = [
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/user/manage",
    name: "User Management",
    icon: "pe-7s-users",
    component: UserMgmt,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/providers/manage",
    name: "Providers",
    icon: "pe-7s-notebook",
    component: ProviderMgmt,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/tag/manage",
    name: "Manage Tags",
    icon: "pe-7s-notebook",
    component: TagsManagement,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/tag/report",
    name: "Tags Report",
    icon: "pe-7s-news-paper",
    component: TagsReport,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/tags",
    name: "Tag Management",
    icon: "pe-7s-news-paper",
    component: TagManagement,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_WRITER,
    path: "/article/custom",
    name: "New Custom Article",
    icon: "pe-7s-user",
    component: CustomArticlePage,
    layout: "/admin",
    showInSideBar:false,
  },
  {
    minAuthLevel: userRoles.ROLE_WRITER,
    path: "/article/custom/:id",
    name: "Edit Article",
    icon: "pe-7s-user",
    component: CustomArticlePage,
    layout: "/admin",
    showInSideBar:false,
  },
  {
    minAuthLevel: userRoles.ROLE_EDITOR,
    path: "/article/image",
    name: "New Image Article",
    icon: "pe-7s-user",
    component: ImageArticlePage,
    layout: "/admin",
    showInSideBar:false,
  },  
  {
    minAuthLevel: userRoles.ROLE_EDITOR,
    path: "/article/image/:id",
    name: "New Image Article",
    icon: "pe-7s-user",
    component: ImageArticlePage,
    layout: "/admin",
    showInSideBar:false,
  },
  {
    minAuthLevel: userRoles.ROLE_WRITER,
    path: "/profile",
    name: "My Posts", 
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_ADMIN,
    path: "/stats",
    name: "Article Stats",
    icon: "pe-7s-graph3",
    component: ArticleStatistics,
    layout: "/admin",
    showInSideBar:true,
  },
  {
    minAuthLevel: userRoles.ROLE_EDITOR,
    path: "/trending",
    name: "Top News",
    icon: "pe-7s-filter",
    component: TrendNews,
    layout: "/admin",
    showInSideBar:true,
  }
];

export default appRoutes;
