const axios = require('axios')
const qs = require('query-string');
const remote = require('../config/remote.js')

// const utils = require('./utils')

const axiosInstance = axios.create();

// URLS to add header multipart/form-data 
const multipartURLs= [
	// API_BASE_URL + "/customArticle/imageUpload",
];

axiosInstance.interceptors.request.use(function (config) {
	let token = localStorage.getItem('accessToken');
	if (token) {
		let isMultipart = multipartURLs.includes(config.url);
		if(isMultipart){
			config.headers["content-type"] = "multipart/form-data";
		}
		config.headers["Authorization"] = "Bearer " + token;
	}
	return config;
}, function (error) {    
	return Promise.reject(error);
}); 	

const API_BASE_URL = remote.url;
 
module.exports = {
	loadData : function(linkData){
		return new Promise( function(resolve, reject) {
			axiosInstance.post(API_BASE_URL+"/customArticle/extract",linkData)
				  .then(function (response) {
				    resolve(response);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
    },
    postArticle : function(articleData){
    	return new Promise( function(resolve, reject) {
			axiosInstance.post(API_BASE_URL+"/customArticle",articleData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
	postImageArticle: function(articleData){
		return new Promise( function(resolve,reject){
			axiosInstance.post(API_BASE_URL + "/customArticle/imageArticle",articleData)
				.then(function(response){
					resolve(response.data)
				}).catch(function(error){
					if(error.response.statusText) { 
						error.response.data.message = error.response.statusText;
					}
					console.log(error.response);
					resolve(error.response.data);
				})
		})
	},
	scheduleArticle : function(scheduleData){
    	return new Promise( function(resolve, reject) {
			axiosInstance.post(API_BASE_URL+"/customArticle/schedule",scheduleData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
	deleteArticle : function (id){
		return new Promise(function(resolve, reject) {
			axiosInstance.delete(remote.url+'/customArticle/'+id)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

	getArticle : function (id){
		return new Promise(function(resolve, reject) {
			axiosInstance.get(remote.url+'/customArticle/get/'+id)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

	editArticle : function(id,articleData){
    	return new Promise( function(resolve, reject) {
			axiosInstance.put(API_BASE_URL+"/customArticle/"+id,articleData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

	editImageArticle : function(id,articleData){
		return new Promise( function(resolve, reject) {
			axiosInstance.put(API_BASE_URL+"/customArticle/imageArticle/"+id,articleData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

	getArticles : function (pagingParams){
		return new Promise(function(resolve, reject) {
			var pagingString= ""
			if(pagingParams){
				pagingString = '?'+qs.stringify(pagingParams)
			}
			axiosInstance.get(remote.url+'/customArticle/'+pagingString)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
	getAllArticles : function (pagingParams){
		return new Promise(function(resolve, reject) {
			var pagingString= ""
			if(pagingParams){
				pagingString = '?'+qs.stringify(pagingParams)
			}
			axiosInstance.get(remote.url+'/customArticle/all'+pagingString)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				resolve(error.response.data);
			});
		});
	},
	topCustomArticles: function (pagingParams){
		return new Promise(function(resolve, reject) {
			var pagingString= ""
			if(pagingParams){
				pagingString = '?'+qs.stringify(pagingParams)
			}
			axiosInstance.get(remote.url+'/customArticle/top'+pagingString)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				resolve(error.response.data);
			});
		});
	},
	getTrending : function (pagingParams,startDate,endDate,Percentile){
		return new Promise(function(resolve, reject) {
			var pagingString= ""
			if(pagingParams){
				pagingString = '?'+qs.stringify(pagingParams)
			}
			axiosInstance.post(remote.url+'/articles/trend'+pagingString,{
				startDate:startDate,
				endDate:endDate,
				Percentile:Percentile,
				})
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
	sendNotification : function (id,type){
		return new Promise(function(resolve, reject) {
			axiosInstance.post(remote.url+'/utils/notification',{id,type})
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				resolve(error.response.data);
			});
		});
	},

}