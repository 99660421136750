import React from "react";
import {subDays} from "date-fns";
import DatePicker from "react-datepicker";
import MaterialTable from 'material-table';
import {Row,Col,Grid,Form,Button,FormControl} from "react-bootstrap";

const articleService = require('../services/articleService')

const customRemote = require('../config/remote.js');
const customUrl = customRemote.customURL; 


class TrendNews extends React.Component{

    constructor(props){
        super(props);
        this.state={
            Articles:[],
            startDate:new Date(),
            endDate:new Date(),
            Percentile:5,
           
        }
        this.tableRef = React.createRef();
    }
    
    componentDidMount(e){
      var yesterday=this.state.startDate.setDate(this.state.startDate.getDate() - 1) ;
      this.setState({
        startDate:yesterday
      })
    }
    fetchArticles= async (query)=>{
        return new Promise(async (resolve, reject) => {
          // this.setState({Articles:[]});
         
          var Start=new Date(this.state.startDate).getTime();
          var End= new Date(this.state.endDate).getTime();
      
          var Articles = await articleService.getTrending({limit : query.pageSize, page : query.page},
          Start,End, this.state.Percentile);
        
    
          Articles.data.forEach((article)=>{
            var date = new Date(article.article_time);
            // article.title="<a href='"+article.url+"'>"+article.title+"</a>"
            console.log(date);
            article["publish_date"]=date.toDateString()+", " +date.getHours()+":"+date.getMinutes();
          })
         
          this.setState({Articles:Articles.data});
          
          resolve({
            data: this.state.Articles,
            page: 0,
            totalCount: Articles.length,
          });
        })
      }


      setStartDate=(date)=>{
        this.setState({
          startDate:date
        })
      }

      setEndDate=(date)=>{
        this.setState({
          endDate:date
        })
      }

      getTrending=()=>{
        
       var query={page:0,pageSize:10}
       
       this.tableRef.current && this.tableRef.current.onQueryChange();
       this.fetchArticles(query);

      }

      handlePercent=(e)=>{
        var Percent=e.target.value;
        this.setState({
          Percentile:parseInt(Percent)
        })
      }

    render() {
        return  <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                    <Form>
                      <Row><Col md={4}>
                        Start Date:<br></br>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={date => this.setStartDate(date)}
                            maxDate={this.state.endDate}
                            placeholderText="Select a date after 0 days ago"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                              
                        /></Col> 
                        <Col md={4}>
                        End Date:<br></br>
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={date => this.setEndDate(date)}
                            maxDate={subDays(new Date(), 0)}
                            placeholderText="Select a date after 0 days ago"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                               
                        /></Col>
                        <Col md={4}>
                        Percentile:
                        <FormControl  onChange={this.handlePercent} 
                          value={this.state.Percentile}></FormControl>
                       </Col>
                       </Row>
                        <Button style={{marginBottom:15,marginTop:15}} onClick={this.getTrending}>Submit</Button>

                    
                    </Form>

                    {/* {this.state.Articles.map((article)=>{
                      return <div>{article.title} <br></br></div>
                    })
                    } */}

                   <MaterialTable
                    tableRef={this.tableRef}
                    columns={ [
                        { title: 'Title', field: 'title',
                    render:rowData => <a href={customUrl + "/article?id=" + rowData._id} 
                                      target='_blank'>{rowData.title}</a> },
                                      {title:'Publish Date', field:'publish_date'}
                                    
                                    ]}
                    
                    data={this.state.Articles}
                    title="Recent Posts"
                    options={{pageSize:this.state.Articles.length,paging:false, search: false, pageSizeOptions:[5,10],
                      headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        fontSize:"15px"
                      },
                      rowStyle: {
                        backgroundColor: '#EEE',
                        fontSize:"20px",
                        fontWeight:"600",

                      }
                    }} 
                    
                    data={this.fetchArticles}
                  />
                    </Col>
                </Row>
            </Grid>
        </div>
    }
}
export default TrendNews;