import React from 'react';
import { withStyles, Grid, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import AppContext from '../../contexts/AppContext';

const classNames = require('classnames');
const UserServices = require('../../services/userServices')
const Utils = require('../../services/utils')
const yup = require('yup');
const userRoles = require('../../const/userRoles')

const styles = theme => ({
    
    itemMargin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionButton: {
      margin: theme.spacing.unit,
    },
    centerText : {
      textAlign : 'center'
    },
    modalContainer : {
      maxWidth : "500px"
    },
    errorText: {
      color: "red"
    },
    loginContainer : {
      width: "100%"
    },
    loginFrame : {
      //marginTop : "20vh",
      width: "600px",
      margin: "0 auto",
    },
    

});

const loginSchema = yup.object().shape({
  email : yup.string().typeError('Must be a string').email('Doesn\'t look like an email address').required('Is required'),
  password : yup.string().typeError('Must be a string').required('Is required')
});

class LoginSignup extends React.Component {
      static contextType = AppContext;
      constructor() {
        super();
        this.state = { 
          formMode : 'login',
          formModeText : 'Sign in to your account',
          formErrors : {},
          email : '',
          password : '',
          isAuthenticated: false, user: null, token: ''
        };
        
      }

      componentDidMount(){
        const { match: { params } } = this.props;
        var invitationToken = params.token
        if(invitationToken){
          this.setState({
            invitationToken : invitationToken
          })
          this.openSignup();
        }
      }

      facebookResponse = async (fbresponse) => {
        const tokenData = {access_token: fbresponse.accessToken}
        var response = await UserServices.facebookLogin(tokenData);
        if(response.success){
          this.props.onLogin(response)
        }else{
          //show some error
        }
      };


      googleResponse = async (googleresponse) => {
        const tokenData = {access_token: googleresponse.accessToken};
        var response = await UserServices.goolgleLogin(tokenData);
        if(response.success){
          this.props.onLogin(response)
        }else{
          console.log(response.error);
          toast.error(response.error || response.message);
        }
      };
      
      onFailure = (error) => {
        alert(error);
      }

      handleChange = name => event => {
        this.setState({ [name]: event.target.value });
      };

      openLogin(){
        this.setState({formModeText : "Sign in to your account", formMode: 'login', formErrors : {}, submissionError:null,  email : this.state.email, password : '' })
      }


      onLogin = (userData) => {
        this.context.onLogin(userData);
      }

      async submitLogin(){
        var userData = {
          email : this.state.email,
          password : this.state.password
        }
        
        localStorage.removeItem("xotkariDraft");

        loginSchema.validate(userData, { abortEarly: false })
          .then(async (data) => {
            this.setState({formErrors : {}})
            var response = await UserServices.login(userData);
            if(response.success){
              localStorage.setItem("accessToken",response.token);
              this.onLogin(response.data);
            }else{
              let error = response.error || response.message || response.data.message;
              toast.error(error);
              this.setState({submissionError : error})
            }
          })
          .catch((err) => {
            console.log(err)
            var formErrors = {}
            for(var i=0; i< err.inner.length; i++){
                var errorInstance = err.inner[i]
                formErrors[errorInstance.path] = errorInstance.message;
            }
            this.setState({formErrors});
          })
      }
      
  render() {
    return (
                <div className="mt-20 mb-40 Container">
                    <div className={classNames("w-full max-w-md bg-white shadow-md rounded pb-10 pt-10", "formFrame")}>
                      <div class="modal-title flex justify-between items-center px-8 pt-2">
                        <p class="text-4xl font-bold w-full text-center font-sans">{ this.state.formModeText }</p>
                      </div>
                        <div class="px-8 py-2">
                            <label class="block text-center uppercase tracking-wide text-gray-700 text-base font-bold mb-2" for="grid-first-name">
                              Connect using social network
                            </label>

                            <div class="flex w-full">
                              <FacebookLogin
                                    appId="307638930146903"
                                    fields="name,email,picture"
                                    callback={this.facebookResponse} 
                                    render={renderProps => (
                                      <div class="w-1/2 m-2" onClick={renderProps.onClick}>
                                          <a class="block bg-fb-blue text-white rounded border hover:border-0 hover:shadow-lg py-4 px-6 flex items-center no-underline" href="#">
                                              <FontAwesomeIcon icon={faFacebookSquare} />
                                              <span class="text-grey-darkest font-semibold flex-1 text-center text-sm uppercase">Facebook</span>
                                          </a>
                                      </div> 
                                    )}
                                />

                              

                              <GoogleLogin
                                  clientId="430177101345-275mmobcs6edvhr4uhab822ff7d5ubnt.apps.googleusercontent.com"
                                  onSuccess={this.googleResponse}
                                  onFailure={this.googleResponse}
                                  render={renderProps => (
                                    <div class="w-1/2 m-2" onClick={renderProps.onClick}>
                                        <a class="block bg-google-red text-white rounded border hover:border-0 hover:shadow-lg py-4 px-6 flex items-center no-underline" href="#">
                                            <FontAwesomeIcon icon={faGoogle} />
                                            <span class="text-grey-darkest font-semibold flex-1 text-center text-sm uppercase">Google</span>
                                        </a>
                                    </div> 
                                  )}
                                />
                                
                            </div>
                        </div>
                        
                        <label class="text-center block uppercase tracking-wide text-gray-700 text-base font-bold my-2" for="grid-first-name">
                              or
                        </label>

                                    {/* login form */}

                      <form className="px-8 mb-2">
                        <div class="flex flex-wrap -mx-3 ">
                          <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2" for="grid-password">
                              Email
                            </label>
                            <input value={this.state.email} onChange={this.handleChange('email')}  class="appearance-none text-xl block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-4 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" placeholder="email@example.com"/>
                            { this.state.formErrors['email'] &&  <p class="text-red-500 text-xs italic">{this.state.formErrors['email']}</p>  }
                          </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mt-4 ">
                          <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2" for="grid-password">
                              Password
                            </label>
                            <input value={this.state.password} onChange={this.handleChange('password')}  class="appearance-none text-xl block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="******************"/>
                            { this.state.formErrors['password'] &&  <p class="text-red-500 text-xs italic">{this.state.formErrors['password']}</p>  }
                          </div>
                        </div>
                        { this.state.submissionError &&  <p class="text-red-500 px-3 text-xs italic">{this.state.submissionError}</p>  }
                        <div className="flex items-center justify-between mt-4 ">
                          <button  onClick={this.submitLogin.bind(this)} class="bg-blue-500 text-xl hover:bg-blue-700 text-white font-bold py-4 px-6 rounded focus:outline-none focus:shadow-outline" type="button">
                            Sign In
                          </button>
                          <a className="inline-block align-baseline font-bold text-xl text-blue-500 hover:text-blue-800" href="#">
                            Forgot Password?
                          </a>
                        </div>
                        
                      </form>

          </div> 
          </div> 
        );
    }
}

export default withStyles(styles)(withRouter(LoginSignup));