import _ from 'lodash';

const getValue = (object, path, defaultValue = undefined) => {
  return _.get(object, path, defaultValue);
}

const hasProperty = (object, path) => {
  return _.has(object, path);
}

export {
  getValue,
  hasProperty
}