import React, { useEffect, useState } from "react"
import { Grid, Row } from "react-bootstrap";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import getTimeAgo from "../../../utils/timeAgo";
import moment from "moment";
function TagStatModal({ tag, openTagData }){

    const [statTag,setStatTag]  = useState({  
        name:"",
        recentCount:0,
        status:"",
        statusClass:"",
        firstSeen:"",
        lastSeen:"",
        data:[],
        dataToVisualize: []
    });
    const [lineColor,setLineColor] = useState("#8ab650");

    useEffect(()=>{
        if(tag) formatTagData(tag);
    },[tag])

    // const compareTimestamp = (a, b) => {
    //     if (a.timestamp > b.timestamp) return 1;
    //     if (b.timestamp > a.timestamp) return -1;
    //     return 0;
    // }

    function getVisualizationData(tag){
        let timeSpan = Math.ceil((tag.lastSeenTimestamp-tag.firstSeenTimestamp)/86400);
            var dataPoints,dataPointsDistance;
            var dateFmt;
            if(timeSpan < 30){
                dataPoints = timeSpan;
                dataPointsDistance = 86400;
                 dateFmt="DD";
            }
            else if(timeSpan < 365){
                dataPoints = Math.ceil(timeSpan/7);
                dataPointsDistance = 86400*7;
                 dateFmt="DD/MMM";                    
            }else{
                dataPoints = Math.ceil(timeSpan/31);
                dataPointsDistance = 86400*30;
                 dateFmt="MMM/YYYY";
            }

        var dataPointsDataArr = [];
            var array = [];
            for(var i=0; i<dataPoints; i++) array.push([]);
            // for(var i=0; i<dataPoints; i++){
            //     dataPointsDataArr[dataPoints] = []
            // }
            //console.log(dataPointsDataArr)
            for(var i=0; i<tag.data.length; i++){
                var dataPointIndex = Math.floor((tag.data[i].timestamp - tag.firstSeenTimestamp)/dataPointsDistance)
                //console.log(dataPointIndex)
                //console.log(dataPointsDataArr[dataPointIndex])
                if (dataPointIndex > -1){
                    try{
                        dataPointsDataArr[dataPointIndex].push(tag.data[i]) 
                    }catch (err){
                        dataPointsDataArr[dataPointIndex] = [tag.data[i]] 
                    }    
                }
                
            }
        var dataToVisualize = []
        for(var i=0; i<dataPointsDataArr.length; i++){
             if (typeof dataToVisualize[i] == 'undefined'){
                
                dataToVisualize[i] = {
                     "timeStamp" : tag.firstSeenTimestamp + (i * dataPointsDistance),
                     "count" : 0
                 }
               let dateTime = moment.unix(dataToVisualize[i].timeStamp);//new Date(dataToVisualize[i].timeStamp*1000);
                 dataToVisualize[i]['dateStr'] = dateTime.format(dateFmt);
               
                     
             }
            if(typeof dataPointsDataArr[i] !== 'undefined'){
                for (var j=0; j< dataPointsDataArr[i].length; j++ ){
                    dataToVisualize[i]['count'] += dataPointsDataArr[i][j]['count']
                }
            } 
            
        }
        return dataToVisualize
    }

    const formatTagData = async (tag) =>{

        if(tag.isProcessed){
            if(tag.isValid){
                tag.status = "Valid";
                tag.statusClass = "validTagBlock";
            }else{
                tag.status = "Invalid";
                tag.statusClass = "invalidTagBlock";
            }
        }else{
            tag.status = "Waiting";
            tag.statusClass = "waitingTagBlock";
        }

       // console.log("TAG STAT");
        //console.log(tag);
        if(tag.data && tag.data.length > 0){
            var dataToVisualize = getVisualizationData(tag);
            //console.log(dataToVisualize)
            //let formattedData = tag.data.map((tagData)=>{
                //let dateTime = new Date(tagData.timestamp*1000);
                // let name = 
                // dateTime.getFullYear()
                //  + "-" + 
                // dateTime.getMonth()
                //  + "-" + 
                // dateTime.getDate();
                // return {...tagData,...{name}}
           // })
            
            //formattedData = formattedData.sort(compareTimestamp);
            //let arrLen = formattedData.length - 1;
            //if(formattedData[arrLen-1] && formattedData[arrLen].count < formattedData[arrLen-1].count ){
            //    setLineColor("rgb(25, 134, 115)");
          //  }
          //  tag.data = formattedData;
        }
        tag.firstSeen = getTimeAgo(tag.firstSeenTimestamp * 1000);
        tag.lastSeen = getTimeAgo(tag.lastSeenTimestamp * 1000);
        tag.dataToVisualize = dataToVisualize
        setStatTag({...tag});
        //console.log(tag.firstSeenTimestamp)
    }
    
    return <Grid fluid>
        <Row>
            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-around",alignItems:"center"}}>
                <div>
                    <div className="tagStatHeader">Tag Name</div> 
                    {statTag.name}
                </div>
                {statTag.source && <div>
                    <div className="tagStatHeader">Duplicate of</div> 
                    <span className="clickable" onClick={() => openTagData(statTag.source)}>{statTag.source.name}</span>
                </div>}
                <div>
                    <div className="tagStatHeader">Recent Count</div> 
                    {statTag.recentCount}
                </div>
                <div>
                    <div className="tagStatHeader">Status</div> 
                    <span className={"tagStatusBadge " + statTag.statusClass}>{statTag.status}</span>
                </div>
                <div>
                    <div className="tagStatHeader">First Seen</div>
                    {statTag.firstSeen}
                </div>
                <div>
                    <div className="tagStatHeader">Last Seen</div> 
                    {statTag.lastSeen}
                </div>
            </div>
        </Row>
        {/* <Row style={{height:"400px",margin:"30px 0px"}}>
            <ResponsiveContainer width="100%" height="100%">
                 <AreaChart
                    width={500} 
                    height={250}
                    data={statTag.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Area dot={false} type="monotone" dataKey="count" stroke={lineColor}  
                    //activeDot={{ r: 8 }} 
                    
                     />
                </AreaChart>
            </ResponsiveContainer>
        </Row> */}
        <Row style={{height:"400px",margin:"30px 0px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width={500}
                    height={250}
                    data={statTag.dataToVisualize}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dateStr" />
                    <YAxis dataKey="count" />
                    <Tooltip />
                    <Legend verticalAlign="top" />
                    <Area dot={false} type="monotone" dataKey="count" stroke={lineColor}  
                    //activeDot={{ r: 8 }} 
                    
                     />
                </AreaChart>
            </ResponsiveContainer>
        </Row>
    </Grid>  
}

export default TagStatModal;