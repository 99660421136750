import React,{Component} from 'react';
import placeholder from "../../assets/img/imagePlaceholder.png";

class FileInput extends Component{

    dropRef = React.createRef();

    constructor(props){
        super(props);
        this.state={
            editMode:false,
            showPropImage:false,
            selectedFiles:[],
            dragging:false,
        }
    }

    componentDidMount(){
        if(this.props.files){
            let files= this.props.files;
            this.initialize(files);    
        }

        this.dragCounter = 0
        let dropArea = this.dropRef.current;
        dropArea.addEventListener('dragenter', this.handleDragIn)
        dropArea.addEventListener('dragleave', this.handleDragOut)
        dropArea.addEventListener('dragover', this.handleDrag)
        dropArea.addEventListener('drop', this.handleDrop)
    }

    initialize(files){
        this.setState({
            selectedFiles:files,
            editMode:true,
            showPropImage:true,
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.files && (!prevProps.files || this.props.files!=prevProps.files)){
            this.setState({ 
                selectedFiles:this.props.files
            })
        }
    }

    componentWillUnmount(){
        let dropArea = this.dropRef.current
        dropArea.removeEventListener('dragenter', this.handleDragIn)
        dropArea.removeEventListener('dragleave', this.handleDragOut)
        dropArea.removeEventListener('dragover', this.handleDrag)
        dropArea.removeEventListener('drop', this.handleDrop)
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++  
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }
    
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }
    
    handleDrop = (e) => {    
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            dragging:false
        })
        this.handleChange(e.dataTransfer.files);
    }

    handleSelectImage = (e) => {
        let fileInput = document.createElement("input");
        fileInput.type="file";
        fileInput.accept=this.props.allowTypes;
        fileInput.multiple=this.props.allowMultiple;
        fileInput.onchange= (event) =>{
            this.handleChange(event.target.files);
        }
        fileInput.click();
    }
    

    getImageDiamensions = (base64) =>{
        return new Promise (function (resolved, rejected) {
            var i = new Image()
            i.onload = function(){
              resolved({w: i.naturalWidth, h: i.naturalHeight})
            };
            i.src = base64;
        })
    }

    handleChange = async (targetFiles) => {
        
        let filesData= [];  

        for( let i = 0 ; i < targetFiles.length ; i++ ){

            let file= targetFiles[i];

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onloadend= async ()=>{
                
                let imgDiamension = await this.getImageDiamensions(reader.result);

                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                    aspectRatio:imgDiamension.w / imgDiamension.h,
                };
                
                filesData.push(fileInfo);
                
                let filesToShow = [];

                if(filesData.length == targetFiles.length){
                    if(this.props.allowMultiple){
                        filesToShow = filesData;
                        this.props.onSelected(filesData);
                    }else{
                        filesToShow.push(filesData[0]) ;
                        this.props.onSelected(filesData[0]);
                    }
                }
                this.setState({
                    showPropImage:false,
                    selectedFiles:filesToShow
                })

            };
        }

    }

    render(){
        return <div ref={this.dropRef}>
            {this.state.dragging &&
                <div 
                    style={{
                    border: 'dashed grey 4px',
                    backgroundColor: 'rgba(255,255,255,.8)',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0, 
                    right: 0,
                    zIndex: 9999
                    }}
                >
                    <div 
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: 0,
                        left: 0,
                        textAlign: 'center',
                        color: 'grey',
                        fontSize: 36
                    }}
                    >
                    <div>Drop Image Here</div>
                    </div>
                </div>
                }
            {this.state.selectedFiles.length!=0 ?  
                this.state.selectedFiles.map((file,index)=>{
                   return <img key={index} 
                        onClick = {this.handleSelectImage} 
                        src={this.state.showPropImage ? file : file.base64} 
                        className = "img-fluid imagePlaceholder" />
                }) : <img 
                        src={placeholder}
                        className="img-fluid imagePlaceholder" 
                        onClick = {this.handleSelectImage}
                    /> 
            }
        </div>
    }

}

FileInput.defaultProps={
    allowMultiple:false,
    allowTypes:["image/png","image/jpg","image/jpeg"]
}

export default FileInput;
