const axios = require('axios');
const remote = require('../config/remote.js');
const qs = require('query-string');

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
	var token = localStorage.getItem('accessToken');
	if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	}, function (error) {    
			return Promise.reject(error);
}); 	


const API_BASE_URL = remote.url;
 
module.exports = {

	// fetch provider languages
	fetchLanguages : function(){
		return new Promise(function(resolve,reject){
			axiosInstance.get(API_BASE_URL + "/providers/language")
			.then(function(response){
				resolve(response.data);
			}).catch(function(err){
				resolve(err.response.data);
			})
		})
	},

    // fetch providers
	fetchProviders : function(page,pageSize,search= null){

		let query = {page,pageSize};
		if(search){
			query.search = search;
		}
		query = qs.stringify(query);
		
		let targetURL = API_BASE_URL + "/providers?" + query;
		return new Promise( function(resolve, reject) {
			axiosInstance.get(targetURL)
			.then(function (response) {
				console.log("data recieved");
				resolve(response.data);
			})
			.catch(function (error) {
				resolve(error.response.data);
			});
		});
	},

    // create a new provider
    createProvider : function(providerData){
		return new Promise( function(resolve, reject) {
			axiosInstance.post(API_BASE_URL + "/providers/create",providerData)
				  .then(function (response) {
					console.log(response);
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},

    // update provider
    updateProvider : function(id,providerData){
		return new Promise( function(resolve, reject) {
			axiosInstance.put(API_BASE_URL + "/providers/update/" + id, providerData)
				  .then(function (response) {
				    resolve(response.data);
				  })
				  .catch(function (error) {
				    resolve(error.response.data);
				  });
			});
	},
} 