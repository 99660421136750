import React from 'react';

import {
    Grid,
    Row, Col,
    Form, FormControl
} from "react-bootstrap";

import {Card}  from "../../components/Card/Card";
import ImageArticleForm from "./components/ImageArticleForm.jsx";

//change default route for admin to dashboard

class ImageArticlePage extends React.Component{
    render(){
        return<div className="content">
            <Grid fluid>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <Card content={<ImageArticleForm {...this.props}/>} /> 
                </Col>
            </Row>
            </Grid>
        </div>
    }
}


export default ImageArticlePage;