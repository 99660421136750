const userRoles = require('../const/userRoles')

module.exports = {
	hasClientAccess : function(user){
		if(user && [ userRoles.ROLE_ADMIN, userRoles.ROLE_EDITOR, userRoles.ROLE_WRITER ].indexOf(user.userRole) > -1){
			return true;
		}
		return false;
	},
	hasEditorAccess : function(user){
		if(user && [ userRoles.ROLE_EDITOR, userRoles.ROLE_ADMIN ].indexOf(user.userRole) > -1){
			return true;
		}
		return false;
	},
	
	hasAdminAccess : function(user){
		if(user && [ userRoles.ROLE_ADMIN ].indexOf(user.userRole) > -1){
			return true;
		}
		return false;
	}
}