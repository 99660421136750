import React, { Component } from "react";
import MaterialTable from 'material-table';

import {
  Col,
  Row,
  Grid,
  Modal,
  Button,
  MenuItem,
  ButtonToolbar,
  DropdownButton
} from "react-bootstrap";

import { toast } from 'react-toastify';
import userRoles from "../const/userRoles.js";
import AppContext from "../contexts/AppContext.js";

const qs = require("query-string");
const UserServices = require('../services/userServices');
const articleService = require('../services/articleService');

const customRemote = require('../config/remote.js');
const customUrl = customRemote.customURL;

class UserProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userRole: 1,
      tableColumns:[],
      Articles: [],
      notificationArticle:null,
      showConfirm:false,
      notificationDisabled:false,
      pageSize:5
    };

    // this.tableColumns=[];
    this.actions = [];
    this.tableRef = React.createRef();
  }

  static contextType = AppContext;

  async componentDidMount() {

    let actions = [];
    let tableColumns = [];
    let query = qs.parse(this.props.location.search);

    let userId;
    if (!query.id) {
      userId = this.context.currentUser._id;
    } else {
      userId = query.id;
    }

    let currentUser = await UserServices.getCurrentUser()

    if (currentUser.success) {
      
      let userData = currentUser.data
      if (!userId) {
        userId = userData.id
      }
      this.setState({ currentUser: currentUser.data,userId: userId })

      if(userData.userRole>1){
        tableColumns=[
          {
            title: 'Title', field: 'title',
            render: rowData => <a 
              style = {{color:rowData.state=="expired" ? "grey" : "#1DC7EA"}} 
              href = {customUrl + "/article?id=" + rowData._id + "&type=custom"}
              target = '_blank'>
                {rowData.title}
              </a>
          },
          { title: 'Publish Date', field: 'publish_date' },
          { title: 'Views', field: 'views' },
          { title: 'Status',field:'state'}
        ]
      }else{
        tableColumns=[
          {title: 'Title', field: 'title'},
          { title: 'Views', field: 'views' },
        ]
      }

      // new Article + new Image option
      let newOptions = [
        {
          "name":"Article",
          "action":this.addArticle,
        },
      ];

      // add New Image Article option for editor and admin
      if(userData.userRole >= userRoles.ROLE_EDITOR){
        newOptions.push({
          "name":"Image",
          "action":this.addImageArticle
        })
      }

      actions = [
        {
          icon: () =><ButtonToolbar>
              <DropdownButton
                pullRight 
                className="primaryButton"
                title="New"
                id="dropdown-size-small"
              >
                {newOptions.map((option,index)=>{
                  return <MenuItem key={index} eventKey={index} onClick={option.action}>{option.name}</MenuItem>
                })}
              </DropdownButton>
            </ButtonToolbar>,
            isFreeAction:true,          
        },
        {
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            this.editArticle(rowData)
          },
        },
        {
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) => {
            this.deleteArticle(rowData)
          },
        }
      ]

      // add send notification action for admin / editor
      if (userData.userRole >= userRoles.ROLE_EDITOR) {
        actions.push({
          icon: 'add_alert',
          tooltip: 'Send Push Notification',
          onClick: (event, rowData) => {
            this.showConfirmModal(rowData);
          },
        })
      }

      this.setState({
        tableColumns,
        actions
      })

    }
  }

  showConfirmModal = (articleData)=>{
    this.setState({
      notificationArticle:articleData,
      showConfirm:true,
    })
  }

  hideConfirmModal= ()=>{
    this.setState({
      notificationArticle:null,
      showConfirm:false,
    })
  }

  sendNotification = async () => {
    this.setState({ notificationDisabled:true });
    let ArticleData = this.state.notificationArticle;
    let id = ArticleData._id;
    let type = "article";
    if (ArticleData.writer_id) {
      type = "custom";
    }
    
    let result = await articleService.sendNotification(id, type);
    if(result.success){
      toast.success(result.message);
    }else{
      toast.error(result.message);
    }
    this.setState({notificationDisabled:false,notificationArticle:null,showConfirm:false});
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addArticle = () => {
    this.props.history.push('/admin/article/custom');
  }

  addImageArticle = () =>{
    this.props.history.push("/admin/article/image");
  }

  editArticle = (ArticleData) => {
    if(ArticleData.type === "xotkari_custom"){
      this.props.history.push("/admin/article/image/"+ArticleData._id);
    }else{
      this.props.history.push("/admin/article/custom/"+ ArticleData._id);
    }
  }

  deleteArticle = async (ArticleData) => {
    let r = window.confirm("Do you want to delete the article? This cant be undone!");
    if (r == true) {
      let response = await articleService.deleteArticle(ArticleData._id)
      if (response.success) {
        this.tableRef.current && this.tableRef.current.onQueryChange()
        toast.info(ArticleData.title + ' deleted.')
        let Articles = this.state.Articles;
        Articles.splice(ArticleData.tableData.id, 1)
        this.setState({ Articles: Articles })
      }else{
        toast.error(response.message);
      }
    }
  }

  formatDate = (date) => {
    if(date==null || date==undefined){
      return null; 
    }
    return date.toDateString() + ", " + date.getHours() + ":" + date.getMinutes();
  }

  fetchArticles = (query) => {
    return new Promise(async (resolve, reject) => {
      query.pageSize=25;
      console.log("FETCH TABLE DATA");
      var Response = await articleService.getArticles({ limit: query.pageSize, page: query.page })
      if (Response.success == true) {
        //if user is editor / admin  - manipulate expiry date and publish date
        if(this.props.currentUser.userRole>1){
          let date, exp_date;
          Response.data.forEach((article) => {
            if((article.expiry_date!=null && parseInt(new Date(article.expiry_date).getTime()) >= new Date().getTime())){
              if(article.publish_date > new Date().getTime()){
                article["state"] = "scheduled";
              }else{
                article["state"] = "active";
              }
            }else{
              article["state"] = "expired";
            }

            if(article.publish_date){
              date = new Date(article.publish_date);
              article["publish_date"] = this.formatDate(date);
            }
            if(article.expiry_date){
              exp_date = new Date(article.expiry_date);
              article["expiry_date"] = this.formatDate(exp_date);
            }
          })
        }
        this.setState({ Articles: Response.data,pageSize:query.pageSize });
        resolve({
          data: this.state.Articles,
          page: query.page,
          totalCount: Response.totalCount,
        });
      } else {
        toast.error("Failed to Load Data, Try Again");
      }
    })
  }


  render() {
    return (
      
      <div className="content">
      <Grid fluid>
          <Row>
            <Col md={12}>

              <MaterialTable
                tableRef={this.tableRef}
                columns={this.state.tableColumns}
                data={this.state.Articles}
                title="Recent Posts"
                options={{
                  pageSize: this.state.pageSize,
                  search: false,
                  pageSizeOptions: [this.state.pageSize],
                  headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF',
                    fontSize: "15px"
                  },
                  rowStyle: rowData=> ({
                    backgroundColor: '#EEE',
                    fontSize: "20px",
                    fontWeight: "600",
                    color:(rowData.state == "expired" && this.props.currentUser.userRole>1) ? "grey" : "black",
                  })
                }}
                actions={
                  this.state.actions
                }
                data={this.fetchArticles}
              />
            </Col>
          </Row>
        </Grid>
        
        <Modal show={this.state.showConfirm} onHide={this.hideConfirmModal}>
          <Modal.Header closeButton>
            <Modal.Title>Send Push Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                Clicking on Push will send a notification to all the users immediately. Are you sure you want to push this notification ?
          </Modal.Body>

          <Modal.Footer>
          <Button bsStyle="primary" disabled = { this.state.notificationDisabled } onClick={this.sendNotification}>
            Push
          </Button>
          <Button onClick={this.hideConfirmModal} >
            Cancel
          </Button>
          </Modal.Footer>
        </Modal>
        
      </div>
    );
  }
}

export default UserProfile;
