import React from 'react';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import Modal from "react-responsive-modal";
import AddUser from './admin/AddUser';
import EditUser from './admin/EditUser';

import { withStyles } from '@material-ui/core/styles';
import Card from "../components/Card/Card";

import { Grid, Row, Col } from "react-bootstrap";


const userRoles = require('../const/userRoles')
const UserServices = require('../services/userServices')


class UserMgmt extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
        users : [],
        invitePopupOpen : false,
        editPopupOpen : false,
        targetUser:"",
      };
      this.fetchUsers=this.fetchUsers.bind(this);
      this.loadData=this.loadData.bind(this);
      this.changeTargetUser=this.changeTargetUser.bind(this);
      this.tableRef = React.createRef();
    }

    async loadData(page){
      var response = await UserServices.getUsers()
      if(response.success){
        this.setState({users : response.data})  
      }
    }
    
    toggleInvitePopup(){
      this.setState({ invitePopupOpen : !this.state.invitePopupOpen })
    }
    toggleEditPopup(){
      this.setState({editPopupOpen : !this.state.editPopupOpen })
    }

    
    async editUser(userData){
        // this.props.history.push('/admin/profile?id='+userData._id);
        this.setState({
          editPopupOpen:true,
          targetUser:userData._id,
        })
    }

    async changeTargetUser(data){
      var editedData=data.userData;
      if(data.success){
        var users=this.state.users;
        users.forEach((user)=>{
          if(user.id==editedData.id){
            user=editedData
          }
        })
        this.setState({users})
        this.tableRef.current && this.tableRef.current.onQueryChange()
      }
    }

    async refreshTable(userData){
      this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    async deleteUser(userData){
        var r = window.confirm("Do you want to delete the user? This cant be undone!");
        if (r == true) {
            var response = await UserServices.deleteUser(userData._id)
            if(response.success){
              this.tableRef.current && this.tableRef.current.onQueryChange()
              toast(userData.firstName +' deleted.')
              var users= this.state.users
              users.splice(userData.tableData.id,1)
              this.setState({users: users})
            }  
        }  
    }

    fetchUsers(query){
      return new Promise(async (resolve, reject) => {
        var users = await UserServices.getUsers({limit : query.pageSize, page : query.page})
        this.setState({users:users.data});
        resolve({
          data: this.state.users,
          page: query.page,
          totalCount: users.totalCount,
        });
      })
    }

    render() {
        const { classes } = this.props;
        return (
               <div className="content">
                 <Grid fluid>
                  <Row>
                    <Col md={12}>

                    <MaterialTable
                    tableRef={this.tableRef}
                    columns={[
                      { title: 'FirstName', field: 'firstName' },
                      { title: 'LastName', field: 'lastName' },
                      { title: 'Phone', field: 'phoneNumber' },
                      {title: 'Email', field: 'email'},
                      {title: 'Role', field: 'userRole', render: rowData => 
                            rowData.userRole === userRoles.ROLE_ADMIN ? "Admin" : (rowData.userRole === userRoles.ROLE_EDITOR ? "Editor" : "Writer")
                      },
                      {title: 'Status', field: 'locked', render: rowData => 
                      rowData.locked ==true ? "Locked" : "Active"
                      } 
                    ]}
                    data={this.state.users}
                    title="Users Table"
                    options={{pageSize:5, search: false, pageSizeOptions:[5,10],
                      headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        fontSize:"15px"
                      },
                      rowStyle: {
                        backgroundColor: '#EEE',
                        fontSize:"20px",
                        fontWeight:"600",

                      }
                    }}
                    actions={[
                      {
                        icon: 'add',
                        tooltip: 'Add User',
                        isFreeAction: true,
                        onClick: this.toggleInvitePopup.bind(this),
                      },
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                          this.editUser(rowData)
                        },
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete',
                        onClick: (event, rowData) => {
                          this.deleteUser(rowData)
                        },
                      }
                    ]}
                    data={this.fetchUsers}
                  />
                </Col>
              </Row>
                 
                </Grid>

                <Modal open={this.state.invitePopupOpen} classNames={{modal:"w-max-md p-0 pt-6 md:px-5"}} onClose={()=>this.toggleInvitePopup()} center showCloseIcon={true}> 
                    <AddUser onDone={(newUser)=> {this.toggleInvitePopup(); this.refreshTable(newUser)}}/>
                  </Modal>

                <Modal open={this.state.editPopupOpen} classNames={{modal:"w-max-md p-0 pt-6 md:px-5"}} onClose={()=>this.toggleEditPopup()} center showCloseIcon={true}> 
                    <EditUser userId={this.state.targetUser} onDone={(data)=> {this.toggleEditPopup();this.changeTargetUser(data)}}/>
                </Modal>
          
              </div>
            
        );
    }
}
export default UserMgmt;
