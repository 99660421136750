import React,{Component} from "react";


import DatePicker from "react-datepicker";
import {Grid,Row,Col,Form,FormControl,ControlLabel,FormGroup,Button} from "react-bootstrap";

const yup = require("yup");

const settingSchema=yup.object().shape({
    frequency:yup.string().required("Is Required"),
    source:yup.string().required("Is Required"),
    start:yup.date().required("Start date is required"),
    end:yup.date().required("End date is required"),
})

class StackedSettings extends Component{

    constructor(props){
        super(props);
        this.state={
            frequency:"daily",
            source:"recent",
            start:this.props.start,
            end:this.props.end
        };
    }
    
    onSubmit=(e)=>{
        e.preventDefault();
        settingSchema.validate(this.state,{abortEarly:false})
        .then(async(data)=>{
            this.props.changeSettings(this.state);
            }
        ).catch((err)=>{
            console.log(err);      
        });
        this.props.onClose(true);
    };

    onChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    handleStart=(date)=>{
        this.setState({start:date});
    }   
    
    handleEnd=(date)=>{
        this.setState({end:date});
    }
    
    render(){
        return( 
            <div className="settingsForm" >
            <Form onSubmit={this.onSubmit}>
                <Row>
                    <Col xs={6}>
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel>Frequency</ControlLabel>
                            <FormControl onChange={this.onChange} name="frequency" value={this.state.frequency} componentClass="select" placeholder="select">
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup controlId="formControlsSelect2">
                            <ControlLabel>Source</ControlLabel>
                            <FormControl onChange={this.onChange} value={this.state.source} name="source" componentClass="select" placeholder="select">
                                <option value="recent">Recent</option>
                                <option value="historical">Historical</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.start}
                            onChange={this.handleStart}
                            />
                    </Col>
                    <Col md={6}>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.end}
                            onChange={this.handleEnd}
                            />
                    </Col>
                </Row>
                
                <Button type="submit" bsStyle="info">Save Changes</Button>
            </Form>
            </div>
        )
    }
}

export default StackedSettings;