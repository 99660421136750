import React,{ Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import AppContext from '../contexts/AppContext'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const utils = require('../services/utils');
var UserService = require('../services/userServices');



class HeaderAppBar extends Component{
      state = {
        anchorEl : null
      }

      static contextType = AppContext
      openHome (){
        this.props.history.push({ pathname : '/'  });
      }

      componentDidUpdate(){
        if(this.context.currentUser){
          this.userId = this.context.currentUser.id
        }
      }

      openAdmin (){
        this.props.history.push({ pathname : '/admin'  });
      }

      openProfile (){
        this.props.history.push({ pathname : '/profile/'+this.userId  });
      }

      addListing (){
        this.props.history.push({ pathname : '/apartments/add'  });
      }

      async logout (){
        await UserService.logout();
        this.props.onLogout();
      }

      openMenu(event) {
        this.setState({ anchorEl : event.currentTarget } );
      }
    
      closeMenu() {
        this.setState({anchorEl:null});
      }
    

      render() {
        return (
          <nav id="header" class="leading-normal tracking-normal text-white gradient fixed w-full z-30 top-0 text-white">
          <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
              
            <div class="pl-4 flex items-center" onClick={this.openHome.bind(this)}>
                <div class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" > 
                  RealEx
                </div>
            </div>
        
           
          {
            this.context.currentUser && 
            <>
             { utils.hasEditorAccess(this.context.currentUser) && 
              <div class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 mr-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
                <div class="list-reset lg:flex justify-end flex-1 items-center">
                    <div onClick={this.addListing.bind(this)} id="navAction" class="mx-auto justify-end  lg:mx-0 cursor-pointer bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-2 px-4 shadow opacity-75">List a Property</div>
                </div>
              </div>
             }
              <div class="relative group">
                <div class="flex items-center cursor-pointer text-sm text-blue border border-white  group-hover:border-grey-light rounded-t-lg py-1 px-2 mr-2" onClick={this.openMenu.bind(this)}>
                  <img src={this.context.currentUser.imageUrl} class="rounded-full h-10 w-10 object-cover" />
                  <p class="hidden lg:block px-2 font-bold">{this.context.currentUser && this.context.currentUser.firstName}</p>
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
                <Menu
                    id="header-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.closeMenu.bind(this)}
                  >
                    <MenuItem onClick={()=> {this.openProfile(); this.closeMenu() }}>My Profile</MenuItem>
                    
                    { utils.hasEditorAccess(this.context.currentUser) && 
                      <MenuItem classes={"lg:hidden"} onClick={()=> {this.addListing(); this.closeMenu() }}>Add Listing</MenuItem>
                    }

                    { utils.hasAdminAccess(this.context.currentUser) && 
                      <MenuItem onClick={()=> {this.openAdmin(); this.closeMenu() }}>Admin Panel</MenuItem>
                    }
                    <MenuItem onClick={()=> {this.logout(); this.closeMenu() }}>Logout</MenuItem>
                  </Menu>
                </div>
              
            </>
          }
            
          </div>
          
          <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
        </nav>

            
            
        );
      }

}


export default withRouter(HeaderAppBar);