const axios = require('axios');
const qs = require('query-string');
const { default: Axios } = require('axios');
const remote = require('../config/remote.js');
const API_BASE_URL = remote.url;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
	var token = localStorage.getItem('accessToken');
	if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	}, function (error) {    
        return Promise.reject(error);
    }
); 	

module.exports = {

    fetchTagArticles : (tagName,pageQuery,cancelToken) => {
        return new Promise((resolve,reject)=>{
            let remoteURL = API_BASE_URL + "/tags/articles/" + tagName;
            if(pageQuery){
                let pageQS = qs.stringify(pageQuery);
                remoteURL += "?" + pageQS;
            };
            axiosInstance.get(remoteURL,{cancelToken}).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                if(Axios.isCancel(err)){
                    resolve({isCancelled:true});
                }else{
                    resolve(err.response.data);
                }
            })
        })
    },

    fetchTags : function (count, filterQuery, search){
        return new Promise( function(resolve, reject) {
            let remoteURL = API_BASE_URL + "/tags?duplicates=true";
            if(search) remoteURL += `&search=${search}`;
            if(count){
                remoteURL += "&count=" + count;
            }
            let filterString= qs.stringify(filterQuery);
            if(filterString){
                if(!count) remoteURL +="?";
                else remoteURL+="&"
                remoteURL +=filterString;
            }

            axiosInstance.get(remoteURL).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            });
        });
    },
    updateTagsBulk : function (tags) {
        return new Promise(function(resolve,reject){
            let remoteURL = API_BASE_URL + "/tags"
            axiosInstance.put(remoteURL, tags).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    getTagById : ({ tagId }) => {
        return new Promise((resolve,reject) => {
            let remoteURL = API_BASE_URL + "/tags/id/" + tagId;
            axiosInstance.get(remoteURL).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    getTagDetails: ({ tagId, similarSearchText }) => {
        return new Promise((resolve, reject) => {
            let remoteURL = `${API_BASE_URL}/tags/details/${tagId}`;

            if (similarSearchText){
                remoteURL += `?searchText=${similarSearchText}`
            }
            
            axiosInstance.get(remoteURL).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                resolve(err.response.data);
            })
        })
    },
    bulkValidate : ({ tags, isValid }) => {
        return new Promise((resolve, reject) => {
            let remoteURL = `${API_BASE_URL}/tags/validate`;
            axiosInstance.put(remoteURL, { tags, isValid }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                resolve(err.response.data);
            });
        });
    },
    updateTag : function (id,updateBody){
        return new Promise((resolve,reject)=>{
            let remoteURL = API_BASE_URL + "/tags/" + id;
            axiosInstance.put(remoteURL,updateBody).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    fetchReportedTags : function(page = 0,pageSize = 10){
        return new Promise( function(resolve, reject) {
            let remoteURL = `${API_BASE_URL}/tags/reports?page=${page}&pageSize=${pageSize}`;
            axiosInstance.get(remoteURL).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            });
        });       
    },
    rejectReport : (tagId)=>{
        return new Promise((resolve,reject)=>{
            let remoteUrl = API_BASE_URL + "/tags/report/reject/" + tagId;
            axiosInstance.get(remoteUrl).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    bulkRejectReport: (tags) => {
        return new Promise((resolve, reject) => {
            let remoteUrl = API_BASE_URL + "/tags/reports/reject";
            axiosInstance.put(remoteUrl, { tags }).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    // searching for tags by name
    searchSimilarTags: (tagName) => {
        return new Promise((resolve,reject)=>{
            let remoteURL = API_BASE_URL + "/tags/search/" + tagName;
            axiosInstance.get(remoteURL).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    // fetching tag by name - (tag must exists)
    fetchSimilarTags : (tagName, tagId) => {
        return new Promise((resolve,reject)=>{
            let remoteURL = API_BASE_URL + "/tags/similar/" + tagName;
            axiosInstance.get(remoteURL).then((res)=>{
                resolve(res.data);
            }).catch((err)=>{
                resolve(err.response.data);
            })
        })
    },
    markAsDuplicate : ( tagId, tags, validateSource = false) => {
        return new Promise((resolve,reject)=>{
            let remoteURL = API_BASE_URL + "/tags/duplicate";
            let payload = { tagId, tags, validateSource };
            axiosInstance.post(remoteURL,payload).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                resolve(err.response.data);
            })
        })
    },
    removeDuplicates : (tagId, tagIds) => {
        return new Promise((resolve, reject) => {
            let remoteURL = API_BASE_URL + "/tags/duplicate/remove";
            let payload = { tagId, tags: tagIds };
            axiosInstance.post(remoteURL, payload).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                resolve(err.response.data);
            })
        })
    }
}