import React from "react";

import { 
    FormControl,
    Form,
    Row,Col,
    Button,
    Image,
    Modal
} from "react-bootstrap";
import PreviewCard from '../../../components/PreviewCard/PreviewCard.jsx';

class EditProofModal extends React.Component{

    constructor(props){
        super(props);
        this.state= {
            show:true,
            title:"",
        }
    }   

    componentDidUpdate = async (prevProps) =>{
        if(prevProps!=this.props && ( prevProps.show != this.props.show || !prevProps.show )){
            this.setState({
                show:this.props.show,
                title:this.props.data.title
            })
        }
    }

    handleChange = (e) =>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    // handles when done editing
    handleDone = () =>{
        this.setState({
            show:false
        })
        let proofData = {
            title : this.state.title,
            claimId : this.props.data.claimId,
            proofId : this.props.data.proofId
        }
        this.props.onDone(proofData);
    }

    // handles when editing is cancelled
    handleCancel = ()=>{
        this.setState({
            show:false
        })
        this.props.onCancel();
    }

    selectArticleImage = (imageUrl) =>{
        this.props.onImageSelect(imageUrl);
    }

    render(){
        return <>
            <Modal show={this.state.show} onHide={this.handleCancel}>
                <Modal.Header>
                    Manual Proof Edit
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <FormControl type="text" name="title"
                                placeholder="Enter Proof Title" value={this.state.title}
                                onChange={this.handleChange} />
                        </Col>
                    </Row>
                    <Row>
                        <div style={{padding:"0px 20px",marginTop:"15px"}}>
                            <div style={{marginBottom:"15px"}}>Proof Preview : </div>
                            <PreviewCard 
                                selected = {this.props.selectedImage === this.props.data.image}
                                onImageSelect = {this.selectArticleImage.bind(this)}
                                title={this.state.title}
                                image={this.props.data.image}
                                url={this.props.data.link} 
                                publishTime = {this.props.data.publishedTime}
                            />
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCancel}>
                        Cancel
                    </Button>
                    <Button className="primaryButton" onClick={this.handleDone}>
                        Done
                    </Button>
                </Modal.Footer>    
            </Modal>
        </>
    }
}

export default EditProofModal;