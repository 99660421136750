import Axios from "axios";
import { toast } from "react-toastify";
import getAgoString from "../../../utils/timeAgo";
import LoadingOverlay from 'react-loading-overlay';
import tagsService from "../../../services/tagsService";
import React, { useCallback, useEffect, useRef, useState} from "react";

const ArticleCard = React.forwardRef(({_id,title,description,image,provider,article_time}, ref) => {
    let articleURL = "https://xotkari.com/article?id=" + _id;
    return <div ref={ref} className="articleCard" key={_id}>
        <div className="imgBlock" style={{backgroundImage:'url("'+image[0].url+'")'}}></div>
        <div className="detailBlock">
            <div className="textContent">
                <p className="title"><a className="titleLink" href={articleURL} target="_blank">{title}</a></p>
                <p className="description">{description}</p>
            </div>
            <div className="metadata">
                <div>{provider.name}</div>
                <div>{getAgoString(article_time["$date"])}</div>
            </div>
        </div>
    </div>
});

export default function ArticlesBlock({ tagName }){

    const [error,setError] = useState(false);
    const [hasMore,setHasMore] = useState(true);
    const [loading,setLoading] = useState(false);
    const [articles,setArticles] = useState([]);
    const [page,setPage] = useState(0);

    const pageSize = 10;

    const observer = useRef();

    const lastArticleRef = useCallback(node =>{
        if(error || !hasMore || loading) return;
        if(observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting) {

                const cancelToken = Axios.CancelToken;
                const source = cancelToken.source();

                fetchArticles({ page: page + 1, cancelToken: source.token });
            };           
        })
        if(node) observer.current.observe(node);
    },[hasMore, loading, error]);

    // when tag name is changed
    useEffect(() => {   

        const cancelToken = Axios.CancelToken;
        const source = cancelToken.source();

        // set page to 0
        setPage(0);

        // fetch tag articles
        fetchArticles({ cancelToken: source.token, page: 0 });

        return ()=> source.cancel();
    }, [tagName] );


    // fetch article tags
    const fetchArticles = async ({ cancelToken, page }) => {
        if (!tagName) return;
        
        setLoading(true);

        try {
            let pageQuery = { page, pageSize };

            let response = await tagsService.fetchTagArticles(tagName, pageQuery, cancelToken); 
            if(response.success){
                let articles = response.data.articles;
                let filteredArticles = articles.filter((item)=>{
                    return (item && item.image.length>0);
                })

                if(filteredArticles.length == 0) {
                    setLoading(false);
                    setHasMore(false);
                    return;
                };
                
                setArticles(prevArticles => [...prevArticles,...filteredArticles]);
                setPage(page);

            }else if(!response.isCancelled){
                toast.error(response.data.message);
                setError(true);
            }
        } catch(err) {
            setError(true);
            toast.error("Could not fetch articles at the moment");
        }

        setLoading(false);
    }
    
    return <div className="tagArticlesWrapper">
        <div className="header">
            Recent Articles 
        </div>
        <LoadingOverlay 
            active={loading}
            spinner
            text="Loading Articles ..."
        >
            <div className="content">
                {articles.map((article,index) => {
                    if(articles.length == index+1 ) {
                        return <ArticleCard ref={lastArticleRef} key={index} {...article}/>
                    }else{
                        return <ArticleCard key={index} {...article}/>
                    }
                })}
            </div>
        </LoadingOverlay> 
    </div>
}