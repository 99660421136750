import React from 'react';
import {subDays} from "date-fns";
import {
    Modal,
    Row,
    Col,
    Button,
    FormGroup,
    InputGroup,
    ButtonToolbar,
    ToggleButtonGroup
} from "react-bootstrap";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import TodayIcon from '@material-ui/icons/Today';
import articleService from "../../../services/articleService.js";
import { CircularProgress } from '@material-ui/core';

class ScheduleModal extends React.Component{

    constructor(props){
        super(props);
        this.state={

            showModal : false,
            id:props.id,

            publishDate:null,
            publishDateMessage:"",

            expiryDate:null,
            expiryDateMessage:"",

            publishPickerOpen:false,
            expiryPickerOpen:false,
            scheduleSelect:0,

            facebookDisable:false,
            twitterDisable:false,
            instagramDisable:false,
            facebookShare:false,
            instagramShare:false,
            twitterShare:false,

            isLoading:false,
        }
    }

    componentDidUpdate (prevProps){
        // modal show Change
        if(this.props.showModal && ( this.props.showModal!=prevProps.showModal || !prevProps.showModal )){
            this.setState({
                publishDate:this.props.publishDate,
                expiryDate:this.props.expiryDate,
                facebookShare:this.props.facebookShare,
                twitterShare : this.props.twitterShare,
                facebookDisable : this.props.facebookShare,
                twitterDisable : this.props.twitterShare,
                showModal : this.props.showModal,
            })
        }
    }

    // closes the schedule modal on schedule cancel
    cancelSchedule =()=>{
        this.setState({
            showModal:false
        })
        this.props.onCancel();
    }
    
    // toggle sharing to social checkbox
    toggleShare=(e)=>{
        this.setState({
            [e.target.name]:e.target.checked
        });
    }

    // handle custom date picker selection - publish date / expiry date
    handleRawDate = (name,date) =>{

        let newDate=moment(date).format("LLL"); // format date

        // Date format validation
        if(newDate==="Invalid date"){
            let field = name + "Message";     //  publishDateMessage / expiryDateMessage
            this.setState({
                [field]:"* Invalid Date",
            })
            return;
        }

        // Feasible Date Validation
        let minDate;
        if(name=="publishDate"){
            minDate = new Date();  
        }else{
            minDate = new Date(this.state.publishDate);
        }
        
        minDate.setSeconds(0,0);

        let valid = moment(date).isSameOrAfter(minDate);
        let messagefield = name + "Message";
        if(!valid){
            let message = (name==="publishDate") ? "* Publish Date cannot be in the past" : "* Expiry Date must be after Publication"
            this.setState({
                [messagefield]:message,
            })
        }else{
            let expiryMessage="";
            if(name==="publishDate" && this.state.scheduleSelect===4){
                //check expiry Date
                valid = moment(this.state.expiryDate).isAfter(new Date(date));
                expiryMessage = valid ? "" : "* Expiry Date must be after Publication";
            }
            this.setState({
                [messagefield]:"",
                expiryDateMessage:expiryMessage,
                [name]:new Date(date).getTime(),
            })
        }
    }

    // open publish date picker
    openPublishPicker=()=>{
        let prevState = this.state.publishPickerOpen;
        this.setState({
            publishPickerOpen:!prevState
        })
    }

    // open expiry date picker
    openExpiryPicker = () => {
        if(this.state.scheduleSelect!=4){
            return;
        }
        let prevState = this.state.expiryPickerOpen;
        this.setState({
            expiryPickerOpen:!prevState,
        })
    }

    // handles Daily, Weekly and monthly selection
    handleScheduleButton= async (e,buttonValue)=>{

        // if same button is clicked twice, select none
        if(buttonValue == this.state.scheduleSelect){
            buttonValue=0;
        }

        let expiryDate;

        // if custom date is selected
        if(buttonValue === 4 ){
            expiryDate = new Date(this.state.expiryDate).getTime();
        }else{

            let now = new Date();
            //daily article  - tomorrow at 1pm 
            if( buttonValue === 1 ){
                now.setDate(now.getDate()+1);
                now.setHours(13);
                now.setMinutes(0);
                now.setSeconds(0);
                expiryDate = now.getTime();
            }
            else if(buttonValue<=3){
                //days after expired 
                let articleTypeLife = {
                    1:1, // 1 day
                    2:3, // 3 days
                    3:7, // 7 days
                }
                let daysToLive  = articleTypeLife[buttonValue];
              
                expiryDate = now.setDate(now.getDate() + daysToLive);
            }

        }
        
        this.setState({
            expiryDate:expiryDate,
            expiryDateMessage:"",
            scheduleSelect:buttonValue,
        })
    }
    
    // handle article scheduling
    scheduleArticle = async ()=>{
        this.setState({
            isLoading:true,
        })
        if(this.state.expiryDateMessage!="" || this.state.publishDateMessage!=""){
            toast.error("Scheduled Date is Invalid");
            return;
        }

        let scheduleData = {
            publishDate:this.state.publishDate,
            expiryDate:this.state.expiryDate,
            id:this.props.id,
            facebookShare:this.state.facebookShare,
            twitterShare:this.state.twitterShare,
            type:this.state.scheduleSelect,
        }
        let response = await articleService.scheduleArticle(scheduleData);
        this.setState({
            showModal:false,
        })
        if(response.success){
            toast.success(response.message);
            this.props.onDone();
        }else{
            toast.error(response.message);
        }
        this.setState({
            isLoading:false,
        })
    }

    render(){
        const scheduleSelected = this.state.scheduleSelect;

        return <>
        
            <Modal show={this.state.showModal}>
                <Modal.Header>
                    <Modal.Title>Schedule Article</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowY:'visible',maxHeight:'70vh'}}>
                    <Row>
                        <Col md={3}>
                            Publish Date
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <InputGroup>
                                <DatePicker
                                    onClickOutside={this.openPublishPicker}
                                    open={this.state.publishPickerOpen}
                                    name="publishDate"
                                    selected={this.state.publishDate}
                                    onChange={(date) => this.handleRawDate("publishDate",date)}
                                    minDate={subDays(new Date(), 0)}
                                    onChangeRaw={event => this.handleRawDate("publishDate",event.target.value)}
                                    placeholderText="Select a date after 0 days ago"
                                    showTimeSelect
                                    strictParsing
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"/>
                                <InputGroup.Addon
                                    className="customDateChoose" 
                                    onClick={this.openPublishPicker}>
                                    <TodayIcon />
                                </InputGroup.Addon> 
                                </InputGroup>
                                <span className="errorMessage">{this.state.publishDateMessage}</span>
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={3}>
                            Expiry Date
                        </Col>
                        <Col md={9}>
                            <ButtonToolbar>
                                <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                                    <button className={`btn scheduleBtn ${scheduleSelected==1 ?  " primaryButton" : ""}`} onClick={(e)=>this.handleScheduleButton(e,1)}>Daily</button>
                                    <button className={`btn scheduleBtn ${scheduleSelected==2 ?  " primaryButton" : ""}`} onClick={(e)=>this.handleScheduleButton(e,2)}>Weekly</button>
                                    <button className={`btn scheduleBtn ${scheduleSelected==3 ?  " primaryButton" : ""}`}  onClick={(e)=>this.handleScheduleButton(e,3)}>Monthly</button>
                                    <button className={`btn scheduleBtn ${scheduleSelected==4 ?  " primaryButton" : ""}`}  onClick={(e)=>this.handleScheduleButton(e,4)}>Custom</button>
                                </ToggleButtonGroup>
                            </ButtonToolbar>
                        </Col>
                            <Col mdOffset={3} md={9} className="pt-20">
                            <FormGroup>
                                <InputGroup>
                                <DatePicker
                                    onClickOutside = {this.openExpiryPicker}
                                    disabled = { scheduleSelected===4 ? false : true }
                                    open={this.state.expiryPickerOpen}
                                    name="expiryDate"
                                    selected={this.state.expiryDate}
                                    onChange={(date)=> this.handleRawDate("expiryDate",date)}
                                    onChangeRaw = { (event) => this.handleRawDate("expiryDate",event.target.value)}
                                    minDate={this.state.publishDate}
                                    placeholderText="Select a date after 0 days ago"
                                    showTimeSelect
                                    strictParsing
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"/>
                                <InputGroup.Addon className="customDateChoose" onClick={this.openExpiryPicker}>
                                    <TodayIcon />
                                </InputGroup.Addon> 
                                </InputGroup>
                                <span className="errorMessage">{this.state.expiryDateMessage}</span>
                            </FormGroup>
                            </Col>
                        
                    </Row>

                    <br></br>
                </Modal.Body>
                <Modal.Footer className="clearfix">
                <div className="float-left">
                    <FormGroup>
                        <input 
                            type="checkbox" 
                            id="facebookShare"
                            name="facebookShare"
                            onChange={(e)=>this.toggleShare(e)}
                            checked={this.state.facebookShare}
                            disabled={this.state.facebookDisable}
                            />
                        <label htmlFor="facebookShare" style={{paddingLeft:"10px",paddingRight:"10px"}}>Facebook</label>
                        <input 
                            id="twitterShare"
                            name="twitterShare"
                            type="checkbox" 
                            disabled={this.state.twitterDisable}
                            onChange={(e)=>this.toggleShare(e)} 
                            checked={this.state.twitterShare}/>
                        <label htmlFor="twitterShare"  style={{paddingLeft:"10px",paddingRight:"10px"}}>Twitter</label>
                    </FormGroup>
                </div>
                <div className="float-right">
                    {this.state.isLoading ? 
                        <div>
                            <CircularProgress/>
                        </div>
                    :
                        <div>
                            <Button className="button" 
                                onClick={this.cancelSchedule}
                                disabled={this.state.buttonDisabled}>
                                Not Now
                            </Button>
                            <Button bsStyle="success" 
                                className="button publish-btn" 
                                onClick={this.scheduleArticle}
                                disabled={this.state.buttonDisabled}>
                                Publish Article
                            </Button>
                        </div>
                    }
                    
               </div>
            </Modal.Footer>
        </Modal>
        </>
    }
}

ScheduleModal.defaultProps={
    publishDate:null,
    facebookShare:false,
    instagramShare:false,
    twitterShare:false,
}

export default ScheduleModal;