import React from "react";
import { toast } from 'react-toastify';
import UserServices from '../../services/userServices';

import {
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Button
} from "react-bootstrap";
import AppContext from "../../contexts/AppContext.js";

const yup = require('yup');

// user object schema
const profileSchema = yup.object().shape({
    id:yup.string().required("Is required"),
    firstName: yup.string().typeError('Must be a string').required('Is required').min(2, 'At least 2 characters'),
    lastName: yup.string().typeError('Must be a string').required('Is required').min(1, 'At least 1 characters'),
    email : yup.string().typeError('Must be a string').email('Doesn\'t look like an email address').required('Is required'),
    phoneNumber : yup.string().nullable().matches(new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"), 'Doenst look like a phone number').min(6, 'Minimum 6 characters'),
    locked : yup.boolean(),
    userRole : yup.number().min(1).max(3),
    imageBase64 : yup.string()
  });

function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
        </FormGroup>
    );
}

class EditUser extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      modalOpen : true,
      formErrors : {},
      email : '',
      firstName: '',
      lastName: '',
      phoneNumber : '',
      locked : "",
      userRole : "",
      id:"",
      fullName:"",
      password:"",
      password2:"",
    };
  }

    static contextType = AppContext

    async componentDidMount(){
  
      var userId=this.props.userId;    

      this.setState({ userId : userId })
  
      var currentUser = await UserServices.getCurrentUser()
  
      if(currentUser.success){
        var userData = currentUser.data
        this.setState({currentUser : currentUser.data})
        
        // if(!userId){
        //   userId = userData.id
        // }
  
        if(userData.userRole < 2 ){
            // && userData.id != userId
        }else{
          var response = await UserServices.getUser(userId)
          
          if(response.success){
              var profileData = response.data
              var fullName=profileData.firstName + " " + profileData.lastName;
              this.setState({
                firstName : profileData.firstName,
                lastName : profileData.lastName,
                email : profileData.email,
                userRole : profileData.userRole,
                imageUrl : profileData.imageUrl,
                phoneNumber : profileData.phoneNumber,
                locked: profileData.locked ? "locked" : "active",
                id:userId,
                fullName
              })
          }
        }
      }
    }
  
    // handles form field changes
    handleChange = (event) => {
        var name=event.target.name;
        var value=event.target.value;
        if(name==="userRole"){
            switch(value){
                case "writer":
                    value=1;
                    break;
                case "admin":
                    value=3;
                    break;
                case "editor":
                    value=2;
                    break;
            }
        }
        this.setState({ [name]: value });
    };

    // handles user update
    updateUser = async (e) =>{

        e.preventDefault();
        
        // create a user object
        let userData = { 
          id : this.state.userId ,
          firstName : this.state.firstName,
          lastName : this.state.lastName,
          phoneNumber : this.state.phoneNumber ? this.state.phoneNumber : null, 
          email : this.state.email,
          locked: this.state.locked == 'locked' ? true : false ,
          userRole : parseInt(this.state.userRole),
          imageBase64 : this.state.imageBase64,
        }

        // validate passwords
        let password=this.state.password;
        let password2=this.state.password2;

        // if passwords not null and matches, change user password
        if(password!="" && password2!="" && password==password2){
            userData["password"]=password;
        }else if(password!="" && password2!=""){
            toast.warning("Passwords Does not match");
            return;
        }

        profileSchema.validate(userData, { abortEarly: false })
        .then(async (data) => {
          this.setState({formErrors : {}});
          var result = await UserServices.updateUser(this.state.userId, userData);
          if(result.success){
            toast('User Updated');
            this.props.onDone({success:true,userData}); 
          }else{
            toast.error(result.message || result.error);
          }
        })
        .catch((err) => {
          var formErrors = {}
          for(var i=0; i< err.inner.length; i++){
              var errorInstance = err.inner[i]
                toast.warning(errorInstance.path + " : " + errorInstance.message);
              formErrors[errorInstance.path] = errorInstance.message;
          }
          this.setState({formErrors});
        });
            
      }
    
    render() {
        return (
                <div className={"w-full p-5"} >
                    <div class="modal-title flex justify-between items-center">
                        <p class="text-3xl font-bold font-sans">Edit User</p>
                    </div>  
                    <form>
                        <Row>
                            <Col md={12}>
                                <FieldGroup
                                    id="id"
                                    type="text"
                                    name="id"
                                    label="User Id"
                                    defaultValue={this.props.userId}
                                    disabled
                                />
                            </Col>
                        </Row>
                        
                        <Row>    
                            <Col md={8}>
                                <FieldGroup
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.email}
                                />
                            </Col>
                            <Col md={4}>
                                <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Role</ControlLabel>
                                <FormControl name="userRole" onChange={this.handleChange} value={this.state.userRole==3 ? "admin" : this.state.userRole==2 ? "editor" : "writer" } componentClass="select" placeholder="select">
                                    <option value="writer" >Writer</option>
                                    <option value="editor">Editor</option>
                                    <option value="admin">Admin</option>
                                </FormControl>
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FieldGroup
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="New Password"
                                    placeholder="New Password"
                                    defaultValue={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <FieldGroup
                                    id="password2"
                                    type="password"
                                    name="password2"
                                    label="Confirm Password"
                                    placeholder="Confirm New Password"
                                    defaultValue={this.state.password2}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col md={6}>
                                <FieldGroup
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    defaultValue={this.state.firstName}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <FieldGroup
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    defaultValue={this.state.lastName}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col md={4}>
                                <FormGroup controlId="formControlsSelect1">
                                <ControlLabel>Status</ControlLabel>
                                <FormControl name="locked" value={this.state.locked} onChange={this.handleChange} componentClass="select" placeholder="select">
                                    <option value="active">Active</option>
                                    <option value="locked">Locked</option>
                                </FormControl>
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FieldGroup
                                    id="contact"
                                    type="text"
                                    label="Contact"
                                    name="phoneNumber"
                                    placeholder="Enter Contact"
                                    defaultValue={this.state.phoneNumber}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={4} xsOffset={8} > 
                                <Button bsStyle="info" fill type="submit" onClick={this.updateUser}>
                                    Update Profile
                                </Button>
                            </Col>
                        </Row>
                        
                </form>
            </div>
        );
    }
}

export default EditUser;
