import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

class ChangeTag extends Component {

  state = { 
    tagName: "",
    sourceTagName: "",
    isUpdatingTag: false,
  }

  componentDidMount(){ 
    const tagName =  this.props.tagName || ""
    this.setState({
      tagName,
      sourceTagName: tagName,
    })
  }

  componentDidUpdate(prevProps){
    if (this.props.tagName && this.props.tagName != prevProps.tagName) {
      const tagName = this.props.tagName;
      this.setState({
        tagName,
        sourceTagName: tagName
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  cancelSearch = () => {
    this.setState({
      tagName: this.state.sourceTagName
    })    
  }

  toggleTagUpdating = (show = true, cb = () => {}) => {
    let newState = { isUpdatingTag: show };

    if (!show) newState.tagName = this.state.sourceTagName;

    console.log(cb);
    this.setState(newState, cb)
  }

  handleSearch = () => {
    this.setState({
      isUpdatingTag: false,
    }, () => this.props.searchHandler(this.state.tagName) );
  }

  render() {
    const {
      label,
    } = this.props;

    return  <div className="headerBlock">
      <div className="clearfix phr-10">
        <span className="float-left">
          <span className="heading"> 
            <span style={{marginRight:"10px"}}>
              { label }
            </span>
            {this.state.isUpdatingTag ? 
              <input style={{borderBottom:'1px solid #d9d9d9'}} type="text" name="tagName" onChange={this.handleChange} value={this.state.tagName} placeholder="Tag name here" /> 
              :
              <span>{this.state.tagName} :</span> }
          </span> 
        </span>
        <span className="float-right">
          {this.state.isUpdatingTag ?
            this.state.isLoading ? <CircularProgress /> : <span>
              <button style={{marginRight:"10px"}} type="button" className="btn-sm validTagBlock" onClick={this.handleSearch}>Search</button>
              <button type="button" className="btn-sm waitingTagBlock" onClick={()=>this.toggleTagUpdating(false)}>Cancel</button>
            </span>
            :
            <button type="button" className="btn-sm primaryBtn" onClick={() => this.toggleTagUpdating(true)}>Change Tag</button>
          }
        </span>
      </div>
    </div>
  }
}

ChangeTag.propTypes = {
  label: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  searchHandler: PropTypes.func.isRequired,
}

export default ChangeTag;