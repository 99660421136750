import React from "react";
import { toast } from 'react-toastify';
import UserServices from '../../services/userServices';

import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Button
} from "react-bootstrap";

function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
        </FormGroup>
    );
}

let yup = require('yup');
const userSchema = yup.object().shape({
    email : yup.string().typeError('Must be a string').email('Doesn\'t look like an email address').required('Is required'),
    firstName: yup.string().typeError('Must be a string').required('Is required').min(2, 'At least 2 characters'),
    lastName: yup.string().typeError('Must be a string').required('Is required').min(1, 'At least 1 characters'),
    password:yup.string().required("Is required").min(6,"Password must be at least 6 characters"),
});

class AddUser extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            modalOpen : true,
            formErrors : {},
            email : '',
            firstName: '',
            lastName: '',
            phoneNumber : '',
            locked : "active",
            userRole : 1,
            id:"",
            fullName:""
        };
        this.handleChange=this.handleChange.bind(this);
        this.addUser=this.addUser.bind(this);
    }
    
    handleChange = (event) => {
        var name=event.target.name;
        var value=event.target.value;
        if(name==="userRole"){
            switch(value){
                case "client":
                    value=1;
                    break;
                case "editor":
                    value=2;
                    break;
                case "admin":
                    value=3;
                    break;
            }
        }
        this.setState({ [name]: value });
    };


    async addUser(e){
        var formErrors = {}
        e.preventDefault();
        
        var userData={
            email:this.state.email,    
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            password:this.state.password,
            locked: (this.state.locked=="locked") ? true : false,
            userRole: this.state.userRole 
        }
        userSchema.validate(userData, { abortEarly: false })
          .then((data) => {
                //validate password
            if(!(this.state.password === this.state.password2)){
                toast.warning("Password Does not Match");
                return;
            }
            this.setState({ formErrors  : {} })
            this.registerUser(userData);
          }).catch((err) => {
            for(var i=0; i< err.inner.length; i++){
                var errorInstance = err.inner[i]
                toast.warning(errorInstance.path + " : " + errorInstance.message);
                formErrors[errorInstance.path] = errorInstance.message;
            }
            this.setState({formErrors});
          })
        
    }

    async registerUser(userInfo){
        var response = await UserServices.createUser(userInfo);
        if(response.success){
          toast.success('Successfully added.')
          this.props.onDone(userInfo); 
        }else{
          toast.error(response.message || response.error)
        }
      }
    
    render() {
        return (
                <div className={"w-full p-5"} >
                    <div class="modal-title flex justify-between items-center">
                        <p class="text-3xl font-bold font-sans">Add User</p>
                      </div>
                      <form>
                        <Row>    
                            <Col md={8}>
                                <FieldGroup
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={4}>
                                <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Role</ControlLabel>
                                <FormControl name="userRole" onChange={this.handleChange} value={this.state.userRole==3 ? "admin" : this.state.userRole==2 ? "editor" : "writer"} componentClass="select" placeholder="select">
                                    <option value="writer" >Writer</option>
                                    <option value="editor">Editor</option>
                                    <option value="admin">Admin</option>
                                </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col md={6}>
                                <FieldGroup
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <FieldGroup
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        

                        <Row>
                            <Col md={6}>
                                <FieldGroup
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <FieldGroup
                                    id="password2"
                                    type="password"
                                    name="password2"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        

                        <Row>
                            <Col md={4}>
                                <FormGroup controlId="formControlsSelect1">
                                <ControlLabel>Status</ControlLabel>
                                <FormControl name="locked" value={this.state.locked} onChange={this.handleChange} componentClass="select" placeholder="select">
                                    <option value="active">Active</option>
                                    <option value="locked">Locked</option>
                                </FormControl>
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FieldGroup
                                    id="contact"
                                    type="text"
                                    label="Contact"
                                    name="phoneNumber"
                                    placeholder="Enter Contact"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={4} xsOffset={8} > 
                                <Button bsStyle="success" fill type="submit" onClick={this.addUser}>
                                    Add User
                                </Button>
                            </Col>
                        </Row>
                        
                </form>
                </div>
            
        );
    }
}

export default AddUser;
