import React from "react";
import {Grid,Row,Col} from "react-bootstrap";

import AllArticles from "./components/allArticles.jsx";
import MostViewedArticles from "./components/topArticles.jsx";


class ArticleStatistics extends React.Component{
    
    render(){
        return <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <MostViewedArticles />
                        <br></br>
                        <AllArticles/>
                    </Col>
                </Row>
            </Grid>
        </div>
    }

}

export default ArticleStatistics;