import React from "react";
import LoadingOverlay from 'react-loading-overlay';

var classNames = require('classnames');
var moment = require('moment');
//var Button = require('../components/Button');
//var Tag = require('../components/Button');

//import LoadingOverlay from 'react-loading-overlay';

const categories = require('../../const/categories')
var axios = require('axios')

class TagManagement extends React.Component{
    state = {TAGS : [], actionPending: false, allChecked: false, currentTag: {}}

    constructor(props){
        super(props);
		axios.get(`http://manager.xotkari.com:8080/getTags`)
	      .then(res => {
              console.log(res['data'])
              if (res['data'].length){
                var firstTag = res['data'].shift()
                console.log(firstTag)
	            this.setState({TAGS : res['data'], actionPending: false, allChecked: false, currentTag: firstTag});
              }
                
	      });
	}
    
	toggleAllRows() {
		this.setState({ allChecked: !this.state.allChecked });
    }
    
    onTagReview(){
        var nextTag = this.state.TAGS.shift()
        console.log('loaded next tag: ', nextTag)
        window.scrollTo(0, 0)
        this.setState({currentTag : nextTag})
    }

	
	
	render() {
		var self = this;
		return (
			<div className="page-container">
                <Tag tag={this.state.currentTag} onFinished={this.onTagReview.bind(this)} ></Tag>
			</div>
		);
	}
}




const sectionContainerStyle = {display:"flex", flexWrap:"wrap", margin: "5%", maxWidth:"95%"}
const sectionItemStyle = { margin:"10px" }


class Tag extends React.Component{
    state = {
        discarded: false,
        isLoading: false,
        tag : {categories:[], scopes:[], types:[]},
        linked_articles : []
    }
    constructor(props){
        super(props);
        this.loadTag(props.tag)
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.tag['name'] !== prevProps.tag['name']) {
          this.loadTag(this.props.tag)
        }
      }

      loadTag(tag){
        if (!tag['categories']){
            tag['categories'] = []
        }
        if (!tag['scopes']){
            tag['scopes'] = []
        }
        if (!tag['types']){
            tag['types'] = []
        }
        this.setState({
            tag : tag,
            linked_articles : []
        })
        
        if (!tag['name'])
            return
        
        var search_url = "http://api.xotkari.com/search/"+tag['name']+"/0"
        axios.get(search_url)
	      .then(res => {
              console.log(res['data'])
              if (res['data'].length){
	            this.setState({linked_articles : res['data']})
              }
                
	      });
      }

     render(){
     	console.log(this.state.tag)
        return (
            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text='Saving...'
                styles={{
                    wrapper: {
                      zIndex: '100000'
                    }
                  }}
                >


                <div style={{display:"flex"}}>
                    <div style={{
                        width: "25%",
                        marginLeft: "5%"
                    }}>
                            <h3><b>{this.state.tag['name']}</b></h3>
                        
                            <h5>Appeared {this.state.tag['count']} times.</h5>
                            <hr />
                            <strong><h4>Categories</h4></strong>
                            <div style={sectionContainerStyle}>
                                {categories.map(function(item) { return <div key={item}>
                                    <label>
                                    <input
                                        type="checkbox"
                                        style={sectionItemStyle}
                                        name={item}
                                        checked={this.state.tag['categories'].indexOf(item) > -1}
                                        onChange={() => this.changeCategory(item)} /> {item}
                                        
                                    </label>
                                </div> }.bind(this))}
                            </div><hr />

                            <strong><h4>Scope</h4></strong>
                            <div style={sectionContainerStyle}>
                                {['नेपाल', 'अन्तर्राष्ट्रिय'].map(function(item) { return <div key={item} >
                                    <label className="checkbox-label">
                                        <input type="checkbox"  style={sectionItemStyle} onChange={(e) => this.changeScope(item)}  checked={this.state.tag['scopes'].indexOf(item) > -1}  /> {item}
                                    </label>
                                </div> }.bind(this))}
                            </div><hr />

                            
                            <strong><h4>Type</h4></strong>
                            <div style={sectionContainerStyle}>
                                {['रुचिको विषय', 'ग्यालरी'].map(function(item) { return <div key={item} >
                                    <label className="checkbox-label">
                                        <input type="checkbox"  style={sectionItemStyle} onChange={(e) => this.changeType(item)} checked={this.state.tag['types'].indexOf(item) > -1} /> {item}
                                    </label>
                                </div> }.bind(this))}
                            </div><hr />

                        

                            <div className="inline-controls">
                                <button  onClick={this.store.bind(this)} class="bg-blue-500 text-xl hover:bg-blue-700 text-white font-bold py-4 px-6 m-2 rounded focus:outline-none focus:shadow-outline" type="button"> Save </button>
                                <button  onClick={this.discard.bind(this)} class="bg-blue-500 text-xl hover:bg-blue-700 text-white font-bold py-4 px-6 m-2 rounded focus:outline-none focus:shadow-outline" type="button"> Discard </button>
                            </div>
                        </div>

                        <div  style={{
                        width: "60%",
                        marginLeft: "5%",
                        display:"flex",
                        flexWrap:"wrap"
                    }}>
                            <strong style={{width:"100%"}}><h4>Appearances</h4></strong>
                            {this.state.linked_articles.map(function(article) { return <div key={article} style={{margin:"2.5%", maxWidth:"45%"}}>
                                {
                                    !article ? <></> :<>
                                    <a target="_blank" href={article['url']}>
                                    <p><b>{article['title']}</b></p> 
                                    {article['image'].length > 0 ? <img src={article['image'][0]['url']} style={{maxHeight: "200px", maxWidth:'100%'}}/>:<></> }</a>
                                    <p>{article['description']}</p>
                                    </>
                                }
                                
                            </div> }.bind(this))}
                        </div><hr />

                </div>


                    </LoadingOverlay>
			);
     }

     changeCategory(categ) {
        var tag = this.state.tag
     	if(tag.categories.indexOf(categ) < 0){
     		tag.categories.push(categ)
     	}else if(tag.categories.indexOf(categ) > -1 ){
     		tag.categories.splice(tag.categories.indexOf(categ), 1);
     	}
     	this.setState({tag: tag})
     }

     changeScope(scope) {
        var tag = this.state.tag
     	if(tag.scopes.indexOf(scope) < 0){
     		tag.scopes.push(scope)
     	}else if(tag.scopes.indexOf(scope) > -1 ){
     		tag.scopes.splice(tag.scopes.indexOf(scope), 1);
     	}
     	
     	this.setState({tag: tag})

     }

     changeType(type) {
        var tag = this.state.tag
     	if(tag.types.indexOf(type) < 0){
     		tag.types.push(type)
     	}else if(tag.types.indexOf(type) > -1 ){
     		tag.types.splice(tag.types.indexOf(type), 1);
     	}
     	
     	this.setState({tag: tag})
     }

     discard(){
     	var tag_data = this.state.tag
     	tag_data['valid'] = false
        this.setState({isLoading : true})
     	axios.post(`http://13.228.46.165:8080/storeTag`, tag_data )
	      .then(res => {
            setTimeout(() => {
                this.setState({isLoading : false})
                this.props.onFinished()
              }, 1000);
	      });
     	
     }

     store(){
     	var tag_data = this.state.tag
     	tag_data['valid'] = true
     	if(tag_data['categories'].length < 1)
             return;
             
     	console.log(tag_data);
         //return;
        this.setState({isLoading : true})
     	axios.post(`http://13.228.46.165:8080/storeTag`, tag_data )
	      .then(res => {
            setTimeout(() => {
                this.setState({isLoading : false})
                this.props.onFinished()
              }, 1000);
	      });
     }

     handleChange(event) {
	    this.setState({value: event.target.value});
	 }
}





//module.exports = Tables;
export default TagManagement;