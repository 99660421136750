import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import React,{ Component, createRef } from "react";
import { CircularProgress, Paper } from "@material-ui/core";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import ChangeTag from "./components/ChangeTag";
import { getValue } from "../../../utils/object";
import tagsService from "../../../services/tagsService";

import "./similarTags.styles.css";

class SimilarTags extends Component{

  similarTableRef = createRef();

  modalType = {
    "valid": {
      modalType: "valid",
      body: "Are you sure you want to mark these tags as valid ?",
    },
    "invalid": {
      modalType: "invalid",
      body: "Are you sure you want to mark these tags as invalid ?",
    },
    "duplicate": {
      modalType: "markDuplicate",
      body: "Are you sure you want to mark these tags as duplicates ?",
    },
    "removeDuplicate": {
      modalType: "removeDuplicate",
      body: "Are you sure you want to remove these tags as duplicates ?",
    }
  }

  state = {
    tagName:"",
    similarTags:[],
    duplicateTags:[],
    isLoading:true,
    sourceTag: null,
    errorMessage:null,
    isUpdatingTag: false,
    refreshTagData: true,
    confirmModal: {
      tags: [],
      show: false,
      validateSource: false,
      modalType: this.modalType.duplicate,
      title: "Confirmation Required",
      body: "Do you want to mark these tags as duplicate",
    }
  }

  serviceHandler = {
    success: (duplicateTags, similarTags, sourceTag, tagName) => {
      this.clearSelection();
      this.setState({
        similarTags,
        duplicateTags,
        isLoading:false,
        errorMessage:null,
        isUpdatingTag: false,
        sourceTag: sourceTag,
        tagName: tagName || sourceTag.name,
        confirmModal: {
          ...this.state.confirmModal,
          show: false,
          tags: [],
        }
      })
    },
    error: (err) =>{
      this.setState({
        isLoading:false,
        errorMessage: err.message || "Something went wrong. Try again"
      })
    }
  }

  async componentDidMount(){
    const { duplicateTags, similarTags, tag } = this.props;
    this.serviceHandler.success(duplicateTags, similarTags, tag);
  }

  async componentDidUpdate(prevProps){
    if( prevProps != this.props && this.state.refreshTagData){
      const { duplicateTags, similarTags, tag, tagName} = this.props;

      if ( duplicateTags != prevProps.duplicateTags || similarTags != prevProps.similarTags || tag != prevProps.tag || tagName != prevProps.tagName){
        this.serviceHandler.success(duplicateTags, similarTags, tag, tagName);
      }
    }
  }

  handleSearchTags = async (tagName) => {
    this.setState({ isLoading: true, tags: [] });
    try {
      let response = await tagsService.searchSimilarTags(tagName);
      if (response.success && response.data && response.data.tags){
        let { tags } = response.data;

        const newState = {
          refreshtagData: false,
          isLoading:false,
          similarTags: tags,
          tagName,
        }
        if (tags.length == 0) {
          newState.errorMessage = "No tags found";
        };

        this.setState(newState);

      }
    } catch(err) {
      console.log(err);
      this.serviceHandler.error(err);
    }
  }

  loadSimilarTags = async (tagName) => {

    this.setState({ isLoading: true, tags: [] });
    try{
      let response = await tagsService.fetchSimilarTags(tagName);
      if(response.success && response.data && response.data.tags){

        const { sourceTag } = response.data;
        let { tags } = response.data;

        if(tags.length==0) throw Error("No similar tags found");

        this.setState({
          refreshTagData: false
        })
        this.serviceHandler.success([], tags, sourceTag);
      }else{
        throw Error(response.message || "Something went wrong. Try again");
      }
    }catch(err){
      console.log(err);
      this.serviceHandler.error(err);
    }
  }

  cancelSearch = () => this.setState({ isUpdatingTag: false, tagName: this.state.sourceTag.name});

  showTagInput = () => this.setState({ isUpdatingTag: true, tagName: this.state.tagName });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  } 
  
  // show Confimation modal based on confirm Type;
  showConfirmModal = async (confirmType, tags) => {

    const selectedIds = tags.map(({ id }) => id);

    const newConfirmState =  {
      ...this.modalType[confirmType],
      tags: selectedIds,
      show: true,
      title: "Confirmation Required",
    }
    this.setState({ confirmModal: newConfirmState });
  }

  confirmRemoveDuplicate = async (sourceId, tags) => {
    try{
      
      const response = await tagsService.removeDuplicates(sourceId, tags); 
      const { success, data } = response;

      if (success ){
        const { message } = data;  
        toast.success(message || "Tags Updated");

        if (this.state.refreshTagData){
          this.props.refreshTagData({ tagId: sourceId, similarSearchText: this.state.tagName }); 
        } else {
          await this.loadSimilarTags(this.state.tagName);
        }
      } else {
        throw Error("Something went wrong");
      }

    } catch (err) {
      throw err;
    }
  }

  // confirm mark as duplicates
  confirmMarkDuplicates = async (sourceId, tags, validateSource) => {
    try{

      const response = await tagsService.markAsDuplicate( sourceId, tags, validateSource);
      const { success, data } = response;

      if(success && data){
        const { message } = data;
        toast.success(message || "Tags Updated");
        if (this.state.refreshTagData){
          this.props.refreshTagData({ tagId: sourceId, similarSearchText: this.state.tagName }); 
        } else {
          await this.loadSimilarTags(this.state.tagName);
        }
      } else {
        throw Error("Unable to mark tags as duplicate");
      }
    }catch(err){
      throw err;
    }
  }

  confirmValidateTags = async ({ markValid, tags }) => {
    try {
      const response = await tagsService.bulkValidate({ tags, isValid: markValid });
      const { success, data } = response;
      let { message } = response;
      if (!message && data){
        message = getValue(data, message);
      }

      if (success) {
        const { id } = this.state.sourceTag;
        if (this.state.refreshTagData){
          this.props.refreshTagData({ tagId: id, similarSearchText: this.state.tagName }); 
        } else {
          await this.loadSimilarTags(this.state.tagName);
        }
        toast.success(message || "Tags Updated");
      }else {
        throw Error(message || "Unable to update tags");
      }
    } catch(err) {
      console.log(err);
      throw err;
    }
  }

  // handles 'confirm' button for the confimation modal based on confirmModal type
  handleConfirmModal = async () => {
    try {
      this.setState({ isLoading: true });

      const confirmType = getValue(this.state, 'confirmModal.modalType');
      const selectedTags = getValue(this.state, 'confirmModal.tags');

      const { duplicate, valid, invalid, removeDuplicate } = this.modalType;

      const { id } = this.state.sourceTag; 

      let selectedIds = selectedTags;

      switch (confirmType) {
        case valid.modalType:
          await this.confirmValidateTags({ markValid: true, tags: selectedTags});
          break;
        case invalid.modalType:
          await this.confirmValidateTags({ markValid: false, tags: selectedTags });
          break;
        case duplicate.modalType:
          const { validateSource } = this.state.confirmModal;
          await this.confirmMarkDuplicates(id, selectedTags, validateSource);
          break;
        case removeDuplicate.modalType:
          await this.confirmRemoveDuplicate(id, selectedTags);
          break;
      }

      this.props.removeTagsFromList(selectedIds);
      
    } catch (err) {
      this.setState({ isLoading: false });
      toast.error(err.message || "Something went wrong. Please try again");
    }
  }

  // hides the confirm modal
  hideConfirmModal = () => {

    // if loading, do not hide the modal
    if (this.state.isLoading) return;

    this.setState({
      confirmModal: {
        ...this.state.confirmModal,
        show: false
      }
    })
  }

  openTagData = (tag) => {
    if(!tag) return;
    this.setState({ isLoading: true, refreshTagData: true });
    this.props.openTagData(tag)
  }

  clearSelection = () => {
    if (this.similarTableRef.current) {
      this.similarTableRef.current.onAllSelected(false)
    }
  }

  changeValidSource = (e) => {
    this.setState({
      confirmModal:{ 
        ...this.state.confirmModal,
        validateSource: e.target.checked,        
      }
    })
  }

  render(){

    // show source valid option in confimration modal
    const sourceValidOption = this.state.confirmModal.modalType === "markDuplicate" && !this.state.sourceTag.isValid;

    return <div>
      
      <div className="contentWrapper">


        { this.state.isLoading  ?  <div className='centeredContent'><CircularProgress /></div> :  
        
        <div>

          {/* Duplicate Tags */}
          { this.state.duplicateTags && this.state.duplicateTags.length > 0 && <div  style={{marginBottom:'50px'}}>
            <p>
              Duplicate tags for <span>{this.state.sourceTag.name}</span>
            </p>
            <MaterialTable 
              title= 'LIST OF DUPLICATE TAGS'
              data={this.state.duplicateTags}
              columns={[
                { title: `Tag`, field:`name`,  render: (rowData) => <span className="clickable" onClick={() => this.openTagData(rowData)} >{rowData.name}</span>},
              ]}
              actions={[
                { 
                  icon: () => <Button className="btn-sm dangerButton ml-2">Mark as Invalid</Button>,
                  onClick:(e, tags) => this.showConfirmModal("invalid", tags)
                },
                { 
                  icon: () => <Button className="btn-sm validTagBlock ml-2">Mark as Valid</Button>,
                  onClick:(e, tags) => this.showConfirmModal("valid", tags)
                },
                { 
                  icon: () =><Button className="btn-sm primaryButton ml-2">Remove Duplicate</Button>, 
                  onClick:(e, tags) => this.showConfirmModal("removeDuplicate", tags)
                },
              ]}
              components={{
                Container: props => <Paper {...props} elevation={0}/>
              }}
              options={{
                search: false,
                selection: true,
                padding:'dense',
                actionsColumnIndex: -1,
                pageSize: this.state.duplicateTags? this.state.duplicateTags.length : 0,
                headerStyle: {
                  color: '#FFF',
                  fontSize: "15px",
                  backgroundColor: '#01579b',
                },
                rowStyle: (rowData)=>{
                  const rowStyle = {
                    padding:'5px',
                    fontWeight:"bold",
                  }
                  if (rowData.isValid != undefined){
                    rowStyle.color = rowData.isValid ? "green" : "red";
                  }
                  return rowStyle;
                }
              }}
            />

          </div>}

          {/* Similar Tags */}
          <ChangeTag tagName={this.state.tagName} label="Similar tags for" searchHandler={(tagName) => this.handleSearchTags(tagName)} />

          { this.state.similarTags && this.state.similarTags.length <= 0 && <div className="centeredContent">No Tags Found</div>}

          { this.state.similarTags && this.state.similarTags.length > 0 && <div  style={{marginBottom:'50px'}}>
            
            
            <MaterialTable 
              ref={this.similarTableRef}
              title= 'LIST OF SIMILAR TAGS'
              data={this.state.similarTags}
              columns={[
                { title: `Tag`, field:`name`,  render: (rowData) => <span className="clickable" onClick={() => this.openTagData(rowData)} >{rowData.name}</span>},
                { title: `Duplicate-Of`, field:'source', render: (rowData) => <span className="clickable" onClick={() => this.openTagData(rowData.source)} >{rowData.source ? rowData.source.name : "-"}</span>}
              ]}
              actions={[
                { 
                  icon: () => <Button className="btn-sm dangerButton ml-2">Mark as Invalid</Button>,
                  onClick:(e, tags) => this.showConfirmModal("invalid", tags)
                },
                { 
                  icon: () => <Button className="btn-sm validTagBlock ml-2">Mark as Valid</Button>,
                  onClick:(e, tags) => this.showConfirmModal("valid", tags)
                },
                { 
                  icon: () =><Button className="btn-sm primaryButton ml-2">Mark as Duplicate</Button>, 
                  onClick:(e, tags) => this.showConfirmModal("duplicate", tags)
                },
              ]}
              components={{
                Container: props => <Paper {...props} elevation={0}/>
              }}
              options={{
                search: false,
                selection: true,
                padding:'dense',
                actionsColumnIndex: -1,
                pageSize: this.state.similarTags? this.state.similarTags.length : 0,
                headerStyle: {
                  color: '#FFF',
                  fontSize: "15px",
                  backgroundColor: '#01579b',
                },
                rowStyle: (rowData)=>{
                  const rowStyle = {
                    padding:'5px',
                    fontWeight:"bold",
                  }
                  if (rowData.isValid != undefined){
                    rowStyle.color = rowData.isValid ? "green" : "red";
                  }
                  return rowStyle;
                }
              }}
            />
          
          </div>}

          <Modal show={this.state.confirmModal.show} onHide={this.hideConfirmModal} >
            <ModalHeader>
              {this.state.confirmModal.title}
            </ModalHeader>
            <ModalBody>
              {this.state.confirmModal.body}
            </ModalBody>
            <ModalFooter>
              {
                this.state.isLoading ? 
                <CircularProgress /> :
                <div className="clearfix">
                  {sourceValidOption && <div className="float-left">
                    <input checked={this.state.confirmModal.validateSource} onChange={this.changeValidSource} style={{marginRight:'10px'}} type="checkbox" />
                    <span>Mark {this.state.sourceTag.name} as a valid tag</span>
                  </div>}
                  <div className="float-right">
                    <Button disabled={this.state.isLoading} className="secondaryButton" onClick={(e) => this.hideConfirmModal(e)}>Cancel</Button>
                    <Button disabled={this.state.isLoading} className="primaryButton" onClick={(e) => this.handleConfirmModal(e)}>Confirm</Button>
                  </div>
                </div>
              }
            </ModalFooter>
          </Modal>

        </div>
      }
    </div>
  </div>
  }
}


export default SimilarTags;