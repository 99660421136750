import React,{ useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Modal, ModalBody, ModalFooter, ModalHeader , Button } from "react-bootstrap";

export default function ConfirmModal({
    show,
    data,
    actions,
    isValid,
    metaData,
    children,
    isLoading,
    onHideHandler,
}) {

    const [markOthers, setMarkOthers] = useState(false);

    const [modalData,setModalData] = useState({
        actions,
        data : null,
        isLoading : false,
        metaData : {
            title : "",
            body : ""
        },
    })

    const handleHide = () => onHideHandler(data);

    useEffect(()=>{
        if(show) setModalData({metaData,data,actions})
    },[show])  

    const toggleMarkOthers = () => {
        setMarkOthers((value)=>!value);
    }

    const handleAction = (action) => {
        setMarkOthers(false);
        action.onClick(markOthers);
    }
    
    return <Modal show={show} bsSize="large" onHide={handleHide}>
        <ModalHeader closeButton>{modalData.metaData.title}</ModalHeader>
        <ModalBody>
            {modalData.metaData.body ? modalData.metaData.body : <></> }
            {children}
        </ModalBody>
        {actions && <div>
            {isLoading ? 
                <div style={{textAlign:'right',padding:"15px"}}><CircularProgress></CircularProgress></div>
                : 
                <ModalFooter>
                    <div className='clearfix'>
                        <div className='float-left' style={{ lineHeight:'40px' }}>
                            <span>
                                <input type='checkbox' checked={markOthers} onChange={toggleMarkOthers} style={{marginRight:10}} />
                            </span>
                            <span className='clickable' onClick={toggleMarkOthers}>
                                Mark other tags as { isValid ? "invalid" : "valid" }
                            </span> 
                        </div>
                        <div className='float-right'>
                            {actions.map((action)=>{
                                return <Button 
                                    className={action.className ? action.className + " btn btn-default" : "btn btn-default"} 
                                    onClick={() => handleAction(action)}>
                                        {action.label}
                                </Button>
                            })}
                        </div>
                    </div>
                </ModalFooter>
            }
            </div>
        }
    </Modal>
}

