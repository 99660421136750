import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import TagStatModal from "../StatModal/tag.stats";
import SimilarTags from "../SimilarTags/SimilarTags";
import ArticlesBlock from "../ArticlesBlock/articles.block";
import { toast } from "react-toastify";
import tagsService from "../../../services/tagsService";

class TagDetailModal extends Component{

  state = {
    isLoading: false,
  }

  handleUpdate = async ({ isValid, isProcessed }) => {
    try {
      
      const { tag, handleRefreshTable, handleHideModal } = this.props;
      if( tag.isValid === isValid ){
        toast.info(tag.name + " is already marked as " + tag.status);
        return;
      }

      this.setState({ isLoading:true });
      let response = await tagsService.updateTag(tag.id, { isValid, isProcessed });
      if(response.success){
        handleRefreshTable({ preserveState : true });
        handleHideModal();
        toast.success(response.data.message);
      }else{
        toast.error(response.message);
      }
    } catch (err) {
      throw err;
    }

    this.setState({ isLoading:false })
  }

  render(){

    const { 
      tag, 
      similarTags,
      duplicateTags,
      show, 
      openTagData,
      handleHideModal,
      handleRefreshTag,
      handleRefreshTable,
      removeTagsFromList,
      searchText,
    } = this.props;

    if (!tag.name) return <></>;
    
    return <Modal show={show} bsSize="large" onHide={handleHideModal}>
      <ModalHeader>Tag Options : {tag.name}</ModalHeader>
      <ModalBody>
        <div class="tagOverflowScroll" ref={this.modalRef} >
          <TagStatModal tag={tag} openTagData = {(tagData) => openTagData(tagData)} /> 
          <SimilarTags 
            tag = {tag}
            similarTags = {similarTags}
            duplicateTags = {duplicateTags}
            tagName={searchText || tag.name} 
            refreshTable = {handleRefreshTable}
            openTagData = {(tagData) => openTagData(tagData)}
            removeTagsFromList = {(tagIds) => removeTagsFromList(tagIds)}
            refreshTagData = {({ tagId, similarSearchText }) => handleRefreshTag({ tagId, similarSearchText }) }
          />
          <ArticlesBlock tagName={tag.name} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button 
            disabled= {this.state.isLoading}
            className="secondaryButton btn btn-default" 
            onClick={handleHideModal}>
                Cancel
        </Button>
        <Button 
            disabled= {this.state.isLoading}
            className={"dangerButton btn btn-default"} 
            onClick={()=>this.handleUpdate({ isValid:false, isProcessed:true })}>
                Invalid
        </Button>
        <Button 
            disabled= {this.state.isLoading}
            className={"primaryButton btn btn-default"} 
            onClick={()=>this.handleUpdate({ isValid:true, isProcessed:true })}>
                Valid
        </Button>
      </ModalFooter>
    </Modal>
  }
  
};

export default TagDetailModal;